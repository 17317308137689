import React, { useRef, useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import './styles/style.css';
import Navbar from './components/Navbar';
import { CustomSelect } from './components/customSelect.js';
import devLog from './functions/devLog.js';

function isValidEmail(email) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

function Setup1({ billingSoftware, setBillingSoftware }) {
  // set up everything but the clients & matters- b/c this depends on whether the user wants to integrate with a billing software
  const [timezone, setTimezone] = useState('America/New_York');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [prevWeeklyHours, setPrevWeeklyHours] = useState('');

  const isMounted = useRef(false);

  const timezone_value_to_display = {
    'America/Los_Angeles': 'Pacific Standard Time (GMT-7)',
    'America/Denver': 'Mountain Daylight Time (GMT-6)',
    'America/Chicago': 'Central Standard Time (GMT-5)',
    'America/New_York': 'Eastern Standard Time (GMT-4)'
  }
  const billing_software_value_to_display = {
    'none': 'None',
    'clio': 'Clio',
    'lawcus': 'Lawcus',
  }
  const clio_value_to_display = {
    true: 'Yes',
    false: 'No'
  }
  const weekly_hours_value_to_display = {
    1: '1 hour',
    2: '2 hours',
    3: '3 hours',
    4: '4 hours',
    5: '5 hours',
    6: '6 hours',
    7: '7 hours',
    8: '8 hours',
    9: '9 hours',
    10: '10 hours'
  }

  useEffect(() => {
      isMounted.current = true; // Flag to track mounted status
      fetch();
      return () => {
        isMounted.current = false; // Component is unmounted
      };
  }, []);
  async function fetch() {
      const session = (await supabase.auth.getSession()).data.session;
      const { data: user, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('profile_id', session.user.id);
      if (error) {
        console.error('Error fetching user profile:', error);
        return;
      }
      const { data: integrations, error_int } = await supabase
        .from('integrations')
        .select('*')
        .eq('profile_id', session.user.id);
      if (error_int) {
        console.error('Error fetching integrations:', error_int);
        return;
      }
      if (user.length === 0) {
        devLog('User not found');
        supabase.auth.signOut();
      }
      if (isMounted.current) {
        setTimezone(user[0].timezone ? user[0].timezone : 'America/New_York');

        const name = session.user.user_metadata.full_name.split(' ');
        setFirstName(name[0]);
        setLastName(name[name.length - 1]);
        setEmail(session.user.user_metadata.email);
        setPrevWeeklyHours(user[0].prev_weekly_hours_time_tracking);
        setBillingSoftware(integrations[0].billing_software);
      }
  };
  const handleTimezoneChange = async (value) => {
    setTimezone(value);
  };

  const handleSubmit = async () => {

    const session = await supabase.auth.getSession();

    let done = true;
    if (firstName === '' || lastName === '' || email === '' || prevWeeklyHours === '') {
      done = false;
    }
    if (!done) {
        alert('Please fill out all fields.');
        return;
    }
    if (!isValidEmail(email)) {
      alert('Please enter a valid email.');
      return;
    }

    const user = session.data.session.user;

    let { error } = await supabase
      .from('profiles')
      .update({ first_name: firstName, last_name: lastName, email: email, setups_finished: 1, prev_weekly_hours_time_tracking: prevWeeklyHours, timezone: timezone })
      .eq('profile_id', user.id);
    if (error) {
      devLog('Error updating user info:', error);
      return;
    }
    let { error2 } = await supabase
      .from('integrations')
      .update({ billing_software: billingSoftware })
      .eq('profile_id', user.id);
    if (error2) {
      devLog('Error updating billing software:', error2);
      return;
    }

    devLog('User info updated:', firstName, lastName, email);
    // go to account page which is linked at '/'
    window.location.href = '/';
  };

  return (
    <div className='form-widget'>
      {/*<Navbar setup={true}/>*/}
      <h1>Setup</h1>
      <div className="form-container">
        <div className="input-group">
          <label>First Name:</label>
          <input
              type="text"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder="First Name"
              className={'input-normal'}
          />
        </div>
        <div className="input-group">
          <label>Last Name:</label>
          <input
              type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              placeholder="Last Name"
              className={'input-normal'}
          />
        </div>
        <div className="input-group">
          <label>Work Email:</label>
          <input
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Work Email"
              className={'input-normal'}
          />
        </div>
      </div>
      <h2 style={{marginBottom: '0px'}}>
        Timezone
      </h2>
      <CustomSelect myVar={timezone} setVar={handleTimezoneChange} value_to_display ={timezone_value_to_display} defaultValue={'America/New_York'} defaultDisplay={timezone_value_to_display['America/New_York']} width={'300px'}/>
      {/*<label>
        <select value={timezone} onChange={handleTimezoneChange} className="settings-select">
          <option value="America/Los_Angeles">Pacific Standard Time (GMT-7)</option>
          <option value="America/Denver">Mountain Daylight Time (GMT-6)</option>
          <option value="America/Chicago">Central Standard Time (GMT-5)</option>
          <option value="America/New_York">Eastern Standard Time (GMT-4)</option>
        </select>
      </label>*/}

      <h2 style={{marginBottom: '0px'}}>Billing Integration</h2>
      <div className="form-container">
          <form>
          <h3>Do you wish to integrate with one of the following billing softwares?</h3>
          <p>We would import your clients and matters from the billing software,
              then allow you to send all time entries generated in LegalPulse
              to your software for billing.</p>
          <CustomSelect myVar={billingSoftware} setVar={(value) => setBillingSoftware(value)} value_to_display ={billing_software_value_to_display} defaultValue={false} defaultDisplay={'none'} width={'150px'}/>

          {/*<h3>Do you wish to integrate with Clio for billing?</h3>
          <p>We would import your clients and matters from Clio,
              then allow you to send all time entries generated in LegalPulse
              to Clio for billing.</p>
            <CustomSelect myVar={useClio} setVar={(value) => setUseClio(value)} value_to_display ={clio_value_to_display} defaultValue={false} defaultDisplay={'No'} width={'100px'}/>*/}
          </form>
      </div>

      <h2 style={{marginBottom: '0px'}}>Curiosity Questions</h2>
      <div className="form-container">
          <form>
              <h3>How many hours do you currently spend tracking your time every week?</h3>
              <CustomSelect myVar={prevWeeklyHours} setVar={(value) => setPrevWeeklyHours(Number(value))} value_to_display ={weekly_hours_value_to_display} defaultValue={''} defaultDisplay={'Select hours'} width={'200px'}/>

              {/*<label>
              <select
                  className='settings-select'
                  value={prevWeeklyHours}
                  onChange={(e) => setPrevWeeklyHours(Number(e.target.value))}>
                  <option value="" disabled>Select hours</option>
                    {[...Array(10).keys()].map((number) => (
                    <option key={number} value={number + 1}>
                      {number + 1}
                    </option>
                  ))}
                  
              </select>
              </label>*/}
          </form>
      </div>

      <br></br>
      <button className="button white" onClick={() => handleSubmit()}>Save and continue</button>
    </div>
  );
}

export default Setup1;