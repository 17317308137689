import { supabase } from '../supabaseClient'
import { v4 as uuidv4 } from 'uuid';
import devLog from './devLog';

export async function blockEntries({ session, currentDate, timezone, setEntries }) {

    if (!window.confirm("Only block entries if you're done working for the day.\nContinue?")) {
        return;
    }

    let date = new Date(currentDate).toISOString().split('T')[0];
    let loading = true;
    const entries = await fetchEntries();
    const clients = await fetchClients();
    const matters = await fetchMatters();

    devLog(session, date, timezone)
    devLog(clients)
    devLog(entries)
    
    // if the value of client for any entry is null, show a message to the user to fill in the client
    for (const entry of entries) {
        let fields = ["client", "matter", "time_taken"];
        for (const field of fields) {
            if (entry[field] === null) {
                if (field === "time_taken") {
                    alert(`Make sure to fill in the time taken for each entry.`)
                    return;
                }
                alert(`Make sure to fill in the ${field} for each entry.`)
                return;
            }
        }
    }
    if (entries.length === 0) {
        return;
    }

    let blockEntries = [];
    // loop through each client
    for (const client of clients) {
        for (let matter_int of client.matters) {
            const matter_id = String(matter_int);
            const matter = matters[matter_id].description;
            //console.log(client, matter)
            if (client.block === "1") { // all bundled into one entry to push
                devLog("Client block 1")
                let time = 0;
                let desc_array = [];
                let entry_array = [];
                let earliestTime = entries[entries.length - 1].time;
                for (const entry of entries) {
                    if (entry.client_num === client.uuid && entry.matter_num === matter_id) {
                        time += Math.round(entry.time_taken * 10);
                        desc_array.push(entry.description);
                        for (const item of entry.entries) {
                            entry_array.push(item);
                        }
                        if (new Date(entry.time) < new Date(earliestTime)) {
                            earliestTime = entry.time;
                        }
                    }
                }
                if (entry_array.length === 0) {
                    continue;
                }
                // create a new entry with the same keys as entry and the total time taken
                blockEntries.push({
                    uuid: uuidv4(),
                    time_taken: time,
                    description: combineDescriptions(desc_array),
                    entries: entry_array,
                    date: date,
                    profile_id: session.user.id,
                    client: client.name,
                    matter: matter,
                    client_num: client.uuid,
                    matter_num: matter_id,
                    time: earliestTime,
                });
            }

            if (client.block === "2") { // consecutive entries for the same matter bundled into one entry
                devLog("Client block 2")

                let time = 0;
                let desc_array = [];
                let entry_array = [];
                let earliestTime = entries[entries.length - 1].time;
                //console.log("Earliest time:", earliestTime)
                
                let iter = 0;
                for (const entry of entries) {

                    if (entry.client_num === client.uuid && entry.matter_num === matter_id) {
                        time += Math.round(entry.time_taken * 10);
                        desc_array.push(entry.description);
                        for (const item of entry.entries) {
                            entry_array.push(item);
                        }
                        if (new Date(entry.time) < new Date(earliestTime)) {
                            earliestTime = entry.time;
                        }

                        devLog("Iter:", iter, "Entries length:", entries.length - 1)
                        if (iter < entries.length - 1) {
                            devLog("Next client:", entries[iter + 1].client, "Next matter:", entries[iter + 1].matter, "Current client:", client.name, "Current matter:", matter)
                        }

                        if (iter === entries.length - 1 || !(entries[iter + 1].client_num === client.uuid && entries[iter + 1].matter_num === matter_id)) {
                            if (entry_array.length === 0) {
                                continue;
                            }
                            blockEntries.push({
                                uuid: uuidv4(),
                                time_taken: time,
                                description: combineDescriptions(desc_array),
                                entries: entry_array,
                                date: date,
                                profile_id: session.user.id,
                                client: client.name,
                                matter: matter,
                                client_num: client.uuid,
                                matter_num: matter_id,
                                time: earliestTime,
                            });
                            time = 0;
                            desc_array = [];
                            entry_array = [];
                            earliestTime = entries[entries.length - 1].time;            
                        }
                    }
                    iter += 1;
                }
            }
            if (client.block === "3") { // entry for each task
                devLog(3)
                devLog(client, matter)
                devLog(entries)
                for (const entry of entries) {
                    if (entry.client_num === client.uuid && entry.matter_num === matter_id) {
                        blockEntries.push({
                            uuid: uuidv4(),
                            time_taken: Math.round(entry.time_taken * 10),
                            description: entry.description,
                            entries: entry.entries,
                            date: date,
                            profile_id: session.user.id,
                            client: client.name,
                            matter: matter,
                            client_num: client.uuid,
                            matter_num: matter_id,
                            time: entry.time,
                        });
                    }
                }
            }
        }
    }

    devLog("Initial Block entries:")
    devLog(blockEntries);

    // update entries with the new block entries
    updateEntries();
    function sup_to_display(entries) {
        return entries.map(entry => ({
          ...entry,
          time_taken: entry.time_taken / 10.0
        }));
    }

    async function updateEntries() {
        // remove all entries for this user and day and add in block entries

        /*const supabaseEntries = blockEntries.map(entry => ({
            ...entry,
            time_taken: entry.time_taken * 10.0
        }));*/

        devLog("Updating entries")
        try {
            // Retrieve and store entries to be deleted
            const { data: entriesToDelete, selectError } = await supabase
                .from('block_entries')
                .select('*')
                .eq('profile_id', session.user.id)
                .eq('date', date);
            if (selectError) {
                console.error('Error retrieving entries to delete:', selectError);
                return; // Stop execution if we can't retrieve the entries
            }

            // delete entries
            const { _, error: deleteError } = await supabase
              .from('block_entries')
              .delete()
              .filter('profile_id', 'eq', session.user.id)
              .filter('date', 'eq', date);
            if (deleteError) {
                console.error('Error deleting entries:', deleteError);
            }
            const { data: insertData, error: insertError } = await supabase
              .from('block_entries')
              .insert(blockEntries);
            if (insertError) {
                console.error('Error inserting entries:', insertError);
            }
            // On insert error, re-add old deleted entries & remove added blockEntries
            if (insertError && !deleteError) {
                console.error('Error inserting entries:', insertError);
                // Re-add the previously deleted entries
                const { error: readdError } = await supabase
                    .from('block_entries')
                    .insert(entriesToDelete);
                
                if (readdError) {
                    console.error('Error re-adding deleted entries:', readdError);
                } else {
                    devLog('Deleted entries re-added successfully.');
                }
                // delete added block entries
                const { _, error: deleteError } = await supabase
                    .from('block_entries')
                    .delete()
                    .eq('profile_id', session.user.id)
                    .in('uuid', blockEntries.map(entry => entry.uuid));
            }
        } catch (error) {
          console.error('Error updating entries:', error);
        }
        setEntries(sup_to_display(blockEntries));
    }

    async function fetchEntries() {
        try {
            const { data, error } = await supabase
              .from('block_entries')
              .select('*')
              .filter('profile_id', 'eq', session.user.id)
              .filter('date', 'eq', date);
            let updatedData = sup_to_display(data).sort((a, b) => new Date(a.time) - new Date(b.time));

            devLog("Fetched entries:")
            devLog(updatedData)
            return updatedData;
        } catch (error) {
          console.error('Error fetching entries:', error);
        }
    }

    async function fetchClients() { 
      try {
        const { data, error } = await supabase
          .from('clients')
          .select('*')
          .eq('profile_id', session.user.id);
        return data;
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    }

    async function fetchMatters() {
        const { data: fetchedMatters, error } = await supabase
            .from('matters')
            .select('*')
            .eq('profile_id', session.user.id);
        if (error) {
            console.error('Error fetching matters:', error);
            return;
        }

        // create a dictionary that maps each matter's uuid to the matter
        const mattersDict = {};
        fetchedMatters.forEach(matter => {
            matter.selected = false;
            mattersDict[matter.uuid] = matter;
        });

        return mattersDict;
    }
    
    function combineDescriptions(desc_array) {
        let desc = '';
        for (const description of desc_array) {
            desc += description + '; ';
        }
        if (desc.length < 2) {
            return desc;
        }
        desc = desc.slice(0, -2);
        if (desc[desc.length - 1] !== '.') {
            desc += '.';
        }
        return desc;
    }
}