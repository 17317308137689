import CryptoJS from 'crypto-js';
import { encrypt, decrypt } from '../encryption';
import moment from 'moment-timezone';
import generateCsrfToken from '../generateCsrfToken.js';
import refreshLawcusToken from './refreshToken.js';
import devLog from '../devLog.js';
import { supabase } from '../../supabaseClient';

export default async function getLawcusClientsMatters() {
    let session = await supabase.auth.getSession();
    session = session.data.session;
    devLog("User ID:")
    devLog(session.user.id)

    //const key = process.env.LAWCUS_ENCRYPTION_KEY;
    //const encryptedID = CryptoJS.AES.encrypt(session.user.id, key).toString();
    const encryptedID = await encrypt(session.user.id, 'lawcus');

    devLog("Encrypted ID")
    devLog(encryptedID)
    // call my server-side function to get the clients
    const serverEndpoint = process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true' ?
        process.env.REACT_APP_PRODUCTION_URL + '/api/lawcus/clients-matters'
        : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/lawcus/clients-matters`;
    const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks
    // store the protection string in supabase
    const { _, error } = await supabase
        .from('integrations')
        .update({ csrf_token: CSRF_PROTECTION_STRING })
        .eq('profile_id', session.user.id);
    if (error) {
        console.error('Error updating CSRF token in Supabase:', error);
        return;
    }

    let data;
    
    try {
        const response = await fetch(serverEndpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': CSRF_PROTECTION_STRING,
            'X-Profile-ID': encryptedID,
          },
        });
        devLog('Response:', response);

        if (!response.ok) {
            if (response.status === 401) {
                devLog('Access token expired');
                refreshLawcusToken( {supabase} );
                return;
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }
    
        data = await response.json();

    } catch (error) {
        console.error('Error fetching Lawcus clients:', error);
        return null;
    }
    if (!data) return;

   //console.log("Data:")
   //console.log(data)

    const {clients, matters} = data;

   //console.log('Clients:', clients);
   //console.log('Matters:', matters);

    // get default client settings
    const { data: profiles, error: defaultClientSettingsError } = await supabase
        .from('profiles')
        .select('*')
        .eq('profile_id', session.user.id);
    if (defaultClientSettingsError) {
        console.error('Error fetching default client settings:', defaultClientSettingsError);
        return null;
    }
    const defaultBlock = profiles.default_block;

    let clients_to_insert = clients.map(client => {
        return {
            uuid: client.uuid,
            profile_id: session.user.id,
            name: client.name,
            first_name: client.first_name || '',
            last_name: client.last_name || '',
            email: client.email,
            phone: client.phone,
            is_company: client.type === 'Company',
            block: defaultBlock,
            matters: [],
            rate: 0,
        }
    });
    const matters_to_insert = matters
        .filter(matter => matter.status.toLowerCase() === 'open')
        .map(matter => {
            const client = clients.find(client => client.id === matter.client_id);
            return {
                uuid: matter.uuid,
                profile_id: session.user.id,
                description: matter.name,
                client_uuid: client ? client.uuid : null,
                lawcus_id: matter.id,
            };
    });

    devLog("Matters to insert:")
    devLog(matters_to_insert)

    // upsert clients and matters into supabase
    for (const matter of matters_to_insert) {
        // check if the matter exists in the matters table
        const { data: matterData, error: matterError } = await supabase
            .from('matters')
            .select('*')
            .eq('uuid', matter.uuid);
        if (matterError) { console.error('Error fetching matters:', matterError); }
        devLog("Matter data:")
        devLog(matterData)
        if (matterData && matterData.length > 0) {
            // update the matter
            const { error: updateError } = await supabase
                .from('matters')
                .update(matter)
                .eq('uuid', matter.uuid);
            if (updateError) {
                console.error('Error updating matters:', updateError);
            }
            const client = clients_to_insert.find(client => client.uuid === matter.client_uuid);
            if (client && !client.matters.includes(matter.uuid)) {
                client.matters.push(String(matter.uuid));
            }
            continue;
        } else {
            // insert the matter
            const { data: insertData, error: insertError } = await supabase
                .from('matters')
                .insert(matter);
            if (insertError) {
                console.error('Insert error:', insertError);
            }
        }
        const client = clients_to_insert.find(client => client.uuid === matter.client_uuid);
        if (client && !client.matters.includes(matter.uuid)) {
            client.matters.push(String(matter.uuid));
        }
    }

    devLog("Clients to insert:")
    devLog(clients_to_insert)
    for (const client of clients_to_insert) {
        // Check if the client exists
        const { data: existingClient, error: selectError } = await supabase
            .from('clients')
            .select('uuid')
            .eq('uuid', client.uuid)
            .single();
        if (selectError) {
            console.error('Select error:', selectError);
        }
        if (existingClient) {
            let updateData = {
                name: client.name,
                first_name: client.first_name,
                last_name: client.last_name,
                is_company: client.is_company,
                block: client.block,
                matters: client.matters,
                rate: client.rate,
            };
            if (client.email) updateData.email = client.email;
            if (client.phone) updateData.phone = client.phone
            // update the client
            const { error: updateError } = await supabase
                .from('clients')
                .update(updateData)
                .eq('uuid', client.uuid)
                .eq('profile_id', session.user.id);
            if (updateError) {
                console.error('Error updating clients:', updateError);
            }
        } else {
            // Client does not exist, insert the client
            const { data: insertData, error: insertError } = await supabase
                .from('clients')
                .insert(client);
    
            if (insertError) {
                console.error('Insert error:', insertError);
                break;
            }
        }
    }
    
}
