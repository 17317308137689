import React, { useState } from 'react';
import { supabase } from '../supabaseClient'
import Modal from 'react-modal';
import devLog from '../functions/devLog';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

function FeedbackReportModal({ showFeedbackModal, onClose }) {
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    devLog(message);

    try {
        const session = (await supabase.auth.getSession()).data.session;
        
        let { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('first_name, last_name, email')
            .eq('profile_id', session.user.id)
            .single();

        if (profileError) throw profileError;

        // Insert the complaint into the complaints table
        const { data, error: insertError } = await supabase
            .from('feedback')
            .insert([
            { 
                profile_id: session.user.id,
                name: profileData.first_name + ' ' + profileData.last_name,
                email: profileData.email,
                feedback: message,
            },
        ]);

        if (insertError) throw insertError;

        localStorage.setItem('last_submitted_feedback', new Date().toISOString());

        devLog('Feedback reported successfully:', data);
        alert('Thank you for giving us feedback!'); // Show success message
        setMessage(''); // Clear the message field

    } catch (error) {
        console.error('Feedback reporting failed:', error);
    }

    onClose(); // Close the modal
  };

  return (
    <Modal
        isOpen={showFeedbackModal}
        onRequestClose={() => {
            onClose();
            }}
            contentLabel="Provide Feedback"
            className="medium-modal"
        >
        <h2>Provide Feedback</h2>
        <form onSubmit={handleSubmit}>
            <h4 style={{ paddingBottom: '10px' }}>Let us know any feedback you have, big or small!</h4>
            
            <textarea
                className="text-area"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write feedback here..."
            />
            <div style={{gap: '10px'}}>
                <button type="submit" >Send Feedback</button>
                <button type="button" onClick={onClose}>Cancel</button>
            </div>
      </form>
    </Modal>
  );
}

export default FeedbackReportModal;