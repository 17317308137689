import { useEffect, useState, useRef } from 'react';
import { supabase } from './supabaseClient.js';
import TimeInputField from './components/timeInputField.js';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { blockEntries } from './functions/blockEntries.js';
import { handleDelete } from './functions/handleDelete.js';
import fetchClientsMatters from './functions/fetchClientsMatters.js';
import { CustomSelect } from './components/customSelect.js';
import devLog from './functions/devLog.js';
import './styles/style.css';

export default function EntriesCal( {session, startDay, endDay, client, timezone, setView, setStartDate, setEndDate, setNumDaysDisplayed, entries, setEntries}) {
    const [clients, setClients] = useState([]);
    const [matters, setMatters] = useState({});
    const [loading, setLoading] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [organizedEntries, setOrganizedEntries] = useState({});

    const [selectedEntry, setSelectedEntry] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const detailBoxRef = useRef(null); // Ref for the detail box

    // Function to toggle the detailed entry view
    const toggleDetail = (entry) => {
        setSelectedEntry(entry);
        setShowDetail(!showDetail);
    };
    // Handler to close detail box if click is outside
    const handleClickOutside = (event) => {
        if (detailBoxRef.current && !detailBoxRef.current.contains(event.target)) {
        setShowDetail(false);
        }
    };
    useEffect(() => {
        // Add when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Remove event listener on cleanup
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty array ensures this only runs on mount and unmount


    useEffect(() => {
      // Function to set pageLoaded to true
      const handleLoad = () => {
        setPageLoaded(true);
      };
      // Add event listener for the load event
      window.addEventListener('load', handleLoad);
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('load', handleLoad);
    }, []); // Empty dependency array to run only once on mount
  
    useEffect(() => {
      fetchEntries();
      fetchClientsMatters(setClients, setMatters, supabase, session);
    }, [pageLoaded, startDay, endDay, client, session]);

    // function to process entries so that the time_taken column gets divided by 10.0
    function sup_to_display(entries) {
      return entries.map(entry => ({
        ...entry,
        time_taken: entry.time_taken / 10.0
      }));}

    /*
    console.log("Entries component:")
    console.log(endDay)*/
  
    async function fetchEntries() {
      // fetch entries to be displayed
        //console.log("Fetching entries")
        const startDate = new Date(startDay).toISOString().split('T')[0];
        const endDate = new Date(endDay).toISOString().split('T')[0];
        //console.log("Start Date")
        //console.log(startDate)
        //console.log("End Date")
        //console.log(endDate)
        //console.log("Client")
        //console.log(client)
        try {
          let data; let error;
          if (client === null) {
            // If no client is selected, fetch all entries for the user
            ({ data, error } = await supabase
              .from('block_entries')
              .select('*')
              .filter('profile_id', 'eq', session.user.id)
              .gte('date', startDate)
              .lte('date', endDate)
              .order('date', { ascending: false }));
          } else {
            // otherwise, fetch entries for the selected client
            ({ data, error } = await supabase
              .from('block_entries')
              .select('*')
              .filter('profile_id', 'eq', session.user.id)
              .filter('client_num', 'eq', client.uuid)
              .gte('date', startDate)
              .lte('date', endDate)
              .order('date', { ascending: false }));
          }
      
          //console.log("Data")
          //console.log(data)
          const updatedData = sup_to_display(data).sort((a, b) => new Date(a.time) - new Date(b.time));
          setEntries(updatedData);
          const organizedEntries = organizeEntriesByDate(updatedData);
          setOrganizedEntries(organizedEntries);

        } catch (error) {
          console.error('Error fetching emails:', error);
        }
        devLog("Fetched entries")
        devLog(entries)
  
        setLoading(false);
    }
    if (loading) {
      return <div>Loading...</div>;
    }

    function organizeEntriesByDate(entries) {
        const organized = {};
        // first add an empty array for each date from startDate to endDate
        const startDate = new Date(startDay);
        const endDate = new Date(endDay);
        for (let date = endDate; date >= startDate; date.setDate(date.getDate() - 1)) {
            const formattedDate = new Date(date).toISOString().split('T')[0]; // Format date as "YYYY-MM-DD"
            organized[formattedDate] = [];
        }
        
        entries.forEach(entry => {
          const entryDate = entry.date// .split('T')[0]; // Assuming entry.date is in ISO format
          if (organized[entryDate]) {
            organized[entryDate].push(entry);
          }
        });
        return organized;
      }

    if (loading || matters.length === 0 || clients.length === 0) {
      return <div>Loading...</div>;
    }
    const formatDate = (date) => {
      const d = new Date(date);
      // Format: "Day, Month Date" - e.g., "Mon, Jun 30"
      return d.toUTCString().split(' ').slice(0, 4).join(' ').replace(/\d{4}/, '').trim();
    };
  
    return (

        <div className="calendar-container">
          {Object.keys(organizedEntries).map((date, index) => (
            <div key={index} className="day-box">
              <header className="date-header" onClick={() => {
                  const selectedDate = moment.tz(date, timezone).startOf('day').toDate(); // Correctly sets to the start of the day in the specified timezone                  setEndDate(selectedDate);
                  //const selectedDate2 = moment(date).startOf('day').toDate(); // also correct, but assumes the current timezone.
                  devLog("Selected date")
                  devLog(selectedDate)
                  setEndDate(selectedDate);
                  //setView('day');
                  setNumDaysDisplayed(0);
              }}>
                <div>{formatDate(date)}</div> {/* Display the date without year */}
              </header>
              
              {organizedEntries[date].map((entry, idx) => (
                <div key={entry.uuid}> {/* Use a div with a key instead of React.Fragment */}
                    <button className={`entry-button${(showDetail && selectedEntry === entry) ? ' selected' : ''}`}
                        onClick={() => toggleDetail(entry)
                        }>
                        <p>{entry.time_taken} - {entry.description}</p>
                    </button>
                    {showDetail && selectedEntry === entry && (
                    <div ref={detailBoxRef} className="detail-box">
                        <div><strong>Date:</strong> {formatDate(date)}</div>
                        <div><strong>Time:</strong> {new Date(entry.time).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                        })}</div>
                        <div><strong>Time Taken:</strong> {entry.time_taken || ''}</div>
                        <div><strong>Client:</strong> {entry.client || ''}</div>
                        <div><strong>Matter:</strong> {entry.matter || ''}</div>
                        <div><strong>Activity:</strong> {entry.activity || ''}</div>
                        <div><strong>Description:</strong> {entry.description || ''}</div>
                    </div>
                    )}
                </div>
              ))}
            </div>
          ))}
        </div>
    );
}