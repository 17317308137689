import { supabase } from '../../supabaseClient';
import generateCsrfToken from '../generateCsrfToken';
import { encrypt, decrypt } from '../encryption';
import devLog from '../devLog';

export default async function signInWithGoogle({ session = null }) {

    if (!session) {
        session = (await supabase.auth.getSession()).data.session;
    }
    console.log("Sign In With Google")
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const deployed = process.env.REACT_APP_DEPLOYED_BOOLEAN;

    let redirectUri = deployed === 'true'
        ? `${process.env.REACT_APP_PRODUCTION_URL}/api/google/auth/v1/callback`
        : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/google/auth/v1/callback`;

    const scopes = 'openid https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/webhistory https://www.googleapis.com/auth/calendar.readonly';
    const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks

    // Store the protection string in Supabase
    if (session && session.user && session.user.id) {
        const { error } = await supabase
            .from('integrations')
            .update({ csrf_token: CSRF_PROTECTION_STRING })
            .eq('profile_id', session.user.id);
        if (error) {
            console.error('Error updating CSRF token in Supabase:', error);
            return;
        }
    } else {
        return;
    }

    const encryptedUserId = await encrypt(session.user.id, 'google');
    devLog("Encrypted User ID:", encryptedUserId);
    const stateWithAdditionalData = `${CSRF_PROTECTION_STRING}|${encryptedUserId}`;

    devLog("CSRF_PROTECTION_STRING: ", CSRF_PROTECTION_STRING);
    devLog("Session:", session);
    devLog("ClientId:", clientId);
    devLog("RedirectUri:", redirectUri);

    let params = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: scopes,
        state: stateWithAdditionalData,
        access_type: 'offline',
        prompt: 'consent',
    });

    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
    devLog(googleAuthUrl);

    window.location.href = googleAuthUrl;
};