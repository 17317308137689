import { Table } from 'react-bootstrap';
import { supabase } from '../supabaseClient'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { v4 as uuidv4 } from 'uuid';
import devLog from './devLog';
const fs = require('fs');
const path = require('path');

async function createInvoice(client_uuid, matter_uuids, timeEntries, clients, matters, settings) {
    devLog("Create Invoice");
    devLog(client_uuid, matter_uuids);
    devLog(timeEntries);
    let session = await supabase.auth.getSession();
    session = session.data.session;

    try {
        const pdfBytes = await generateInvoicePdf(client_uuid, matter_uuids, timeEntries, clients, matters, settings);

        // Generate a unique filename for the PDF
        const pdfFileName = `invoice-${Date.now()}_${uuidv4()}.pdf`;

        // Convert pdfBytes to a Blob
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Upload the PDF Blob to Supabase Storage
        /*const { data, error: uploadError } = await supabase
            .storage
            .from('invoices_bucket')
            .upload(pdfFileName, blob);
        if (uploadError) {
            console.error('Error uploading PDF to Supabase:', uploadError);
            return;
        }
        // Retrieve the public URL of the uploaded PDF
        const { publicURL, error: urlError } = supabase
            .storage
            .from('invoices')
            .getPublicUrl(pdfFileName);

        if (urlError) {
            console.error('Error getting public URL:', urlError);
            return;
        }*/
        const publicURL = URL.createObjectURL(blob);
        devLog('Public URL for the Blob PDF:');
        devLog(publicURL);

        //console.log('PDF uploaded successfully. Public URL:', publicURL);

        const { data: insertData, error: insertError } = await supabase
            .from('invoices')
            .insert([{
                profile_id: session.user.id,
                client_uuid: client_uuid,
                matter_uuids: matter_uuids,
                issue_date: settings.issueDate,
                due_date: settings.dueDate,
                total_due: 0,
                balance_due: 0,
                amount_paid: 0,
                entries: timeEntries,
                pdf_name: pdfFileName,
                public_url: publicURL,
            }]);

        // Create an object URL for the blob
        const url = URL.createObjectURL(blob);
        devLog("blob")
        devLog(blob)

        /*
        // Optionally, display the PDF in an iframe for viewing
        const viewer = document.createElement('iframe');
        viewer.style.width = '100%';
        viewer.style.height = '500px'; // Adjust as needed
        viewer.src = url;
        document.body.appendChild(viewer); // Append the viewer to the document for viewing*/

        devLog('Invoice created and link generated successfully');

    } catch (error) {
        console.error('Error creating invoice:', error);
    }
}

export async function generateBills({ event, issueDate, dueDate, include, combineMatters, includeUnbilledEntries, clients, matters }) {
    
    // add warning if some entries in the range don't have a client or matter specified
    event.preventDefault();

    let promises = [];

    let session = await supabase.auth.getSession();
    session = session.data.session;

    devLog("Generate Bills")
    devLog(clients)
    devLog(matters)

    const { data: all_entries, error } = await supabase
        .from('block_entries')
        .select('*')
        .eq('profile_id', session.user.id);
    if (error) {
        devLog(error);
        throw new Error('Failed to fetch client data');
    }

    let clients_dict = {};
    for (const client of clients) {
        clients_dict[client.uuid] = client.selected;
    }

    devLog("clients_dict")
    devLog(clients_dict)

    let settings = {
        issueDate: issueDate,
        dueDate: dueDate,
        include: include,
        combineMatters: combineMatters,
        includeUnbilledEntries: includeUnbilledEntries
    }

    for (const client of clients) {
        let entries = [];
        devLog("client")
        devLog(client)

        if (combineMatters) {
            let matters_in_client = [];
            for (const matter_uuid of client.matters) {
                if (matters[matter_uuid]) {
                    matters_in_client.push(matter_uuid)
                }
            }
            //console.log("matters_in_client")
            //console.log(matters_in_client)
            if (matters_in_client.length === 0) {
                continue;
            }

            for (const entry of all_entries) {
                //console.log("entry")
                //console.log(entry)
                if (entry.client_num && entry.matter_num) {
                    if (entry.client_num === client.uuid && matters[entry.matter_num].selected) {
                        devLog("*")
                        entries.push(entry);
                    }}
            }
            if (entries.length === 0) {
                continue;
            }
            await createInvoice(client.uuid, matters_in_client, entries, clients, matters, settings);
        } else {
            for (const matter_uuid of client.matters) {
                if (matters[matter_uuid]) {
                    entries = []
                    for (const entry of all_entries) {
                        if (entry.client_num === client.uuid && entry.matter_num === matter_uuid) {
                            entries.push(entry);
                        }
                    }
                    if (entries.length === 0) {
                        continue;
                    }
                    await createInvoice(client.uuid, [matter_uuid], entries, clients, matters, settings);
                }
            }
        }
    }

    const { data: data, errordata } = await supabase
        .from('block_entries')
        .select('*')
        .eq('profile_id', session.user.id);
    if (error) {
        devLog(error);
        throw new Error('Failed to fetch client data');
    }
    promises.push(supabase
        .from('block_entries')
        .select('*')
        .eq('profile_id', session.user.id));
    await Promise.all(promises);

    window.location.href = '/view_bills';
    // prevent web socket error

}


async function generateInvoicePdf(client_uuid, matter_uuids, timeEntries, clients, matters, settings) {

    let session = await supabase.auth.getSession();
    session = session.data.session;

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    const fontSize = 12;
    const margin = 50;

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    page.setFont(font);
    page.setFontSize(fontSize);

    let yPosition = height - margin;

    const client = clients.find(client => client.uuid === client_uuid);
    const clientName = client.name;

    let matterNameString = "";
    for (const matter_uuid of matter_uuids) {
        matterNameString += matters[matter_uuid].descrption;
        matterNameString += ", "
    }
    if (matterNameString.length > 2) {
        matterNameString = matterNameString.slice(0, -2);
    }

    page.drawText(`Invoice for ${clientName} - ${matterNameString}`, {
        x: margin,
        y: yPosition,
        size: fontSize + 4,
        color: rgb(0, 0, 0),
    });

    yPosition -= fontSize + 10;

    timeEntries.forEach((entry, index) => {
        if (yPosition < margin) {
            yPosition = height - margin;
            page.addPage();
        }

        page.drawText(
            `${entry.date} - ${entry.time} - ${entry.description} - ${entry.time_taken} hours`,
            {
                x: margin,
                y: yPosition,
                size: fontSize,
                color: rgb(0, 0, 0),
            }
        );

        yPosition -= fontSize + 5;
    });

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}