import { supabase } from '../../supabaseClient'
import devLog from '../devLog';
import CryptoJS from 'crypto-js';
import { encrypt, decrypt } from '../encryption';

function mins_since(time1) {
    return Math.floor((new Date() - new Date(time1)) / 60000);
}

export default async function tokenExpired() {

    devLog("Called tokenExpired")

    let session = await supabase.auth.getSession();
    session = session.data.session;

    let { data, error } = await supabase
        .from('profiles')
        .select('provider_token, provider_refresh_token, last_called_refresh')
        .eq('profile_id', session.user.id);
    if (error) {
        console.error('Error fetching tokens:', error);
        return;
    }
    if (data.length === 0) {
        console.error('No tokens found');
        return;
    }
    //console.log("Profile Data")
    //console.log(data[0])
    
    if (mins_since(data[0].last_called_refresh) < 1) {
        console.log('Recently refreshed');
        return;
    }

    // update the last_called_refresh column for this user
    let { error: updateError } = await supabase
        .from('profiles')
        .update({ last_called_refresh: new Date() }) // set the last_called_refresh column to the current date and time
        .eq('profile_id', session.user.id); // where the id is the current user's id
    if (updateError) {
        console.error('Error updating last_called_refresh:', updateError);
    }

    let enc_providerRefreshToken = data[0].provider_refresh_token;
    console.log("Token")
    console.log(enc_providerRefreshToken)

    //console.log("Session Information")
    //console.log(session)

    async function refreshToken() {
        let response;
        
        const fetch_url = process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true'
        ? `${process.env.REACT_APP_PRODUCTION_URL}/api/refresh-token`
        : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/refresh-token`;

        try {
            response = await fetch(fetch_url, {
                method: 'POST',
                body: JSON.stringify({ enc_providerRefreshToken: enc_providerRefreshToken }),
                headers: {
                  'Content-Type': 'application/json',
                },
            });
            //console.log("Response")
            //console.log(response)
    
            if (!response.ok) {
                devLog(response)
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            return data;

        } catch (error) {
            console.error('Failed to fetch:', error);
            console.log(error.message)
            supabase.auth.signOut();
        }
    }
    
    const output = await refreshToken();
    const encryptedClientAccessToken = output.provider_access_token;
    const encryptedNewRefreshToken = output.provider_refresh_token;

    devLog("Refreshed Client Access Token")
    devLog(encryptedClientAccessToken)
    devLog("New Encrypted Refresh Token")
    devLog(encryptedNewRefreshToken)

    //const key = process.env.AZURE_ENCRYPTION_KEY;
    //const clientAccessToken = CryptoJS.AES.decrypt(encryptedClientAccessToken, key).toString(CryptoJS.enc.Utf8);
    const clientAccessToken = await decrypt(encryptedClientAccessToken, 'azure');
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + clientAccessToken,
            'Content-Type': 'application/json'
        },
    };

    // fetch drafts from the Microsoft Graph API
    const drafts_raw = await fetch('https://graph.microsoft.com/v1.0/me/mailFolders/Drafts/messages', requestOptions);

    if (drafts_raw.status === 401) {
        // Redirect to the Auth page
        console.log("Couldn't get drafts")
        supabase.auth.signOut();
        return;
    }

    console.log("Successful.")

    /*console.log("Got drafts!")
    const drafts = await drafts_raw.json();
    console.log(drafts);*/

    supabase
        .from('profiles')
        .update({
            provider_token: encryptedClientAccessToken,
            provider_refresh_token: encryptedNewRefreshToken,
        })
        .eq('profile_id', session.user.id)
        .then(() => devLog('Provider token and refresh token updated in profiles table'))
        .catch(console.error)

    return;
}
