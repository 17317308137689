// Navbar.js
import { Link } from 'react-router-dom';
import '../styles/style.css';
import { supabase } from '../supabaseClient'
import React, { useState } from 'react';
import ErrorReportModal from './ErrorReportModal';
import FeedbackReportModal from './FeedbackReportModal';
import SendEntriesToClio from './SendEntriesToClio';
import signInWithZoom from '../functions/zoom/signInWithZoom';
import devLog from '../functions/devLog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons'; // settings icon
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'; // feedback icon

function mins_since(time1) {
  return Math.floor((new Date() - new Date(time1)) / 60000);
}
function days_since(time1) {
  return mins_since(time1) / 60 / 24;
}

function Navbar({ billingSoftware='none', signedInWithZoom, setSignedInWithZoom, signedInWithGoogle, setup = false}) {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const lastWeekTimeSaved = parseInt(localStorage.getItem('last_week_time_saved'), 10);
  const dailyTimeSaved = parseInt(localStorage.getItem('daily_time_saved'), 10);

  const handleReportError = () => {
    setShowErrorModal(true);
  };
  const handleReportFeedback = () => {
    setShowFeedbackModal(true);
  }
  // make a sign out function
  const handleSignOut = async () => {
    // set the clio access token to null in supabase
    const session = (await supabase.auth.getSession()).data.session;
    const user_id = session.user.id;
    const { data: profileData, error: profileError } = await supabase // sign out of clio
      .from('integrations')
      .update({ clio_access_token: null })
      .eq('profile_id', user_id);
    if (profileError) {
      console.error('Error updating clio access token:', profileError);
    }
    await supabase.auth.signOut(); // sign out of azure
  }

  /*
  // this doesn't work yet
  const stored_last_submitted = localStorage.getItem('last_submitted_feedback');
  if (!stored_last_submitted) {
    localStorage.setItem('last_submitted_feedback', new Date().toISOString());
  } else {
    const lastSubmitted = new Date(stored_last_submitted);
    console.log("Last submitted feedback: ", lastSubmitted);
    if (mins_since(lastSubmitted) > 0) {
        if (window.confirm("You haven't submitted feedback in a while. We strongly benefit from any and all customer feeback. Would you like to submit feedback now?")) {
          localStorage.setItem('last_submitted_feedback', new Date().toISOString());
          handleReportFeedback();
        } else {
          localStorage.setItem('last_submitted_feedback', new Date().toISOString());
            // it'll bug them after 4 days
            const threeDaysAgo = new Date();
            threeDaysAgo.setDate(threeDaysAgo.getDate() - 1);
            localStorage.setItem('last_submitted_feedback', threeDaysAgo.toISOString());
        }
    }
  }*/

  const integrations_dict = {
    'Zoom': {'use': 'useZoom', 'img': 'zoom.png', 'signed_in': signedInWithZoom, 'sign_in': signInWithZoom},
    'Google': {'use': 'useGoogle', 'img': 'google.png', 'signed_in': signedInWithGoogle},
  }
  // we display the ones that user wants to use and are not signed in to
  const integrations_to_display = Object.keys(integrations_dict).filter((key) => localStorage.getItem(integrations_dict[key]['use']) == 'true' && !integrations_dict[key]['signed_in']);

  //devLog(integrations_to_display);
  const showTimeSaved = lastWeekTimeSaved > 45 || dailyTimeSaved >= 20;

  return (
    <div style={{ marginBottom: showTimeSaved ? '10px' : '40px' }}>
    <nav>
      <ul>
      <div className="left-items">
        {!setup && (
          <li style = {{height: '100%', justifyContent: 'flex-start'}}>
            <Link to="/settings">
              <FontAwesomeIcon icon={faCog} style={{ height: '25px', width: '25px', color: 'white' }} />
            </Link>
          </li>
        )}

        {(billingSoftware!=='none' && !setup) && (
            <li>
              <SendEntriesToClio supabase={supabase} billingSoftware={billingSoftware} />
            </li>
          )}

        {(!billingSoftware==='none' && !setup) && (
          <li>
            <Link to="/billing">
              <button className="button white">Billing</button>
            </Link>
          </li>
        )}
      </div>

      <li className='icon-container'>
        <Link to="/">
          <img src="/LegalPulse_White.png" alt="Account" style={{ maxHeight: '90%', width: 'auto', opacity: '80%' }} />
        </Link>
      </li>

        <div className="right-items">
          <li>
            <button 
              className="button icon"
              onClick={handleReportFeedback}
            >
              <FontAwesomeIcon icon={faCommentDots} />
            </button>
          </li>
          <li>
            <button 
              className="button icon"
              onClick={handleReportError}
              style={{fontSize: '0.9rem'}}
            >
              !
            </button>
          </li>

          <li>
            <button className="button white" style={{margin: '0px'}} onClick={() => handleSignOut()}>Sign Out</button>
          </li>
        </div>
      </ul>
      <ErrorReportModal showErrorModal={showErrorModal} onClose={() => setShowErrorModal(false)} />
      <FeedbackReportModal showFeedbackModal={showFeedbackModal} onClose={() => setShowFeedbackModal(false)} />
    </nav>

    {/*(!setup && integrations_to_display.length > 0) && (
        <div className="integration-warning"  title="These integrations need to be re-authenticated">
            <div className="integration-icons">
                {integrations_to_display.map((key) => (
                  <img key={key} onClick = {() => integrations_dict[key]['sign_in']()} src={integrations_dict[key]['img']} alt={key} />
                ))}
            </div>
        </div>
    )*/}
    <br></br>

    {(lastWeekTimeSaved > 45) && (
      <div className="timesaved-container">
        <h3 className="timesaved-text">Time Saved Last Week: {(lastWeekTimeSaved / 60).toFixed(1)} hrs</h3>
      </div>
    ) || (dailyTimeSaved >= 20) && (
      <div className="timesaved-container">
        <h3 className="timesaved-text">Daily Time Saved: {(dailyTimeSaved / 60).toFixed(1)} hrs</h3>
      </div>
    )
    }
    
    </div>

  );
}

export default Navbar;
