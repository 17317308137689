import { Table } from 'react-bootstrap';
import { supabase } from '../../supabaseClient'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import shiftDateByTimezone from '../../functions/shiftDateByTimezone';
import { create } from 'domain';
import devLog from '../../functions/devLog';
const fs = require('fs');
const path = require('path');

// wellborn law generate bills
// rls for the storage bucket

// fix naming of pdfs

export default async function generateBills({ invoices, selected_entries, selectedClients, selectedMatters, startDate, endDate, timezone, combineClients, combineMatters, clients, matters }) {
    
    // add warning if some entries in the range don't have a client or matter specified
    //event.preventDefault();

    let session = await supabase.auth.getSession();
    session = session.data.session;

    devLog("Generate Bills")
    //console.log(clients)
    //console.log(matters)

    let newStartDate = shiftDateByTimezone(startDate, timezone);
    let newEndDate = shiftDateByTimezone(endDate, timezone);

    //console.log("start date")
    //console.log(startDate)
    //console.log("end date")
    //console.log(endDate)

    let settings = {
        startDate: newStartDate.toISOString().slice(0, 10),
        endDate: newEndDate.toISOString().slice(0, 10),
        combineMatters: combineMatters,
    }

    let invoicePromises = [];
    if (combineClients) {
        invoicePromises.push(createInvoice(invoices["*"], selectedClients, selectedMatters, selected_entries, clients, matters, settings));
    } else if (combineMatters) {
        for (let client_num in invoices) {
            let client = clients.find(client => String(client.uuid) === String(client_num));
            let entries = selected_entries.filter(entry => String(entry.client_num) === String(client_num));
            devLog("Client.matters")
            devLog(client.matters)
            devLog(typeof client.matters[0])
            devLog("Selected Matters")
            devLog(selectedMatters)
            devLog(typeof selectedMatters[0])
            let matters_to_pass = selectedMatters.filter(matter_id => 
                (client.matters).includes(matter_id));
            invoicePromises.push(createInvoice(invoices[client_num], [client_num], matters_to_pass, entries, clients, matters, settings));
        }
    } else {
        for (let client_num in invoices) {
            for (let matter_num in invoices[client_num]) {
                let entries = selected_entries.filter(entry => (String(entry.client_num) === String(client_num) && String(entry.matter_num) === String(matter_num)));
                invoicePromises.push(createInvoice(invoices[client_num][matter_num], [client_num], [matter_num], entries, clients, matters, settings));
            }
        }
    }

    // Wait for all invoice creation operations to complete
    await Promise.all(invoicePromises);
    
    window.location.href = '/billing';
}

async function createInvoice(timeEntries, selectedClients, selectedMatters, orig_entries, clients, matters, settings) {
    devLog("Create Invoice");

    if (timeEntries.length === 0) {
        devLog("No entries to bill");
        return;
    }
    let client_id_to_name = {};
    clients.map((client) => {
        client_id_to_name[client.uuid] = client.name;
    });
    devLog("Selected Clients:")
    devLog(selectedClients.map(client_num => client_id_to_name[client_num]));
    devLog("Selected Matters:")
    devLog(selectedMatters.map(matter_num => matters[matter_num].description));

    let session = await supabase.auth.getSession();
    session = session.data.session;

    try {
        const pdfBytes = await generateInvoicePdf(timeEntries, clients, matters);

        // Generate a unique filename for the PDF

        const pdfFileName = `invoice-${uuidv4()}.pdf`;
        devLog("PDF File Name")
        devLog(pdfFileName)

        // Convert pdfBytes to a Blob
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Upload the PDF Blob to Supabase Storage
        const { data, error: uploadError } = await supabase
            .storage
            .from('invoices')
            .upload(pdfFileName, blob, {
                cacheControl: '3600',
                upsert: false,
                metadata: {
                    profile_id: session.user.id,
                    start_date: settings.startDate,
                    end_date: settings.endDate,
                    clients: selectedClients,
                    matters: selectedMatters,
                    entries: timeEntries,
                    orig_entries: orig_entries,
                }
            });
        if (uploadError) {
            console.error('Error uploading PDF to Supabase:', uploadError);
            return;
        }

        const { data: data1, error } = await supabase.storage
            .from('invoices')
            .list('', { limit: 100, offset: 0 }); // Adjust limit and offset as needed
        if (error) {
            console.error('Error listing files:', error);
            return;
        }

        devLog('Files in bucket:', data1);

        // generate a signed URL for the PDF
        const { data: signedURL, error: signedUrlError } = await supabase
            .storage
            .from('invoices')
            .createSignedUrl(pdfFileName, 600);  // expiration time in seconds
        if (signedUrlError) {
            console.error('Error generating signed URL for PDF:', signedUrlError);
            return;
        }

        devLog('Signed URL for the PDF:', signedURL);

        // open a new tab with the signed URL
        window.open(signedURL.signedUrl, '_blank');

        // insert this into the supabase invoices table
        const { data: insertData, error: insertError } = await supabase
            .from('invoices')
            .insert([{
                profile_id: session.user.id,
                created_at: new Date(),
                entries: timeEntries,
                orig_entries: orig_entries,
                start_date: settings.startDate,
                end_date: settings.endDate,
                clients: selectedClients,
                matters: selectedMatters,
                total_due: 0,
                amount_paid: 0,
                pdf_name: pdfFileName,
                status: 'billed', // options: billed, paid
        }]);
        if (insertError) {
            console.error('Error inserting invoice into Supabase:', insertError);
            return;
        }

        // mark these entries as billed
        for (let entry of orig_entries) {
            const { data: updateData, error: updateError } = await supabase
                .from('block_entries')
                .update({ billed: true })
                .eq('profile_id', session.user.id)
                .eq('uuid', entry.uuid);
            if (updateError) {
                console.error('Error updating time entry:', updateError);
                return;
            }
        }
        devLog("Entries marked as billed")


        /*// Create an object URL for the blob
        const url = URL.createObjectURL(blob);
        devLog("blob")
        devLog(blob)
        
        // Optionally, display the PDF in an iframe for viewing
        const viewer = document.createElement('iframe');
        viewer.style.width = '100%';
        viewer.style.height = '500px'; // Adjust as needed
        viewer.src = url;
        document.body.appendChild(viewer); // Append the viewer to the document for viewing
        devLog(url)*/

        devLog('Invoice created and link generated successfully');

    } catch (error) {
        console.error('Error creating invoice:', error);
    }
}


async function generateInvoicePdf(timeEntries, clients, matters) {

    let session = await supabase.auth.getSession();
    session = session.data.session;

    const pdfDoc = await PDFDocument.create();
    let page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    const fontSize = 12;
    const margin = 40;
    const padding = 5; // padding between lines and text
    const columnWidths = [80, 80, 80, width - (2 * margin) - 280, 40]; // Adjust based on your needs
    const headers = ["Client", "Matter", "Date", "Entry", "Time"];
    const startYPosition = height - margin - 20;

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    page.setFont(font);
    page.setFontSize(fontSize);

    let yPosition = startYPosition
    let rowHeight = fontSize + 2 * padding;

    function wrapText(text, width, fontSize) {
        const words = text.split(' ');
        let lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const lineWidth = font.widthOfTextAtSize(currentLine + ' ' + word, fontSize);
            if (lineWidth < width) {
                currentLine += ' ' + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);
        return lines;
    }
    function drawVerticalLines(page, startYPosition, yPosition) {
        let currentX = margin;
        columnWidths.forEach((width) => {
            page.drawLine({
                start: { x: currentX, y: startYPosition },
                end: { x: currentX, y: yPosition - padding },
                thickness: 0.5,
                color: rgb(0, 0, 0),
            });
            currentX += width;
        });
        // Draw the rightmost vertical line
        page.drawLine({
            start: { x: currentX, y: startYPosition },
            end: { x: currentX, y: yPosition - padding },
            thickness: 0.5,
            color: rgb(0, 0, 0),
        });
    }

    page.drawLine({
        start: { x: margin, y: yPosition },
        end: { x: margin + columnWidths.reduce((a, b) => a + b, 0), y: yPosition },
        thickness: 1,
        color: rgb(0, 0, 0),
    });
    yPosition -= rowHeight;

    // Draw Table Headers with Yellow Background

    headers.forEach((header, index) => {
        const cellX = margin + columnWidths.slice(0, index).reduce((a, b) => a + b, 0);
        const cellWidth = columnWidths[index];
        page.drawRectangle({
            x: cellX,
            y: yPosition - padding,
            width: cellWidth,
            height: rowHeight + padding,
            color: rgb(1, 1, 0), // yellow
            //borderColor: rgb(0, 0, 0), // black
        });

        page.drawText(header, {
            x: cellX + padding,
            y: yPosition,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
    });
    /*headers.forEach((header, index) => {
        page.drawText(header, {
            x: margin + columnWidths.slice(0, index).reduce((a, b) => a + b, 0) + padding,
            y: yPosition,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
    });*/ // this has no fill color
    page.drawLine({
        start: { x: margin, y: yPosition - padding },
        end: { x: margin + columnWidths.reduce((a, b) => a + b, 0), y: yPosition - padding },
        thickness: 1,
        color: rgb(0, 0, 0),
    });

    yPosition -= fontSize + 10; // Adjust for the first row

    // Draw Table Rows
    timeEntries.forEach((entry, index) => {
        if (yPosition < margin + rowHeight) { // Check if we need a new page
            drawVerticalLines(page, startYPosition, yPosition + rowHeight); // draw vertical lines for the page that was just finished

            const newPage = pdfDoc.addPage();
            page.setFont(font);
            page.setFontSize(fontSize);
            yPosition = startYPosition; // Reset Y position for new page
            page = newPage; // Switch to the new page

            // Redraw the table headers on the new page
            page.drawLine({
                start: { x: margin, y: yPosition },
                end: { x: margin + columnWidths.reduce((a, b) => a + b, 0), y: yPosition },
                thickness: 1,
                color: rgb(0, 0, 0),
            });
            yPosition -= rowHeight;
            headers.forEach((header, index) => {
                const cellX = margin + columnWidths.slice(0, index).reduce((a, b) => a + b, 0);
                const cellWidth = columnWidths[index];
                page.drawRectangle({
                    x: cellX,
                    y: yPosition - padding,
                    width: cellWidth,
                    height: rowHeight + padding,
                    color: rgb(1, 1, 0), // yellow
                    //borderColor: rgb(0, 0, 0), // black
                });

                page.drawText(header, {
                    x: cellX + padding,
                    y: yPosition,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
            });
            page.drawLine({
                start: { x: margin, y: yPosition - padding },
                end: { x: margin + columnWidths.reduce((a, b) => a + b, 0), y: yPosition - padding },
                thickness: 1,
                color: rgb(0, 0, 0),
            });

            yPosition -= rowHeight; // Adjust for the first row on new page
        }

        const formattedDate = moment(entry.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
        const rowData = [entry.client, entry.matter, formattedDate, entry.description, `${entry.time_taken}`];

        let maxRowHeight = rowHeight; // we must draw the line below the tallest cell in the row

        // Draw each cell in the row
        rowData.forEach((data, colIndex) => {
            const cellX = margin + columnWidths.slice(0, colIndex).reduce((a, b) => a + b, 0);
            const cellWidth = columnWidths[colIndex];
            const lines = wrapText(data, cellWidth - padding * 2, fontSize);
            lines.forEach((line, lineIndex) => {
                page.drawText(line, {
                    x: cellX + padding,
                    y: yPosition - lineIndex * (fontSize + 2), // Adjust line spacing
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
            });
            maxRowHeight = Math.max(maxRowHeight, (fontSize + 2) * lines.length + 10);
        });
        // Draw horizontal line for the row
        page.drawLine({
            start: { x: margin, y: yPosition - maxRowHeight + rowHeight - 5 },
            end: { x: margin + columnWidths.reduce((a, b) => a + b, 0), y: yPosition - maxRowHeight + rowHeight - 5 },
            thickness: 0.5,
            color: rgb(0, 0, 0),
        });

        yPosition -= maxRowHeight; // Move to the next row based on the tallest cell
    });

    // Draw vertical lines for the last page
    drawVerticalLines(page, startYPosition, yPosition + rowHeight);

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}