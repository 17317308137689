import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import tokenExpired from './tokenExpired.js';
import fetchClientsMatters from '../fetchClientsMatters.js';
import devLog from '../devLog.js';
import { encrypt, decrypt } from '../encryption';

function mins_since(time1) {
  return Math.floor((new Date() - new Date(time1)) / 60000);
}

export async function getCalendar({ supabase, timezone }) {
  //console.log("Called getCalendar")

  try {
      let session = await supabase.auth.getSession();
      session = session.data.session;

      const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('last_updated_cal, provider_token')
          .eq('profile_id', session.user.id);
      if (profileError) {
          console.error('Error fetching last_updated:', profileError);
          return;
      }
      const { data: descData, error: descError } = await supabase
            .from('custom_descriptions')
            .select('*')
            .eq('profile_id', session.user.id)
            .eq('type', 'meeting');
        if (descError) {
            console.error('Error fetching description data:', descError);
            return;
        }
        let text1;
        if (!descData || !descData.length || !descData[0].text1) text1 = 'Meeting regarding'
        else text1 = descData[0].text1;
        let text2;
        if (!descData || !descData.length || !descData[0].text2) text2 = 'with'
        else text2 = descData[0].text2;

      // if the last_updated column is null, set it to yesterday
      const last_updated = new Date(profileData[0].last_updated_cal) || new Date(new Date().setDate(new Date().getDate() - 1));
      
      const deployed = process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true';
      const mins_to_wait = deployed ? 15 : 1; // 15 minutes if deployed, 1 minute if testing
      if (mins_since(last_updated) < mins_to_wait) {
          return;
      }
      //const key = process.env.AZURE_ENCRYPTION_KEY;
      //const providerToken = CryptoJS.AES.decrypt(profileData[0].provider_token, key).toString(CryptoJS.enc.Utf8);
      const providerToken = await decrypt(profileData[0].provider_token, 'azure');

      insertUpdate({supabase, session, last_updated, providerToken, text1, text2});
      handleDeleted({supabase, timezone, session, last_updated, providerToken});

  } catch (error) {
      console.error("Error in getCalendar:", error);
  }
}

async function insertUpdate({supabase, session, last_updated, providerToken, text1, text2}) {

    const { clients, matters } = await fetchClientsMatters(null, null, supabase, session);
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer' + providerToken,
        },
    };
    let fetchUrl = 'https://graph.microsoft.com/v1.0/me/calendar/events';

    if (last_updated) {
        const lastUpdatedISO = new Date(last_updated).toISOString();
        fetchUrl += `?$filter=lastModifiedDateTime ge ${lastUpdatedISO}`;
    }

    const data = await fetch(fetchUrl, requestOptions);

    if (data.status === 401) {
        // Redirect to the Auth page
        tokenExpired();
        return;
    }
    if (data.error) {
        devLog('Error fetching calendar events:', data.error);
        return;
    }

    const unparsed_cal = (await data.json()).value;
    //console.log(unparsed_cal);

    if (unparsed_cal.length === 0) {
        return [];
    }

    const calendars = await parseSentCalData(unparsed_cal);
    
    //console.log("Calendars!!")
    //console.log(calendars);

    const calsToInsert = calendars.map(calendar => ({
        uuid: session.user.id + ' ' + calendar["cal_id"] + " calendar",
        profile_id: session.user.id,
        subject: calendar['Subject'],
        organizer: calendar['Organizer'],
        start: calendar['Start'],
        end: calendar['End'],
        time_taken: calendar['Time Taken'],
        attendees_names: calendar['Attendees Names'],
        attendees_emails: calendar['Attendees Emails'],
        date: calendar['Date'],
    }));

    const { error: insertError } = await supabase
        .from('calendars')
        .upsert(calsToInsert)
        .eq('profile_id', session.user.id);
    if (insertError) {
        devLog('Error inserting calendars:', insertError);
    }
    
    const entriesToInsert = calsToInsert.map(({ uuid, profile_id, date, start, time_taken, subject, attendees_names, attendees_emails }) => {
        const { client, matter } = getClientAndMatter(attendees_names, attendees_emails, subject, clients, matters);
        let client_num = client ? client.uuid : null;
        let client_name = client ? client.name : null;
        return {
            uuid,
            profile_id,
            time_taken,
            time: start,
            date,
            description: `${text1} ${subject}` + (attendees_names.length > 0 ? ` ${text2} ${attendees_names}` : ''),
            type: 'calendar',
            activity: 'Meeting',  
            client: client_name,
            client_num: client_num,
            matter_num: matter,
            matter: matter ? matters[matter].description : null,
        };
    });

    //console.log("Inserting into supabase entries table")
    //console.log(entriesToInsert)

    for (const entry of entriesToInsert) {
        // Step 1: Check if an entry with the same uuid exists
        let existingEntries = null; let fetchError = null;
        try {
          ({ data: existingEntries, error: fetchError } = await supabase
            .from('entries')
            .select('*')
            .eq('uuid', entry.uuid)
            .single());
        } catch (error) {
          devLog("Error fetching entry:", error);
        }

        if (existingEntries) {
          // Step 2: Entry exists, so update it
          const { error: updateError } = await supabase
            .from('entries')
            .update({ time: entry.time, time_taken: entry.time_taken, date: entry.date })
            .eq('uuid', entry.uuid);
      
          if (updateError) {
            console.error('Error updating entry:', updateError);
          } /*else {
            devLog(`Entry updated for uuid: ${entry.uuid}`);
          }*/
        } else {
          // Step 3: Entry does not exist, so insert it
          //console.log("Inserting Entry")
          const { error: insertError } = await supabase
            .from('entries')
            .insert([entry]);
      
          if (insertError) {
            console.error('Error inserting entry:', insertError);
            devLog(entry)
          } /*else {
            devLog(`Entry inserted for uuid: ${entry.uuid}`);
          }*/
        }

        // Redo with block_entries table

        // Step 1: Check if an entry with the same uuid exists
        const { data: existingEntriesB, error: fetchErrorB } = await supabase
          .from('block_entries')
          .select('*')
          .eq('uuid', entry.uuid)
          .single();
      
        if (fetchErrorB) {
          devLog('Error fetching entry:', fetchErrorB);
        }
        
        if (existingEntriesB) {
          // Step 2: Entry exists, so update it

          const { error: updateError } = await supabase
            .from('block_entries')
            .update({ time: entry.time, time_taken: entry.time_taken, date: entry.date, 
                      client: entry.client, client_num: entry.client_num, matter: entry.matter, matter_num: entry.matter_num,
                      need_to_verify_client: entry.client ? true : false
            })
            .eq('uuid', entry.uuid);
      
          if (updateError) {
            console.error('Error updating entry:', updateError);
          }
        } else if (!existingEntriesB && !existingEntries) {
          // Step 3: Entry does not exist, so insert it

          entry.entries = [entry.uuid];
          entry.need_to_verify_client = entry.client ? true : false;
          const { error: insertError } = await supabase
            .from('block_entries')
            .insert(entry);
      
          if (insertError) {
            console.error('Error inserting entry:', insertError);
          } /*else {
            devLog(`Entry inserted for uuid: ${entry.uuid}`);
          }*/
        }
    }
}
async function handleDeleted({supabase, timezone, session, last_updated, providerToken}) {
    // figure out if any calendar events have been deleted
    //console.log("Handling Deleted")

    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer' + providerToken,
      },
    };
    let fetchUrl = 'https://graph.microsoft.com/v1.0/me/calendar/events';

    // default value of start date is yesterday
    let startDay = new Date().toLocaleString('en-US', { timeZone: timezone }).split('T')[0];
    startDay = new Date(startDay);
    startDay.setHours(0, 0, 0, 0);
    startDay.setDate(startDay.getDate() - 1);
    //console.log("Default Start Date")
    //console.log(startDay)

    if (last_updated) {
        const lastUpdatedISO = new Date(last_updated).toISOString();
        startDay = new Date(last_updated).toLocaleString('en-US', { timeZone: timezone }).split('T')[0];
        startDay = new Date(startDay);
        startDay.setDate(startDay.getDate() - 1); // be safe because we don't know the timezone
        startDay.setHours(0, 0, 0, 0);
        //console.log("Real Start Date")
        //console.log(startDay)
        //console.log(lastUpdatedISO)
        //console.log(startDay.toISOString())
        fetchUrl += `?$filter=start/dateTime ge '${startDay.toISOString()}'`;
        startDay.setDate(startDay.getDate() + 1);
    }

    const data = await fetch(fetchUrl, requestOptions);

    if (data.status === 401) {
        // Redirect to the Auth page
        tokenExpired();
        return;
    }
    if (data.ok === false) {
        devLog('Error fetching calendar events:', data);
        return;
    }
    
    // update the last_updated column for this user
    let { error: updateError } = await supabase
      .from('profiles')
      .update({ last_updated_cal: new Date() }) // set the last_updated column to the current date and time
      .eq('profile_id', session.user.id); // where the id is the current user's id
    if (updateError) {
        console.error('Error updating last_updated:', updateError);
    }

    const unparsed_cal = (await data.json()).value;
    //console.log("Raw data")
    //console.log(unparsed_cal);

    if (unparsed_cal.length === 0) {
        return [];
    }

    const calendars = await parseSentCalData(unparsed_cal);
    
    //console.log("Calendars!!")
    //console.log(calendars);

    const uuids = calendars.map(calendar => (
        session.user.id + ' ' + calendar["cal_id"] + " calendar"));

    // get calendar events from the calendars database with start date after the start date
    const { data: existingCalendars, error: fetchError } = await supabase
      .from('calendars')
      .select('*')
      .eq('profile_id', session.user.id)
      .gte('start', startDay.toISOString());
    
    //console.log(startDay.toISOString())
    //console.log("Existing Calendars")
    //console.log(existingCalendars)

    // for each existing calendar event, check if it is in the uuids array
    for (const event of existingCalendars) {
        if (!uuids.includes(event.uuid)) {
            // delete the event from supabase
            const { error: deleteError } = await supabase
              .from('calendars')
              .delete()
              .eq('uuid', event.uuid);
            if (deleteError) {
                console.error('Error deleting calendar event:', deleteError);
            }

        }
    }
}

async function parseSentCalData(data) {
    let result = [];
    for (let obj of data) {
        let subject = obj.subject; //kept
        let organizer = obj.organizer.emailAddress.name;
        let start_timezone = obj.start.timeZone;
        let end_timezone = obj.start.timeZone;
        let start = moment.tz(obj.start.dateTime, start_timezone).tz('America/New_York').toISOString();
        let end = moment.tz(obj.end.dateTime, end_timezone).tz('America/New_York').toISOString();
        
        let startMoment = moment(start);
        let endMoment = moment(end);
        let duration = moment.duration(endMoment.diff(startMoment));
        let minutes = duration.asMinutes();

        let time_taken = Math.ceil(minutes / 6);
        let cal_id = obj.id;

        let date = new Date(start).toDateString()

        let allDay = obj.isAllDay;
        if (allDay) {
            continue;
        }

        let attendeesDict = obj.attendees;
        let attendees_names = [];
        let attendees_emails = [];
        for (let person of attendeesDict) {
            attendees_names.push(person.emailAddress.name);
            attendees_emails.push(person.emailAddress.address);
        }

        let event = {
            "Subject" : subject,
            "Organizer" : organizer,
            "Start" : start,
            "End" : end,
            "Attendees Names" : attendees_names,
            "Attendees Emails" : attendees_emails,
            "Date": date,
            "Time Taken": time_taken,
            "cal_id": cal_id,
        };

        result.push(event);
    }
    return result;
}

function getClientAndMatter(attendNames, emails, title, clients, matters) {
  if (clients.length === 0) {
      return {client: null, matter: null};
  }
  // names should be an array of [first_name, last_name]
  let names = attendNames.map(name => [name.split(' ')[0].toLowerCase(), name.split(' ')[name.split(' ').length - 1].toLowerCase()]);
  let title_words = (title.match(/\b[\w']+\b/g) || []).map(word => word.toLowerCase());

  let clientMatches = clients.map(client => ({...client, matches: 0}));

  for (let client of clients) {
      const split = client.name.split(' ');
      let first_name = split[0].toLowerCase();
      let last_name = split[split.length-1].toLowerCase();
      // first try seeing if the email address matches
      if (emails.includes(client.email)) {
          clientMatches[clients.indexOf(client)].matches += 1;
      }
      // now see if name matches
      if (names.includes([first_name, last_name])) {
          clientMatches[clients.indexOf(client)].matches += 1;
      }
      if (title_words.includes(first_name)) {
          clientMatches[clients.indexOf(client)].matches += 1;
      }
      if (title_words.includes(last_name)) {
          clientMatches[clients.indexOf(client)].matches += 1;
      }
      for (let matter_uuid of client.matters) {
          let matter = matters[matter_uuid];
          let matter_words = (matter.description.match(/\b[\w']+\b/g) || []);
          matter_words = matter_words.map(word => word.toLowerCase());
          for (let word of title_words) {
              if (matter_words.includes(word)) {
                  clientMatches[clients.indexOf(client)].matches += 1;
              }
          }
      }
  }
  //console.log("Client Matches:")
  //console.log(clientMatches)

  // sort the candidate clients
  // if there are multiple clients, return the one with the most matches
  // if there's a tie, return null
  clientMatches.sort((a, b) => b.matches - a.matches);
  if (clientMatches[0].matches === 0) {
      return {client: null, matter: null};
  }
  if (clientMatches.length === 1 || clientMatches[0].matches === clientMatches[1].matches) {
      return {client: null, matter: null};
  }
  if (clientMatches[0].matters.length === 1) {
      return {client: clientMatches[0], matter: clientMatches[0].matters[0]};
  }
  return {client: clientMatches[0], matter: null};
}