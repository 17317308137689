import generateCsrfToken from "../generateCsrfToken";
import CryptoJS from 'crypto-js';
import { encrypt, decrypt } from '../encryption';
import { supabase } from '../../supabaseClient';
import devLog from "../devLog";

export default async function signInWithLawcus(setSignedInWithLawcus) {
    // Redirect to Lawcus' OAuth endpoint, and then back to the redirect URI so the app can catch it.
    // the domain in redirectUri must be configured in Lawcus and supabase as an allowed redirect URI
    devLog("Signing in with Lawcus");
    const clientId = process.env.REACT_APP_LAWCUS_CLIENT_ID;
    const deployed = process.env.REACT_APP_DEPLOYED_BOOLEAN;
  
    let redirectUri = deployed === 'true' 
        ? `${process.env.REACT_APP_PRODUCTION_URL}/api/lawcus/auth/v1/callback` 
        : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/lawcus/auth/v1/callback`;
    
    //const scopes = 'activities api matters communications contacts custom fields documents imports general matters settings:read_write';
    const scopes = 'contacts';

    const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks
    // store the protection string in supabase

    let session = await supabase.auth.getSession();
    session = session.data.session;
    if (session && session.user && session.user.id) {
      const { error } = await supabase
        .from('integrations')
        .update({ auth_csrf_token: CSRF_PROTECTION_STRING })
        .eq('profile_id', session.user.id);
      if (error) {
        console.error('Error updating CSRF token in Supabase:', error);
        return;
      }
      devLog("CSRF Token Updated in Supabase");
    } else {
      return;
    }
  
    //const secretKey = process.env.LAWCUS_ENCRYPTION_KEY;
    //const encryptedUserId = CryptoJS.AES.encrypt(session.user.id, secretKey).toString();
    const encryptedUserId = await encrypt(session.user.id, 'lawcus');
    devLog("Encrypted User ID:", encryptedUserId)
    const stateWithAdditionalData = `${CSRF_PROTECTION_STRING}-${encryptedUserId}`;
    const stateData = {
      csrf: CSRF_PROTECTION_STRING,
      encryptedUserId: encryptedUserId
    };
    // URL-encode the JSON string
    const encodedState = encodeURIComponent(JSON.stringify(stateData));
    
    setSignedInWithLawcus(true);
    //console.log("CSRF_PROTECTION_STRING:", CSRF_PROTECTION_STRING);
    //console.log("ClientId:", clientId);
    //console.log("RedirectUri:", redirectUri);
    //console.log("Scopes:", scopes);
      
    // example from lawcus docs:
    // https://auth.lawcus.com/auth?response_type=code&state=&client_id=1234&scope=&redirect_uri=https://api-hostname/oauth

    let params = new URLSearchParams({
      response_type: 'code',
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: scopes,
      state: encodedState,
      redirect_on_decline: true,
    });
  
    const authUrl = `https://auth.lawcus.com/auth?${params.toString()}`;
    devLog(authUrl);
  
    window.location.href = authUrl;
  }