import React, { useState } from 'react';
import { supabase } from '../supabaseClient'
import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import '../styles/style.css';
import { useLocation } from 'react-router-dom';
import { generateBills } from '../functions/generateBills';
import fetchClientsMatters from '../functions/fetchClientsMatters';
import EditDate from '../components/EditDate';
import devLog from '../functions/devLog';

import moment from 'moment-timezone';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

export default function NewBill() {
    const [loading, setLoading] = useState(true);
    const [timezone, setTimezone] = useState('America/New_York');
    const [clients, setClients] = useState([]);
    const [matters, setMatters] = useState([]);
    const [session, setSession] = useState(null);
    const [editingClientIndex, setEditingClientIndex] = useState(null);
    const [editingMatterIndex, setEditingMatterIndex] = useState(null);

    const [issueDate, setIssueDate] = useState(moment.tz(timezone).startOf('day').toDate());
    const [dueDate, setDueDate] = useState(moment.tz(timezone).startOf('day').toDate());
    const [include, setInclude] = useState('1');
    const [combineMatters, setCombineMatters] = useState(false);
    const [includeUnbilledEntries, setIncludeUnbilledEntries] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    
    const fetch = async () => {
        const session = (await supabase.auth.getSession()).data.session;
        setSession(session);

        const { data, error } = await supabase
        .from('profiles')
        .select('timezone')
        .eq('profile_id', session.user.id);

        if (error) {
            alert('Failed to get timezone: ' + error.message);
        } else {
            setTimezone(data[0].timezone);
        }    
    };

    async function fetchClients() {
        const session = (await supabase.auth.getSession()).data.session;
        const { data: clients, error } = await supabase
        .from('clients')
        .select('*')
        .eq('profile_id', session.user.id);

        if (error) {
        console.error('Error fetching clients:', error);
        return;
        }

        const clientsWithSettings = clients.map(client => ({
        ...client,
        selected: false,
        }));
        devLog("Clients")
        devLog(clientsWithSettings)

        setClients(clientsWithSettings);
    }
    async function fetchMatters() {
        const session = (await supabase.auth.getSession()).data.session;
        const { data: fetchedMatters, error } = await supabase
        .from('matters')
        .select('*')
        .eq('profile_id', session.user.id);
        if (error) {
        console.error('Error fetching matters:', error);
        return;
        }

        // create a dictionary that maps each matter's uuid to the matter
        const mattersDict = {};
        fetchedMatters.forEach(matter => {
            matter.selected = false;
            mattersDict[matter.uuid] = matter;
        });

        setMatters(mattersDict);
        devLog(mattersDict)
    };

    useEffect(() => {
        fetch();
        fetchClients();
        fetchMatters();
        setLoading(false);
    }, []);

    /*console.log("Clients")
    devLog(clients)*/
    
    if (loading || matters.length === 0 || clients.length === 0) {
        return <div>Loading...</div>;
    }
    /*
    devLog("Clients")
    devLog(clients)
    devLog("Matters")
    devLog(matters)*/

    clients.map((client) => {
        client.matters.map((matter_id) => {
            if (!matters[matter_id]) {
                devLog('Undefined matter_id:', matter_id);
                devLog(client);
            }
        });
    });

    return (
        <div className="form-widget">
        {/*<Navbar />*/}
        <h1>Billing</h1>
        {clients.map((client, clientIndex) => (
            <div key={clientIndex}>
            <input
            type="checkbox"
            checked={client.selected}
            disabled={client.matters.length === 0}
            onChange={() => {
                const newClients = [...clients];
                newClients[clientIndex].selected = !newClients[clientIndex].selected;
                setClients(newClients);
                // for all matters in the client, set the selected state to the client's selected state
                newClients[clientIndex].matters.forEach((matter_uuid, matterIndex) => {
                    let matter = matters[matter_uuid];
                    matter.selected = newClients[clientIndex].selected;
                });
            }}
            />
            {client.name}
            {client.matters.map((matter_id, matterIndex) => (
                <div key={matterIndex} style={{ paddingLeft: '20px' }}>
                    <input
                        type="checkbox"
                        checked={matters[matter_id] ? matters[matter_id].selected : false}
                        onChange={() => {
                        const newMatters = {...matters};
                        newMatters[matter_id].selected = !newMatters[matter_id].selected;
                        setMatters(newMatters);
                        }}
                    />
                    {matters[matter_id].description}
                </div>
            ))}
        </div>
        ))}
        <br></br>

        <div>
        <button className="button white" onClick={() => {
            setModalOpen(true);
        } }>Generate Invoices</button></div>

        <Modal
            isOpen={modalOpen}
            onRequestClose={() => {
                setModalOpen(false);
                }}
              contentLabel="Add Client"
            >
            <h2>Generate Invoices</h2>
            <form onSubmit={(event) => generateBills({event, issueDate, dueDate, include, combineMatters, includeUnbilledEntries, clients, matters })}>
            <label>
                Issue Date
                <EditDate date={issueDate} handleDateChange={(e) => setIssueDate(new Date(e.target.value))} />
                {/*<input type="date" value={issueDate} onChange={e => setIssueDate(e.target.value)}/>*/}
            </label>

            <label>
                Due Date
                <EditDate date={dueDate} handleDateChange={(e) => setDueDate(new Date(e.target.value))} />
                {/*<input type="date" value={dueDate} onChange={e => setDueDate(e.target.value)}/>*/}
            </label>
            {/* Bill theme */}

            {/* Detail level */}
            {/*<label>
                Detail Level:
                <div>
                <select value={detailLevel} onChange={e => setDetailLevel(e.target.value)} className="settings-select">
                    <option value="3">All Details</option>
                    <option value="2">Activity Summary</option>
                    <option value="1">Client Summary</option>
                </select>
                </div>
            </label>*/}
            <label>
                Include:
                <div>
                <select value={include} onChange={e => setInclude(e.target.value)} className="settings-select">
                    <option value="1">Time Entries and Expenses</option>
                    <option value="2">Time Entries Only</option>
                    <option value="3">Expenses Only</option>
                </select>
                </div>
            </label>

            {/* Other options represented as checkboxes */}
            <label>
                Preferences:
                <input type="checkbox" checked={combineMatters} onChange={e => setCombineMatters(e.target.checked)}/>
                Combine multiple matters for a client into one invoice
                
                <input type="checkbox" checked={includeUnbilledEntries} onChange={e => setIncludeUnbilledEntries(e.target.checked)}/>
                Include unbilled time entries
            </label>

            {/* Tax later */}

              <button type="submit">Generate Bills</button>
              <button type="button" onClick={() => {setModalOpen(false)}}>
                Cancel
              </button>
            </form>

          </Modal>

        </div>
    );
}