import { useEffect, useState } from 'react';

export default async function fetchClientsMatters(setClients, setMatters, supabase, session) { 
    let clients;
    try {
        const { data, error } = await supabase
            .from('clients')
            .select('*')
            .eq('profile_id', session.user.id);
        if (setClients) {
            setClients(data);}
        clients = data;
    } catch (error) {
        console.error('Error fetching clients:', error);
    }
    
    const { data: fetchedMatters, error } = await supabase
        .from('matters')
        .select('*')
        .eq('profile_id', session.user.id);
    if (error) {
        console.error('Error fetching matters:', error);
        return;
    }
    // create a dictionary that maps each matter's uuid to the matter
    const matters = {};
    fetchedMatters.forEach(matter => {
        matter.selected = false;
        matters[matter.uuid] = matter;
    });
    if (setMatters) {
        setMatters(matters);}
    //console.log(matters);
    return { clients, matters }
}