import signInWithLawcus from "./signInWithLawcus";
import devLog from "../devLog";
import getClientsMatters from "./getClientsMatters";

export default async function refreshLawcusToken( { supabase } ) {
    devLog("Refreshing Lawcus Token")
    let session = await supabase.auth.getSession();
    session = session.data.session;
    // get the encrypted refresh token from supabase
    const { data: tokenData, error: tokenError } = await supabase
        .from('integrations')
        .select('lawcus_refresh_token')
        .eq('profile_id', session.user.id);
    if (tokenError || !tokenData || !tokenData.length) {
        console.error('Error fetching lawcus refresh token from Supabase:', tokenError);
        return;
    }
    const encryptedRefreshToken = tokenData[0].lawcus_refresh_token;
    
    const endpoint = '/api/lawcus/refresh-token';
    const url =  process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true' ?
    process.env.REACT_APP_PRODUCTION_URL + endpoint
    : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}${endpoint}`;
    const payload = {
        enc_refreshToken: encryptedRefreshToken
    };

    let data;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        if (!response.ok) {
            devLog(response)
            throw new Error('Error refreshing Lawcus token');
        }

        data = await response.json();
        devLog('Token refreshed successfully:', data);
    } catch (error) {
        /*console.error('Error refreshing Lawcus token:', error);
        console.log("Session")
        console.log(session)
        console.log("User ID:")
        console.log(session.user.id)*/
        console.log("Nullifying Lawcus Access Token")
        
        // set the user's access token to null so they are prompted to log in again
        const { error: updateError } = await supabase
            .from('integrations')
            .update({ lawcus_access_token: null, lawcus_refresh_token: null })
            .eq('profile_id', session.user.id);
        if (updateError) console.error('Error updating lawcus access token:', updateError);
        window.location.href = '/';
        return;
    }

    const encryptedNewAccessToken = data.access_token;
    const encryptedNewRefreshToken = data.refresh_token;

    devLog("Refreshed Client Access Token")
    devLog(encryptedNewAccessToken)
    devLog("New Encrypted Refresh Token")
    devLog(encryptedNewRefreshToken)

    // update the access and refresh tokens in supabase
    const { error: updateError } = await supabase
        .from('integrations')
        .update({ lawcus_access_token: encryptedNewAccessToken, lawcus_refresh_token: encryptedNewRefreshToken })
        .eq('profile_id', session.user.id);
    if (updateError) console.error('Error updating lawcus access token:', updateError);
}