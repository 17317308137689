import '../styles/style.css';
import { supabase } from '../supabaseClient.js'
import { useEffect } from 'react';
import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../styles/Settings.css';
import Modal from 'react-modal';
import { CustomSelect } from './customSelect.js';
import '../styles/style.css';
import devLog from '../functions/devLog.js';
import Papa from 'papaparse';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

const maxx = Math.pow(2, 53) - 1
function generateBigInt() {
  return Math.floor(Math.random() * maxx) + 1;
}

function UploadClients({ defaultBlock, clients, setClients, fetchClients, fetchMatters }) {
  const [showModal, setShowModal] = useState(false);
  const [errorFieldsClients, setErrorFieldsClients] = useState([]);
  const [errorFieldsMatters, setErrorFieldsMatters] = useState([]);
  const [csvDataClients, setCsvDataClients] = useState([]);
  const [csvDataMatters, setCsvDataMatters] = useState([]);
  const [csvFieldsClients, setCsvFieldsClients] = useState([]);
  const [csvFieldsMatters, setCsvFieldsMatters] = useState([]);
  const [columnMappingClients, setColumnMappingClients] = useState({});
  const [columnMappingMatters, setColumnMappingMatters] = useState({});
  const clientsFileInputRef = useRef(null);
  const mattersFileInputRef = useRef(null);

  const requiredFieldsClients = [
    ['uuid', 'CLIENT ID*'],
    ['name', 'CLIENT NAME*'],
    ['is_company', 'CLIENT TYPE (company or individual)'],
    ['email', 'CLIENT EMAIL'],
    ['phone', 'CLIENT PHONE'],
    ['rate', 'CLIENT BILLING RATE'],
  ];

  const requiredFieldsMatters = [
    ['uuid', 'MATTER ID*'],
    ['description', 'MATTER NAME*'],
    ['client_uuid', 'CLIENT ID*'],
  ];

  const resetState = () => {
    setErrorFieldsClients([]);
    setErrorFieldsMatters([]);
    setCsvDataClients([]);
    setCsvDataMatters([]);
    setCsvFieldsClients([]);
    setCsvFieldsMatters([]);
    setColumnMappingClients({});
    setColumnMappingMatters({});
    if (clientsFileInputRef.current) clientsFileInputRef.current.value = '';
    if (mattersFileInputRef.current) mattersFileInputRef.current.value = '';
  };

  const handleCloseModal = () => {
    setShowModal(false);
    resetState();
  };

  const handleFileUpload = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (type === 'clients') {
            setCsvDataClients(results.data);
            setCsvFieldsClients(results.meta.fields);
          } else {
            setCsvDataMatters(results.data);
            setCsvFieldsMatters(results.meta.fields);
          }
        }
      });
    } else {
      // File was removed
      if (type === 'clients') {
        setCsvDataClients([]);
        setCsvFieldsClients([]);
        setColumnMappingClients({});
      } else {
        setCsvDataMatters([]);
        setCsvFieldsMatters([]);
        setColumnMappingMatters({});
      }
    }
  };

  const handleSubmit = () => {
    const errorsClients = [];
    const errorsMatters = [];

    if (csvDataClients.length > 0) {
      requiredFieldsClients.forEach(([field, outwardName]) => {
        const columnName = columnMappingClients[field];
        if (columnName && !csvFieldsClients.includes(columnName) || outwardName[outwardName.length - 1] === '*' && !columnName) {
          errorsClients.push(field);
        }
      });
    }

    if (csvDataMatters.length > 0) {
      requiredFieldsMatters.forEach(([field, outwardName]) => {
        const columnName = columnMappingMatters[field];
        if (columnName && !csvFieldsMatters.includes(columnName) || outwardName[outwardName.length - 1] === '*' && !columnName) {
          errorsMatters.push(field);
        }
      });
    }

    if (errorsClients.length > 0 || errorsMatters.length > 0) {
      setErrorFieldsClients(errorsClients);
      setErrorFieldsMatters(errorsMatters);
    } else {
      setErrorFieldsClients([]);
      setErrorFieldsMatters([]);
      setShowModal(false);
      handleCsvSubmit();
    }
  };

  const handleCsvSubmit = async () => {
    const session = (await supabase.auth.getSession()).data.session;
    let clientsToInsert = csvDataClients.map(row => {
      const client_name = row[columnMappingClients['name']] || ''
      const client_first_name = client_name.split(' ').length > 1 ? client_name.split(' ')[0] : ''
      const client_last_name = client_name.split(' ').length > 1 ? client_name.split(' ').slice(1).join(' ') : '';

      return {
        uuid: row[columnMappingClients['uuid']] || '',
        profile_id: session.user.id,
        is_company: row[columnMappingClients['is_company']] ? row[columnMappingClients['is_company']] === 'Company': true,
        name: client_name,
        first_name: client_first_name,
        last_name: client_last_name,
        email: row[columnMappingClients['email']] || '',
        phone: row[columnMappingClients['phone']] || '',
        matters: [],
        block: defaultBlock,
        rate: row[columnMappingClients['rate']] || 0,
      };
    });

    const matters = csvDataMatters.map(row => ({
      uuid: row[columnMappingMatters['uuid']] || '',
      profile_id: session.user.id,
      description: row[columnMappingMatters['description']] || '',
      client_uuid: row[columnMappingMatters['client_uuid']] || '',
    }));

    if (clientsToInsert.length === 0 && matters.length === 0) {
      alert('No data to submit.');
      return;
    }
    if (clientsToInsert.length == 0) {
      clientsToInsert = [...clients];
    }

    for (const matter of matters) {
      try {
        const { error: insertError } = await supabase
          .from('matters')
          .upsert(matter);
        if (insertError) throw insertError;
      } catch (error) {
        console.error('Error inserting matter:', error);
        alert('Error inserting matter. Please try again.');
        continue;
      }
      
      // find the client for this matter
      const client = clientsToInsert.find(c => c.uuid === matter.client_uuid);
      if (client && !client.matters.includes(matter.uuid)) {
        client.matters.push(matter.uuid);
      }
    }

    console.log("Clients and Matters Submitted");
    console.log(clientsToInsert);
    console.log(matters);

    for (const client of clientsToInsert) {
      const { data: existingClients, error } = await supabase
        .from('clients')
        .select('*')
        .eq('profile_id', session.user.id)
        .neq('uuid', client.uuid)
        .eq('name', client.name);
      if (error) {
        console.error('Error fetching existing clients:', error);
        alert (`Error adding clients. Please try again.`);
        return;
      }
    
      if (existingClients.length > 0) {
          const replace = window.confirm(`You have already manually added a client with name "${client.name}". Do you want to replace it?`);
          if (replace) {
            const { error: updateError } = await supabase
              .from('clients')
              .update(client)
              .eq('profile_id', session.user.id)
              .eq('name', client.name);
            
            if (updateError) {
              console.error('Error updating client:', updateError);
              alert(`Error updating client "${client.name}".`);
            }

            // delete matters of the old client, not in the new client
            const oldClient = existingClients[0];
            if (oldClient) {
              const mattersToDelete = oldClient.matters.filter(matter => !client.matters.includes(matter));
              for (const matter of mattersToDelete) {
                const { error: deleteError } = await supabase
                  .from('matters')
                  .delete()
                  .eq('uuid', matter);
                if (deleteError) {
                  console.error('Error deleting matter:', deleteError);
                  alert('Error deleting matter. Please try again.');
                }
              }
            }
            continue;
          }
      }
      const { data: existingClients2, error2 } = await supabase
        .from('clients')
        .select('*')
        .eq('profile_id', session.user.id)
        .eq('uuid', client.uuid)
      if (error2) {
        console.error('Error fetching existing clients:', error2);
        alert (`Error adding clients. Please try again.`);
        return;
      }
      if (existingClients2.length > 0) {
        if (matters.length > 0) { // if we have not gotten the matters, updating the client will remove its matters
          // update client
          const { error: updateError } = await supabase
            .from('clients')
            .update(client)
            .eq('profile_id', session.user.id)
            .eq('uuid', client.uuid);
          if (updateError) {
            console.error('Error updating client:', updateError);
          }
        }
      } else {
        const { error: insertError } = await supabase
          .from('clients')
          .insert(client);
        if (insertError) {
          console.error('Error inserting client:', insertError);
          alert('Error inserting client. Please try again.');
        }
      }
    }

    fetchClients();
    fetchMatters();
    if (csvDataClients.length > 0) {
      if (csvDataMatters.length > 0) {
        alert('Clients and matters added.');
      } else {
        alert('Clients added.');
      }
    } else {
      alert('Matters added.');
    }
    resetState();
  };

  return (
    <div>
      <button className='button white' onClick={() => setShowModal(true)}>Upload CSV/XLSX</button>
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel="Map Columns"
        className="large-modal"
      >
        <h2>Column Names</h2>
        <p>Let us know the column names in your files for each of the following properties. Starred properties are required. Leave blank if not necessary or not available.</p>
        <p>Files may contain existing clients; submitting will update existing clients and insert new ones.</p>
        {(errorFieldsClients.length > 0 || errorFieldsMatters.length > 0) && (
          <div style={{ color: 'red' }}>
            Make sure to fill out the required fields and ensure that the column names are correct.
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '48%' }}>
            <h3>Clients</h3>
            <input
              type="file"
              accept=".csv, .xlsx"
              ref={clientsFileInputRef}
              onChange={(e) => handleFileUpload(e, 'clients')}
            />
            
            {requiredFieldsClients.map(([field, outwardName]) => (
              <div key={field}>
                <label>{outwardName}</label>
                <input
                  type="text"
                  value={columnMappingClients[field] || ''}
                  onChange={(e) => setColumnMappingClients({ ...columnMappingClients, [field]: e.target.value })}
                  style={{ borderColor: errorFieldsClients.includes(field) ? 'red' : 'initial', width: '100%' }}
                />
              </div>
            ))}
          </div>
          
          <div style={{ width: '48%' }}>
            <h3>Matters</h3>
            <input
              type="file"
              accept=".csv, .xlsx"
              ref={mattersFileInputRef}
              onChange={(e) => handleFileUpload(e, 'matters')}
            />
            
            {requiredFieldsMatters.map(([field, outwardName]) => (
              <div key={field}>
                <label>{outwardName}</label>
                <input
                  type="text"
                  value={columnMappingMatters[field] || ''}
                  onChange={(e) => setColumnMappingMatters({ ...columnMappingMatters, [field]: e.target.value })}
                  style={{ borderColor: errorFieldsMatters.includes(field) ? 'red' : 'initial', width: '100%' }}
                />
              </div>
            ))}
          </div>
        </div>
        <button className = 'button white' onClick={handleSubmit} disabled = {csvDataClients.length + csvDataMatters.length === 0} style={{ marginTop: '20px' }}>Submit</button>
      </Modal>
    </div>
  );
}

export default UploadClients;