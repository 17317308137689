import { useEffect, useState } from 'react';
import { supabase } from './supabaseClient.js';
import TimeInputField from './components/timeInputField.js';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { blockEntries } from './functions/blockEntries.js';
import { handleDelete } from './functions/handleDelete.js';
import fetchClientsMatters from './functions/fetchClientsMatters.js';
import { CustomSelect } from './components/customSelect.js';
import './styles/style.css';
import e from 'cors';

export default function EntriesDay( {session, billingSoftware, day, client, timezone, today, entries, setEntries, saveEntries, saveEnabled, setSaveEnabled, entrySelectToggle, setShowDelete} ) {
    //const [entries, setEntries] = useState([]);
    const [clients, setClients] = useState([]);
    const [matters, setMatters] = useState({});
    const [loading, setLoading] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [tooltip, setTooltip] = useState(null); // uuid of the info icon where the tooltip is displayed
    const [activities, setActivities] = useState([ 'Phone Call', 'Court Time', 'Deposition', 'Research', 'Review', 'Training', 'Prepare', 'Analyze', 'Correspondence', 'Meeting', 'Draft', 'Break' ]);

    useEffect(() => {
      // Function to set pageLoaded to true
      const handleLoad = () => {
        setPageLoaded(true);
      };
      // Add event listener for the load event
      window.addEventListener('load', handleLoad);
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('load', handleLoad);
    }, []); // Empty dependency array to run only once on mount
  
    useEffect(() => {
      fetchEntries();
      fetchActivities();
      fetchClientsMatters(setClients, setMatters, supabase, session);
    }, [pageLoaded, session, day, client]);

    // function to process entries so that the time_taken column gets divided by 10.0
    function sup_to_display(entries) {
      return entries.map(entry => ({
        ...entry,
        time_taken: entry.time_taken / 10.0
      }));}
    
    //console.log("Entries")
    //console.log(entries)

    /*
    useEffect(() => {
        console.log("Need to verify client changed somewhere")
        saveEntries();
    }, [
        entries ? JSON.stringify(entries.map(entry => entry.need_to_verify_client)) : '',
    ]);*/

    /*
    console.log("Entries component:")
    console.log(day)*/
  
    async function fetchEntries() {
        // fetch entries to be displayed
        const dayDate = new Date(day).toISOString().split('T')[0];
        //console.log(dayDate)
        try {
          let data; let error;

          if (client == null) {
            // If no client is selected, fetch all entries for the user
            ({ data, error } = await supabase
              .from('block_entries')
              .select('*')
              .filter('profile_id', 'eq', session.user.id)
              .filter('date', 'eq', dayDate));

            const updatedData = sup_to_display(data).sort((a, b) => new Date(a.time) - new Date(b.time));
            const sortedEntries = updatedData.sort((a, b) => new Date(a.time) - new Date(b.time));
            setEntries(sortedEntries.map(entry => ({ ...entry, selected: false })));
          } else {
            // otherwise, fetch entries for the selected client
            ({ data, error } = await supabase
              .from('block_entries')
              .select('*')
              .filter('profile_id', 'eq', session.user.id)
              .filter('client_num', 'eq', client.uuid)
              .filter('date', 'eq', dayDate));

            const updatedData = sup_to_display(data).sort((a, b) => new Date(a.time) - new Date(b.time));
            const sortedEntries = updatedData.sort((a, b) => new Date(a.time) - new Date(b.time));
            setEntries(sortedEntries.map(entry => ({ ...entry, selected: false })));
          }

        } catch (error) {
          console.error('Error fetching entries:', error);
        }
        //console.log("Fetched entries")
        //console.log(entries)
  
        setLoading(false);
    }
    async function fetchActivities() {
        // fetch activities to be displayed
      const { data, error } = await supabase
        .from('activities')
        .select('activities')
        .eq('profile_id', session.user.id);
      if (error) {
        console.error('Error fetching activities:', error);
        return;
      }
      if (data && data.length > 0) {
        setActivities(data[0].activities);
      }
    }

    if (loading) {
      return <div>Loading...</div>;
    }

    async function handleInputChange(index, field, value, uuid = null) {
        if (field === 'time') {
            value = moment.tz(value, 'HH:mm', timezone).format();
        }
        //console.log("handleInputChange")
        //console.log(field, value)
        //console.log(entries)
        const newEntries = [...entries];
        newEntries[index][field] = value;

        if (field === 'client') {
          newEntries[index]['client_num'] = uuid;
        }
        if (field === 'matter') {
          newEntries[index]['matter_num'] = uuid;
        }

        setEntries(newEntries);

        if (field === 'time' || field === 'client' || field === 'matter' || field === 'activity' || field === "need_to_verify_client") {
            // update this field in supabase
            let updateObject = { [field]: value };
            if (field === 'client' || field === 'matter') {
                updateObject[field + '_num'] = uuid;
            }
            //console.log("Update object")
            //console.log(updateObject)
            //console.log(entries[index].uuid)

            const { data, error } = await supabase
                .from('block_entries')
                .update(updateObject)
                .match({ uuid: entries[index].uuid, profile_id: session.user.id});
                //.eq('uuid', entries[index].uuid);
            if (error) {
                console.error('Error updating entry:', error);
                return;
            }

            // don't do anything to saveEnabled since we've saved this change
        } else {
            setSaveEnabled(true);
        }
        //setSaveEnabled(true);
    }

    const handleAddEntry = async () => {
      //let timestamp = new Date().toISOString();

      let dayMoment = moment.tz(day, timezone); // selected day
      let currentTime = moment().tz(timezone); // current time in timezone

      // we want the current time on the specified day
      currentTime.year(dayMoment.year());
      currentTime.month(dayMoment.month());
      currentTime.date(dayMoment.date());

      let timestamp = currentTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ');

      const newEntry = {
        uuid: session.user.id + ' ' + timestamp + " new",
        profile_id: session.user.id,
        date: timestamp.split('T')[0],
        type: 'unk',
        time: timestamp,
        //time_taken: 1,
        description: '',
      };

      const blockEntry = {
        uuid: uuidv4(),
        profile_id: session.user.id,
        date: timestamp.split('T')[0],
        time: timestamp,
        //time_taken: 1,
        description: '',
        entries: [newEntry.uuid],
      }
      
      const { error2 } = await supabase
        .from('entries')
        .insert(newEntry);
      if (error2) {
        console.error('Error inserting new entry:', error2);
        return;
      }
      const { error } = await supabase
        .from('block_entries')
        .insert(blockEntry);
      if (error) {
        console.error('Error inserting block entry:', error);
        return;
      }
    
      blockEntry.selected = false;
      setEntries([...entries, blockEntry]);
    };

    function selectEntry(id) {
      //console.log("Selecting entries")
      //console.log(selectedEntries)
      //console.log(entries)
      entrySelectToggle(id);
    }

    async function handleDelete1(index) {
        await handleDelete(supabase, [entries[index]], entries, setEntries, index);
        await fetchEntries(); // refresh the entries displayed
    }

    const verifyAllClients = () => {
      // call handle input change for all entries to set need_to_verify_client to false
      for (let i = 0; i < entries.length; i++) {
        handleInputChange(i, 'need_to_verify_client', false);
      }
      /*const updatedEntries = entries.map(entry => ({
        ...entry,
        need_to_verify_client: false,
      }));
      setEntries(updatedEntries);*/
    };

    const handleMouseEnter = (uuid) => {
        setTooltip(uuid);
    }
    const handleMouseLeave = (uuid) => {
        setTooltip(null);
    }


    if (loading) {
      return <div>Loading...</div>;
    }

    const anyNotVerified = entries.some(entry => entry.need_to_verify_client);
  
    return (
      <div className="form-widget" style={{ color: 'black' }}>
        <div className="entry-table-container">
        <table className="entry-table">
          <thead>
            <tr>
              <th className="select-header"></th>
              <th className="timespent-header">When</th>
              <th className="timespent-header">Time (hrs)</th>
              <th className="client-header">Client</th>
              <th className="matter-header">Matter</th>
              <th className="activity-header">Activity</th>
              <th className="description-header">Description</th>
            </tr>
          </thead>
          <tbody>
            {entries ? entries.map((entry, index) => (
              <tr key={index} style={{verticalAlign: 'center'}}>
                <td><input 
                  type="checkbox"
                  checked={entry.selected}
                  onChange={() => selectEntry(index)}
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                /></td>
                <TimeInputField index={index} entry={entry} field="time" handleInputChange={handleInputChange} timezone={timezone} fetchEntries={fetchEntries}/>
                <TimeInputField index={index} entry={entry} field="time_taken" handleInputChange={handleInputChange} timezone={timezone} fetchEntries={fetchEntries}/>
                <TimeInputField index={index} entry={entry} field="client" handleInputChange={handleInputChange} clients={clients} matters={matters}/>
                <TimeInputField index={index} entry={entry} field="matter" handleInputChange={handleInputChange} clients={clients} matters={matters}/>
                <TimeInputField index={index} entry={entry} field="activity" handleInputChange={handleInputChange} activities={activities} />
                <TimeInputField index={index} entry={entry} field="description" handleInputChange={handleInputChange} />
                <td>
                {(entry.sent_to_billing_sw || entry.billed) ? (
                  <div
                    className="info-icon"
                    onMouseEnter={() => handleMouseEnter(entry.uuid)}
                    onMouseLeave={() => handleMouseLeave(entry.uuid)}
                    style={{ textAlign: 'center', cursor: 'pointer', position: 'relative' }}>
                      ⓘ
                      {(tooltip === entry.uuid) && (
                        <div className="tooltip">
                          {entry.sent_to_billing_sw ? `Entry sent to ${capitalize(billingSoftware)}` :
                          entry.billed ? "Entry has been billed." : ""}
                        </div>
                      )}
                    </div>
                  ) : (
                    <button className = "delete-button" onClick={() => {
                        if (window.confirm('Are you sure you want to delete this entry?')) {
                          handleDelete1(index);
                        }
                      }}><i className="fas fa-times"></i>
                    </button>
                  )}
                </td>
                </tr>
            )) : <tr><td>No entries</td></tr>}
          </tbody>
        </table>
      </div>

      <div style={{display: 'flex', padding: '8px'}}>
        <button className="button secondary" onClick={() => handleAddEntry()}>
          <i className="fas fa-plus" style={{fontSize: '13px'}}></i>
        </button>
        <button
          className="button primary"
          style={{ width: '350px', marginLeft: '20px' }}
          onClick={() => saveEntries({ entries })}
          disabled={loading || !saveEnabled}
        >
          {loading ? 'Loading ...' : 'Save'}
        </button>
      </div>

      <div style={{paddingLeft: '13px'}}>
        {anyNotVerified && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
            <h3 className='error-text'>There are a few auto-assigned clients needing verification.</h3>
            <button
              className="button white"
              onClick={verifyAllClients}
            >
              Verify All
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}