import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { supabase } from '../../supabaseClient';
import CryptoJS from 'crypto-js';
import { generateCsrfToken } from '../../Auth';
import { encrypt, decrypt } from '../encryption';
import devLog from '../devLog';

function mins_since(time1) {
    return Math.floor((new Date() - new Date(time1)) / 60000);
}

export default async function queryClio(force = false) {
    
    devLog("Called queryClio")

    let session;
    let clioAccessToken;
    try {
        session = await supabase.auth.getSession();
        session = session.data.session;
        if (!force) {
            const { data, error } = await supabase
                .from('profiles')
                .select('last_queried_clio')
                .eq('profile_id', session.user.id);
            if (error) {
                console.error('Error fetching last_updated:', error);
                return;
            }
            const last_updated = new Date(data[0].last_queried_clio) || new Date(new Date().setDate(new Date().getDate() - 1));
            if (mins_since(last_updated) < 30) {
                return;
            }
        }

        const { data: profileData, error: profileError } = await supabase
            .from('integrations')
            .select('clio_access_token')
            .eq('profile_id', session.user.id);
        if (profileError) {
            console.error('Error fetching clio access token:', profileError);
            return;
        }
        //const key = process.env.REACT_APP_CLIO_ENCRYPTION_KEY;
        //clioAccessToken = CryptoJS.AES.decrypt(profileData[0].clio_access_token, key).toString(CryptoJS.enc.Utf8);
        //const encryptedID = CryptoJS.AES.encrypt(session.user.id, key).toString();
        clioAccessToken = await decrypt(profileData[0].clio_access_token, 'clio');
        devLog("Clio Access Token:", clioAccessToken)
        const encryptedID = await encrypt(session.user.id, 'clio');
        
        devLog('Calling getclients');
        const clients = await getClientsFromClio(supabase, session, encryptedID);
        //console.log('Got clients:', clients);
        if (!clients) {
            console.error("Couldn't get clients from Clio");
            return;
        }

        const user = await getUserFromClio(supabase, session, encryptedID);
        //console.log('Got user:', user);
        if (!user) {
            console.error("Couldn't get user from Clio");
            return;
        }

        // encrypt user id
        //const encryptedUserID = CryptoJS.AES.encrypt((user.id).toString(), key).toString();
        const encryptedUserID = await encrypt((user.id).toString(), 'clio');

        // update last_updated
        const { error: updateError1 } = await supabase
            .from('integrations')
            .update({ 'clio_id': encryptedUserID })
            .eq('profile_id', session.user.id);
        if (updateError1) {
            console.error('Error updating last_updated:', updateError1);
            return;
        }
        const { error: updateError2 } = await supabase
            .from('profiles')
            .update({ 'last_queried_clio': new Date() })
            .eq('profile_id', session.user.id);
        if (updateError2) {
            console.error('Error updating last_updated:', updateError2);
            return;
        }
    } catch (error) {
        console.error('Error in queryClio:', error);
        return;
    }
    
}

export async function getClientsFromClio(supabase, session, encryptedID) {
    devLog("Get clients from Clio")
    const serverEndpoint = process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true' ?
        process.env.REACT_APP_PRODUCTION_URL + '/api/clio/clients-matters'
        : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/clio/clients-matters`;
    
    const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks
    // store the protection string in supabase
    const { _, error } = await supabase
        .from('integrations')
        .update({ csrf_token: CSRF_PROTECTION_STRING })
        .eq('profile_id', session.user.id);
    if (error) {
        console.error('Error updating CSRF token in Supabase:', error);
        return;
    }
    
    let clients;
    let matters;
    
    try {
        const response = await fetch(serverEndpoint, {
          method: 'GET', // or 'POST' if you're sending the token in the body
          headers: {
            //'X-Clio-Token': clioAccessToken,
            'Content-Type': 'application/json',
            'X-CSRF-Token': CSRF_PROTECTION_STRING,
            'X-Profile-ID': encryptedID,
          }
        });

        if (response.status === 401) {
            // set the user's Clio access token to null
            const { data: updateData, error: updateError } = await supabase
                .from('integrations')
                .update({ clio_access_token: null })
                .eq('profile_id', session.user.id);
            alert('Your Clio session has expired. Please log in again.');
            window.location.href = '/';
            return null;
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        ({clients, matters} = await response.json());
    } catch (error) {
        console.error('Error fetching clients:', error);
        return null;
    }
    if (!clients || !matters) {
        console.error('No clients or no matters found');
        return null;
    }

    // get default client settings
    const { data: data, error: defaultClientSettingsError } = await supabase
        .from('profiles')
        .select('*')
        .eq('profile_id', session.user.id)
        .single();
    if (defaultClientSettingsError) {
        console.error('Error fetching default client settings:', defaultClientSettingsError);
        return null;
    }
    const defaultBlock = data.default_block;

    //console.log("Matters")
    //console.log(matters)
    const matters_to_insert = matters
        .filter(matter => matter.client != null) // check for more things?
        .filter(matter => matter.client.id != null)
        .map(matter => {
            return {
                uuid: matter.id,
                profile_id: session.user.id,
                description: matter.description,
                client_uuid: matter.client.id,
                // display name?
            };
    });
    //console.log("Matters to insert")
    //console.log(matters_to_insert)

    devLog("Clients received from Clio")
    //console.log(clients)

    // insert these into supabase
    const clients_to_insert = clients.map(client => {
        return {
            uuid: client.id,
            profile_id: session.user.id,
            name: client.name || '',
            first_name: client.first_name || '',
            last_name: client.last_name || '',
            email: client.primary_email_address,
            phone: client.primary_phone_number,
            is_company: client.type === 'Company',
            matters: [],
            block: defaultBlock,
            rate: 0, // need to add this to the Clio API & have them fill it in during setup
        };
    })
    devLog("Clients to insert")
    devLog(clients_to_insert)

    for (const matter of matters_to_insert) {
        const client = clients_to_insert.find(client => client.uuid === matter.client_uuid)
        if (!client) { // sometimes 
            //console.error('Client not found:', matter);
            continue;
        }
        client.matters.push(String(matter.uuid));

        const { data: insertData, error: insertError } = await supabase
            .from('matters')
            .upsert(matter)
            .eq('uuid', matter.uuid)
            .eq('profile_id', matter.profile_id);
        if (insertError) {
            console.error('Upsert error:', insertError);
            break; // or handle the error as needed
        }
    }

    //console.log("Clients to insert")
    //console.log(clients_to_insert)
    for (const client of clients_to_insert) {
         // Check if client exists
         let { data: existingClients, error: selectError } = await supabase
            .from('clients')
            .select('*')
            .eq('uuid', client.uuid)
            .eq('profile_id', client.profile_id);
        if (selectError) {
            console.error('Select error:', selectError);
        }

        if (selectError || existingClients.length === 0) {
            // insert client
            const { data: insertData, error: insertError } = await supabase
                .from('clients')
                .insert([client]);
            if (insertError) {
                console.error('Insert error:', insertError);
                continue;
            }
        } else {
            // update client
            if (!client.email) {
                client.email = existingClients[0].email;
            } if (!client.phone) {
                client.phone = existingClients[0].phone;
            } if (!client.name) {
                client.name = existingClients[0].name;
                client.first_name = existingClients[0].first_name || '';
                client.last_name = existingClients[0].last_name || '';
            } if (client.matters.length === 0) {
                client.matters = existingClients[0].matters;
            }
            const { data: updateData, error: updateError } = await supabase
                .from('clients')
                .update({email: client.email, phone: client.phone, name: client.name, first_name: client.first_name, matters: client.matters})
                .eq('uuid', client.uuid)
                .eq('profile_id', client.profile_id);
            if (updateError) {
                console.error('Update error:', updateError);
                continue;
            }
        }
    }

    return clients_to_insert;
}

async function getUserFromClio(supabase, session, encryptedID) {
    const serverEndpoint = process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true'
        ? process.env.REACT_APP_PRODUCTION_URL + '/api/clio/user'
        : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/clio/user`;
    
    const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks
    // store the protection string in supabase
    const { _, error } = await supabase
        .from('integrations')
        .update({ csrf_token: CSRF_PROTECTION_STRING })
        .eq('profile_id', session.user.id);
    if (error) {
        console.error('Error updating CSRF token in Supabase:', error);
        return;
    }
    let user;
    try {
        const response = await fetch(serverEndpoint, {
        method: 'GET', // or 'POST' if you're sending the token in the body
        headers: {
            // Assuming you're passing the token in a custom header for security
            //'X-Clio-Token': clioAccessToken,
            'Content-Type': 'application/json',
            'X-CSRF-Token': CSRF_PROTECTION_STRING,
            'X-Profile-ID': encryptedID,
        }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
        }

        user = await response.json();
        //console.log('User fetched:', user);
        return user;
    } catch (error) {
        console.error('Error fetching user:', error);
        return null;
    }
}