import devLog from "../devLog";
export default async function refreshZoomToken( { supabase }) {
    devLog("Refreshing Zoom Token")
    let session = await supabase.auth.getSession();
    session = session.data.session;
    // get the encrypted refresh token from supabase
    const { data: tokenData, error: tokenError } = await supabase
        .from('integrations')
        .select('zoom_refresh_token')
        .eq('profile_id', session.user.id);
    if (tokenError || !tokenData || !tokenData.length) {
        console.error('Error fetching zoom refresh token from Supabase:', tokenError);
        return;
    }
    if (!tokenData[0].zoom_refresh_token) {
        // remove the access and refresh tokens from supabase
        const { error: removeError } = await supabase
            .from('integrations')
            .update({ zoom_access_token: null, zoom_refresh_token: null })
            .eq('profile_id', session.user.id);
        if (removeError) console.error('Error removing zoom access token:', removeError);
        return;
    }
    const encryptedRefreshToken = tokenData[0].zoom_refresh_token;
    
    const endpoint = '/api/zoom/refresh-token';
    const url =  process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true' ?
    process.env.REACT_APP_PRODUCTION_URL + endpoint
    : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}${endpoint}`;
    const payload = {
        enc_refreshToken: encryptedRefreshToken
    };
    devLog(url)

    let data;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        if (!response.ok) {
            devLog(response)
            throw new Error(`Error refreshing Zoom token: ${response.status}`);
        }

        data = await response.json();
        devLog('Token refreshed successfully:', data);
    } catch (error) {
        console.error('Error refreshing Zoom token:', error);
        // remove the access and refresh tokens from supabase
        const { error: removeError } = await supabase
            .from('integrations')
            .update({ zoom_access_token: null, zoom_refresh_token: null })
            .eq('profile_id', session.user.id);
        if (removeError) console.error('Error removing zoom access token:', removeError);
        return;
    }

    const encryptedNewAccessToken = data.access_token;
    const encryptedNewRefreshToken = data.refresh_token;

    devLog("Refreshed Client Access Token")
    devLog(encryptedNewAccessToken)
    devLog("New Encrypted Refresh Token")
    devLog(encryptedNewRefreshToken)

    // update the access and refresh tokens in supabase
    const { error: updateError } = await supabase
        .from('integrations')
        .update({ zoom_access_token: encryptedNewAccessToken, zoom_refresh_token: encryptedNewRefreshToken })
        .eq('profile_id', session.user.id);
    if (updateError) console.error('Error updating zoom access token:', updateError);
}