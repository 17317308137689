import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient'
import { useEffect } from 'react';
import moment from 'moment-timezone';
import Navbar from './../components/Navbar';
import '../styles/style.css';
import Modal from 'react-modal';
import devLog from '../functions/devLog';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

export default function ViewBills() {
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState({}); // Dictionary of clients by UUID
    const [matters, setMatters] = useState({}); // Dictionary of matters by UUID
    const [bills, setBills] = useState([]);
    const [session, setSession] = useState(null);
    const [deletingBill, setDeletingBill] = useState(null);
    const [markUnbilled, setMarkUnbilled] = useState(true);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            devLog("Fetching data")
            const session = (await supabase.auth.getSession()).data.session;
            setSession(session);

            const { data: clients, error: clientsError } = await supabase
            .from('clients')
            .select('*')
            .eq('profile_id', session.user.id);
            if (clientsError) {
                console.error('Error fetching clients:', clientsError);
                return;
            }
            const clientsDictionary = clients.reduce((acc, client) => {
                acc[client.uuid] = client;
                return acc;
            }, {});
            setClients(clientsDictionary);

            const { data: matters, error: mattersError } = await supabase
            .from('matters')
            .select('*')
            .eq('profile_id', session.user.id);
            if (mattersError) {
                console.error('Error fetching matters:', mattersError);
                return;
            }
            const mattersDictionary = matters.reduce((acc, matter) => {
                acc[matter.uuid] = matter;
                return acc;
            }, {});
            setMatters(mattersDictionary);

            const {data: bills, error: billsError} = await supabase
            .from('invoices')
            .select('*')
            .eq('profile_id', session.user.id);
            if (billsError) {
                console.error('Error fetching bills:', billsError);
                return;
            }

            setBills(bills);
            setLoading(false);
        };

        fetch();
    }, []);

    const handleViewBill = async ( bill ) => {
        // generate a signed URL for the PDF
        const { data: signedURL, error: signedUrlError } = await supabase
            .storage
            .from('invoices')
            .createSignedUrl(bill.pdf_name, 600);  // expiration time in seconds
        if (signedUrlError) {
            console.error('Error generating signed URL for PDF:', signedUrlError);
            return;
        }
        devLog('Signed URL for the PDF:', signedURL);

        // open a new tab with the signed URL
        window.open(signedURL.signedUrl, '_blank');
    }
    const handleDownloadBill = async ( bill ) => {
        // download the PDF
        const { data: pdf, error: pdfError } = await supabase
            .storage
            .from('invoices')
            .download(bill.pdf_name);
        if (pdfError) {
            console.error('Error downloading PDF:', pdfError);
            return;
        }
        devLog('PDF:', pdf);

        // create a blob URL for the PDF
        const blob = new Blob([pdf], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        // create a link element and click it to download the PDF
        const link = document.createElement('a');
        link.href = url;
        link.download = bill.pdf_name;
        link.click();
    }

    async function handleDeleteBill(bill) {
        devLog('Deleting bill:', bill);
        devLog(bill.pdf_name);

        // Optionally mark entries as unbilled based on user input
        let promises = [];

        if (markUnbilled) {
            for (let entry of bill.orig_entries) {
                // Mark the entry as unbilled
                const promise = supabase
                    .from('block_entries')
                    .update({ billed: false })
                    .match({ profile_id: session.user.id, uuid: entry.uuid });
                promises.push(promise);
            }
        }

        // Delete the invoice from Supabase
        const promise1 = supabase
            .from('invoices')
            .delete()
            .match({ profile_id: session.user.id, pdf_name: bill.pdf_name });
        promises.push(promise1);
        // delete the PDF from storage
        const promise2 = supabase
            .storage
            .from('invoices')
            .remove([bill.pdf_name]);
        promises.push(promise2);

        try {
            const results = await Promise.all(promises);
            // Check for errors in the results
            for (const result of results) {
                if (result.error) {
                    console.error('Error deleting bill:', result.error);
                }
            }
        } catch (error) {
            console.error('Error deleting bill:', error);
        }
        setDeleteModalOpen(false);
        window.location.reload();
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    return (

        <div className='form-widget'>
            {/*<Navbar />
            <h1>View Bills</h1>*/}
            <div>
                {bills.length > 0 ? (
                    <table className="bill-table">
                    <thead>
                      <tr className="bill-table-header">
                        <th className="bill-table-cell">View</th>
                        <th className="bill-table-cell">Client</th>
                        <th className="bill-table-cell">Matters</th>
                        <th className="bill-table-cell">Start Date</th>
                        <th className="bill-table-cell">End Date</th>
                        <th className="bill-table-cell">Balance Due</th>
                        <th className="bill-table-cell">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bills.map((bill, index) => (
                        <tr key={index}>
                          <td className="bill-table-cell">
                            <button 
                                onClick={() => handleViewBill(bill)}
                                className="view-bill-button"
                            >
                                View Bill
                            </button>
                          </td>
                          <td className="bill-table-cell">{bill.clients.map(client_id => clients[client_id].name).join(', ')}</td>
                          <td className="bill-table-cell">{bill.matters.map(matter_id => matters[matter_id].description).join(', ')}</td>
                          <td className="bill-table-cell">{moment(bill.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
                          <td className="bill-table-cell">{moment(bill.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
                          <td className="bill-table-cell">${bill.total_due}</td>
                          <td className="bill-table-cell">
                            <button 
                                onClick={() => handleDownloadBill(bill)}
                                className="view-bill-button"
                            >
                                Download
                            </button>
                          </td>
                          <td className="bill-table-cell" style={{border: 'none'}}>
                            <button 
                                onClick={() => {setDeletingBill(bill); setDeleteModalOpen(true)}}
                                className="view-bill-button"
                            >
                                X
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                    <p>No invoices found.</p>
                )}
            </div>

            <Modal
            isOpen={deleteModalOpen}
            onRequestClose={() => {
                setDeleteModalOpen(false);
                }}
              contentLabel="Add Client"
              className="small-modal"
            >
            <h2>Delete Invoice</h2>
            <form onSubmit={async (event) => {
                event.preventDefault(); // This will prevent the default form submission behavior
                await handleDeleteBill(deletingBill);
            }}>
            <div>
                Do you want to mark all time entries within this invoice as unbilled? This means they can be included in future invoices.
            </div>
            <div style={{marginTop: '15px', marginBottom: '15px'}}><input type="checkbox" checked={markUnbilled} onChange={e => setMarkUnbilled(e.target.checked)}/>
            Yes</div>
            
              <button type="submit">Delete Invoice</button>
              <button type="button" onClick={() => {setDeleteModalOpen(false)}}>
                Cancel
              </button>
            </form>
          </Modal>

        </div>
    );
}