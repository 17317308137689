import React, { useRef, useState } from 'react';
import { supabase } from './supabaseClient'
import { useEffect } from 'react';
import Navbar from './components/Navbar';
import AddClients from './components/AddClients';
import EditClients from './components/EditClients';
import { v4 as uuidv4 } from 'uuid';
import signInWithZoom from './functions/zoom/signInWithZoom';
import Integrations from './components/Integrations';
import './styles/Settings.css';
import './styles/style.css';

export default function Settings({billingSoftware}) {
  const [timezone, setTimezone] = useState('America/New_York');
  const [clients, setClients] = useState([]);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [defaultBlock, setDefaultBlock] = useState('1'); // default block billing settings
  const [integrations, setIntegrations] = useState(null);

  const [emailDesc, setEmailDesc] = useState(["Sent email to", "regarding"]);
  const [docDesc, setDocDesc] = useState(["Edited document", ""]);
  const [meetingDesc, setMeetingDesc] = useState(["Meeting regarding", "with"]);
  const [activities, setActivities] = useState([]);
  const [newActivity, setNewActivity] = useState('');
  const isMounted = useRef(false);

  const fetch = async () => {
    if (!isMounted.current) return;
    const session = (await supabase.auth.getSession()).data.session;
    setSession(session);

    const { data, error } = await supabase
    .from('profiles')
    .select('timezone, default_block')
    .eq('profile_id', session.user.id);

    if (error) {
        alert('Failed to get profile data: ' + error.message);
    } else {
      if (isMounted.current) {
        setTimezone(data[0].timezone);
        setDefaultBlock(data[0].default_block);
      }
    }

    const { data: integrationsData, error: integrationsError } = await supabase
      .from('integrations')
      .select('*')
      .eq('profile_id', session.user.id);
    if (integrationsError) {
        console.error('Error fetching integrations:', integrationsError);
    }
    if (!integrationsData || integrationsData.length === 0) {
        console.error('Integration row not found');
    } else {
      if (isMounted.current) {
        setIntegrations(integrationsData[0]);
      }
    }

    const {data: descData, error: descError } = await supabase
      .from('custom_descriptions')
      .select('*')
      .eq('profile_id', session.user.id);

    if (descData !== null && !descError && isMounted.current) {
      for (let row of descData) {
        if (row.type === 'email') {
          setEmailDesc([row.text1, row.text2])
        } else if (row.type === 'document') {
          setDocDesc([row.text1, row.text2])
        } else if (row.type === 'meeting') {
          setMeetingDesc([row.text1, row.text2])
        }
      }
    } if (descError) {
      console.error("Desc Error: ", descError)
    }

    const {data: trialData, error: actError } = await supabase
      .from('activities')
      .select('activities')
      .eq('profile_id', session.user.id);
    if (actError) {
      console.error("Act Error: ", actError)
    }
    if (trialData == null || trialData.length === 0) {
      const { error } = await supabase
        .from('activities')
        .insert({ profile_id: session.user.id });
      if (error) {
        console.error("Error inserting activities: ", error)
      }
    }
    const {data: actData } = await supabase
      .from('activities')
      .select('activities')
      .eq('profile_id', session.user.id);
    if (actError) {
      console.error("Act Error: ", actError)
    }
    if (isMounted.current) {
      setActivities(actData[0].activities)
    }
    
  };

  useEffect(() => {
    isMounted.current = true;
    fetch();

    setLoading(false);

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleTimezoneChange = async (event) => {
    setTimezone(event.target.value);
    saveTimezone({ timezone: event.target.value });
  };

  async function saveTimezone( timezone ) {
    //console.log("Saving settings")
    //console.log(timezone)

    const { error } = await supabase
      .from('profiles')
      .update(timezone)
      .eq('profile_id', session.user.id);
    if (error) {
      alert('Failed to update timezone: ' + error.message);
    } 
  }

  if (loading || !isMounted.current || !integrations) {
    return (
      <div className="form-widget">
        {/*<Navbar />*/}
        <h1>Settings</h1>
        <p>Loading ...</p>
      </div>
    );
  }
  const integrations_dict = {
    'Zoom': {'use': 'use_zoom', 'access_token': 'zoom_access_token', 'add_func': addZoom},
    'Google': {'use': 'use_google', 'access_token': 'google_access_token', 'add_func': addGoogle},
  }
  async function addZoom() {
      // integrate with zoom
      const { error } = await supabase
        .from('integrations')
        .update({ use_zoom: true })
        .eq('profile_id', session.user.id);
      if (error) {
        console.error('Error updating Zoom integration:', error);
      }
      const newIntegrations = { ...integrations };
      newIntegrations['use_zoom'] = true;
      setIntegrations(newIntegrations);
      signInWithZoom( { session });
  }
  async function addGoogle() {
      // integrate with google
      const { error } = await supabase
        .from('integrations')
        .update({ use_google: true })
        .eq('profile_id', session.user.id);
      if (error) {
        console.error('Error updating Google integration:', error);
      }
      integrations.use_google = true;
  }
  async function remove(key) {
    // un-integrate with this app

    const { error } = await supabase
      .from('integrations')
      .update({ [integrations_dict[key]['use']]: false, [integrations_dict[key]['access_token']]: null })
      .eq('profile_id', session.user.id);
    if (error) {
      console.error('Error removing integration:', error);
    }
    const newIntegrations = { ...integrations };
    newIntegrations[integrations_dict[key]['use']] = false;
    setIntegrations(newIntegrations);
  }

    const handleSaveDesc = async () => {
      try {
        await supabase
          .from('custom_descriptions')
          .upsert({
            profile_id: session.user.id,
            type: 'email',
            text1: emailDesc[0],
            text2: emailDesc[1]
          });
        
        await supabase
          .from('custom_descriptions')
          .upsert({
            profile_id: session.user.id,
            type: 'meeting',
            text1: meetingDesc[0],
            text2: meetingDesc[1]
          });
        
        await supabase
          .from('custom_descriptions')
          .upsert({
            profile_id: session.user.id,
            type: 'document',
            text1: docDesc[0],
            text2: docDesc[1]
          });
      } catch (error) {
        console.error(error)
      }
  };

  const handleAddActivity = async () => {
    if (newActivity.trim() !== '') {
      setActivities([...activities, newActivity]);
      setNewActivity('');
    }
    //console.log("New activities: ", [...activities, newActivity])
    // update supabase
    const { error } = await supabase
      .from('activities')
      .update({ activities: [...activities, newActivity] })
      .eq('profile_id', session.user.id);
    if (error) {
      console.error('Error adding activity:', error);
    }
  };

  const handleDeleteActivity = async (index) => {
    const updatedActivities = activities.filter((_, i) => i !== index);
    setActivities(updatedActivities);
    // update supabase
    const { error } = await supabase
      .from('activities')
      .update({ activities: updatedActivities })
      .eq('profile_id', session.user.id);
    if (error) {
      console.error('Error deleting activity:', error);
    }
  };

  return (
    <div className="form-widget">
      {/*<Navbar useClio={billingSoftware==='clio'} />*/}
      <h1>Settings</h1>

      {billingSoftware !== 'none' ?
          <EditClients
            billingSoftware={billingSoftware}
            clients={clients}
            setClients={setClients}
            defaultBlock={defaultBlock}
            setDefaultBlock={setDefaultBlock}
            signedInWithClio={true}
          />
      
      : <AddClients
          defaultBlock={defaultBlock}
          setDefaultBlock={setDefaultBlock}
       />}

      {/* <Integrations session={session}></Integrations> */}
      
      {/* add a button on each to sign in if they're signed out */}
      {/*<h2 style={{marginBottom: '0px'}}>Integrations</h2>
      <p style={{margin: '0px'}}>Connect with third-party services you use during your workday so we can track your time more accurately. </p>
      

      <h3 style={{marginBottom: '0px'}}>Added</h3>
      <div className="integrations-grid">
        {Object.keys(integrations_dict).map(app => {
          if (integrations[integrations_dict[app]['use']]) {
            return (
              <div key={uuidv4()} className="integration">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                  
                  <h3>{app}</h3>
                  <button className="button white" style={{height: '30', width: '25px', aspectRatio: '1 / 1', padding: '0' }} onClick={() => {
                    remove(app);
                  }}>-</button></div>

              </div>
            );
          }
        })}
      </div>
      
      <h3 style={{marginBottom: '0px'}}>Not Added</h3>
      <div className="integrations-grid">
        {Object.keys(integrations_dict).map(app => {
          if (!integrations[integrations_dict[app]['use']]) {
            return (
              <div key={uuidv4()} className="integration">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
                <h3>{app}</h3>
                <button className="button white" style={{height: '30', width: '25px', aspectRatio: '1 / 1', padding: '0' }} onClick={() => {
                  integrations_dict[app]['add_func']();
                }}>+</button></div>

                <div>
                <button className="button white" onClick={() => {
                  integrations_dict[app]['add_func']();
                }}>Sign In</button>
                </div>

              </div>
            );
          }
        })}
      </div>*/}

      <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
        <h2 style={{margin: '0px'}}>Default descriptions</h2>
        <button className="button white" onClick={handleSaveDesc}>Save</button>    
      </div>

      <p style={{marginTop: '0px'}}>Customize the default description LegalPulse adds when it creates a new entry for an email, meeting, or a document. </p>
      <div className="desc container">
          <h3>Emails:</h3>
          <input className="desc" type="text" value={emailDesc[0]}
              onChange={(e) => setEmailDesc([e.target.value, emailDesc[1]])}
          />
          <label> names </label>
          <input className="desc" type="text" value={emailDesc[1]}
              onChange={(e) => setEmailDesc([emailDesc[0], e.target.value])}
          />
          <label> subject </label>
      </div>

      <div className="desc container">
      <h3>Meetings:</h3>
          <input className="desc" type="text" value={meetingDesc[0]}
              onChange={(e) => setMeetingDesc([e.target.value, meetingDesc[1]])}
          />
          <label> subject </label>
          <input className="desc" type="text" value={meetingDesc[1]}
              onChange={(e) => setMeetingDesc([meetingDesc[0], e.target.value])}
          />
          <label> names </label>
      </div>

      <div className="desc container">
        <h3>Documents:</h3>
          <input className="desc" type="text" value={docDesc[0]}
              onChange={(e) => setDocDesc([e.target.value, docDesc[1]])}
          />
          <label> document title </label>
          <input className="desc" type="text" value={docDesc[1]}
              onChange={(e) => setDocDesc([docDesc[0], e.target.value])}
          />
      </div>

      <h2>Activities</h2>
      <div className="activities-list">
        {Array.isArray(activities) ? (
          activities.map((activity, index) => (
            <div key={index} className="activity-item">
              <p style={{margin: '0px'}}>{activity}</p>
              <button className="delete-button" onClick={() => handleDeleteActivity(index)}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          ))
        ) : (
          <div>No activities available</div>
        )}
      </div>
      <div className='flexbox'>
      <input
        type="text"
        className="input-normal"
        value={newActivity}
        onChange={(e) => setNewActivity(e.target.value)}
        placeholder="New Activity"
      />
      <button className = "button white" onClick={handleAddActivity}>Add</button>
      </div>

      <h2>
        Timezone
      </h2>
      <label>
        <select value={timezone} onChange={handleTimezoneChange} className="settings-select">
          <option value="America/Los_Angeles">Pacific Standard Time (GMT-7)</option>
          <option value="America/Denver">Mountain Daylight Time (GMT-6)</option>
          <option value="America/Chicago">Central Standard Time (GMT-5)</option>
          <option value="America/New_York">Eastern Standard Time (GMT-4)</option>
        </select>
      </label>

    </div>
  );
}