import React from 'react';

export default function Footer () {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a href="/terms_of_service" className='link'>Terms of Service</a>
        <a href="/privacy_policy" className='link'>Privacy Policy</a>
      </div>
      <div className="footer-copyright">
        &copy; {new Date().getFullYear()} LegalPulse, Inc.
      </div>
    </footer>
  );
};