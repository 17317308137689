import { supabase } from '../../supabaseClient'
import CryptoJS from 'crypto-js';
import tokenExpired from './tokenExpired';
import devLog from '../devLog';
import { encrypt, decrypt } from '../encryption';

// function to calculate the number of minutes between 2 times
function mins_since(time1) {
    return Math.floor((new Date() - new Date(time1)) / 60000);
} function mins_between(time1, time2) {
    return Math.floor((new Date(time2) - new Date(time1)) / 60000);
}

export async function getDrafts() {
    //console.log("Called getDrafts")
    //console.log(new Date())

    //let response;
    try {
        //console.log("Entered Try Statement")

        let session = await supabase.auth.getSession();
        session = session.data.session;

        const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('last_updated_draft, provider_token')
            .eq('profile_id', session.user.id);

        if (profileError) {
            console.error('Error fetching last_updated:', profileError);
            return;
        }
        
        if (profileData[0].last_updated_draft && mins_since(profileData[0].last_updated_draft) < 2) {
            return;
        }

        /*const key = process.env.AZURE_ENCRYPTION_KEY;
        let providerToken;
        try {
            providerToken = CryptoJS.AES.decrypt(profileData[0].provider_token, key).toString(CryptoJS.enc.Utf8);
        } catch (error) {
            devLog("Error decrypting provider token:", error);
            supabase.auth.signOut();
        }*/
        const providerToken = await decrypt(profileData[0].provider_token, 'azure');
        const lastUpdatedISO = new Date(profileData[0].last_updated_draft).toISOString();

        // update the last_updated column for this user
        
        let { error: updateError } = await supabase
          .from('profiles')
          .update({ last_updated_draft: new Date() }) // set the last_updated column to the current date and time
          .eq('profile_id', session.user.id); // where the id is the current user's id

        if (updateError) {
            console.error('Error updating last_updated:', updateError);
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer' + providerToken,
                'Content-Type': 'application/json'
            },
        };

        // fetch drafts from the Microsoft Graph API
        let drafts_raw;
        try {
            drafts_raw = await fetch('https://graph.microsoft.com/v1.0/me/mailFolders/Drafts/messages', requestOptions);
        } catch (error) {
            devLog('Error fetching drafts:', error);
        }

        if (!drafts_raw || drafts_raw.status === 401) {
            // Redirect to the Auth page
            tokenExpired();
            return;
        }

        if (drafts_raw.length === 0) {
            return [];
        }
        const data = await drafts_raw.json();
        const drafts = await parseDraftData(data);

        //console.log("Drafts!!")
        //console.log(drafts);

        // get drafts from the drafts table, and find corresponding drafts and update them in supabase
        // if they don't exist, insert them
        const { data: draftsData, error: draftsError } = await supabase
            .from('drafts')
            .select('*')
            .eq('profile_id', session.user.id);
        if (draftsError) {
            console.error('Error fetching drafts:', draftsError);
        }
        // for each draft in drafts, check if it exists in draftsData
        // if it does, update it
        // if it doesn't, insert it
        for (let draft of drafts) {
            let supabaseDraft = null;
            let found = false;
            for (let draftData of draftsData) {
                if (draft.conversationID === draftData.conversation_id && draft.conversationIndex === draftData.conversation_index) {
                    supabaseDraft = draftData;
                    found = true;
                    break;
                }
            }
            if (!found) {
                // insert the draft
                devLog("Inserting draft in supabase");
                devLog("mins between:", mins_between(draft['Time Created'], draft['Time Modified']));
                const { error: insertError } = await supabase
                    .from('drafts')
                    .insert({
                        profile_id: session.user.id,
                        body_preview: draft['Body Preview'],
                        subject: draft['Subject'],
                        'to:name': draft['To'].map(arr => arr[0]),
                        'to:email': draft['To'].map(arr => arr[1]),
                        date: new Date(draft['Time Modified']).toDateString(),
                        time_created: draft['Time Created'],
                        time_modified: draft['Time Modified'],
                        conversation_id: draft['conversationID'],
                        conversation_index: draft['conversationIndex'],
                        change_key: draft['changeKey'],
                        last_updated: new Date(),
                        minutes: Math.min(mins_between(draft['Time Created'], draft['Time Modified']), 20),
                    });
                if (insertError) {
                    console.error('Error inserting draft:', insertError);
                }
            }
            // check if the draft in supabase has a time_modified less than the time_modified of the draft
            // if it does, update the draft in supabase
            // if it doesn't, do nothing

            // update the draft in supabase to increase time by 1 and change time_modified
            else {
                //console.log("Found draft in supabase")
                //console.log(supabaseDraft)
                if (draft['changeKey'] === supabaseDraft.change_key) { // has not been changed since last seen
                    const { error: updateError } = await supabase
                        .from('drafts')
                        .update({
                            last_updated: new Date(),
                        })
                        .eq('conversation_id', draft['conversationID'])
                        .eq('conversation_index', draft['conversationIndex']);
                    if (updateError) {
                        console.error('Error updating draft:', updateError);
                    }
                    continue;
                }
                //console.log(draft)

                const mins_since_checked = mins_since(supabaseDraft.last_updated);
                let minutes_to_add = mins_since_checked;
                if (mins_since_checked > 20) {
                    // not going to assume you spent over 20 minutes on the draft
                    const x = mins_between(supabaseDraft.last_updated, draft['Time Modified']);
                    minutes_to_add = Math.min(20, x);
                }
                console.log("Old Minutes:", supabaseDraft.minutes);
                console.log("Minutes to add:", minutes_to_add);
                
                const { error: updateError } = await supabase
                    .from('drafts')
                    .update({
                        time_modified: draft['Time Modified'],
                        minutes: supabaseDraft.minutes + minutes_to_add,
                        change_key: draft['changeKey'],
                        body_preview: draft['Body Preview'],
                        'to:name': draft['To'].map(arr => arr[0]),
                        'to:email': draft['To'].map(arr => arr[1]),
                        last_updated: new Date(),
                    })
                    .eq('conversation_id', draft['conversationID'])
                    .eq('conversation_index', draft['conversationIndex']);
                if (updateError) {
                    console.error('Error updating draft:', updateError);
                }
            }
        }

        return drafts;

    } catch (error) {
        console.error("Error in getDrafts:", error);
    }
}

async function parseDraftData(data) {
    //console.log("Parsing Draft Data")
    //console.log(data)
    const arr = data.value;
    let result = [];
    for (let obj of arr) {
        let subject = obj.subject;
        //let sentAt = new Date(obj.sentDateTime).toLocaleString("en-US", {timeZone: timezone});
        let created = new Date(obj.createdDateTime).toISOString();
        let modified = new Date(obj.lastModifiedDateTime).toISOString();
        let conversationID = obj.conversationId;
        let conversationIndex = obj.conversationIndex;
        let changeKey = obj.changeKey;

        let bodyPreview = obj.bodyPreview;

        let sentToDict = obj.toRecipients;
        let sentTo = [];
        for (let recipient of sentToDict) {
            sentTo.push([recipient.emailAddress.name, recipient.emailAddress.address]);
        }

        let draft = {
            "Subject" : subject,
            "Time Created" : created,
            "Time Modified" : modified,
            "To" : sentTo,
            "Body Preview": bodyPreview,
            conversationID,
            conversationIndex,
            changeKey,
        };

        result.push(draft);
    }
    return result;
}