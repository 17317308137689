const { default: devLog } = require("./devLog");

export const encrypt = async (item, type) => {

    const endpoint = '/api/encrypt';
    const url =  process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true' ?
    process.env.REACT_APP_PRODUCTION_URL + endpoint
    : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}${endpoint}`;
    const payload = {
        item,
        type
    };

    let data;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        if (!response.ok) {
            devLog(response)
            throw new Error(`Error encrypting tokens: ${response.status}`);
        }

        data = await response.json();
    } catch (error) {
        console.error('Error encrypting tokens:', error);
        return;
    }
    devLog("Item encrypted:")
    devLog(item)
    devLog("Data returned from encrypt:")
    devLog(data)
    return data.data;
}

export const decrypt = async (item, type) => {

    const endpoint = '/api/decrypt';
    const url =  process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true' ?
    process.env.REACT_APP_PRODUCTION_URL + endpoint
    : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}${endpoint}`;
    const payload = {
        item,
        type
    };

    let data;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        if (!response.ok) {
            devLog(response)
            throw new Error(`Error decrypting tokens: ${response.status}`);
        }

        data = await response.json();
    } catch (error) {
        console.error('Error decrypting tokens:', error);
        return;
    }
    //devLog("Item decrypted:")
    //devLog(item)
    //devLog("Result:")
    //devLog(data)
    return data.data;
}