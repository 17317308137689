import tokenExpired from './tokenExpired';
import CryptoJS from 'crypto-js';
import devLog from '../devLog';
import { encrypt, decrypt } from '../encryption';

// function to calculate the number of minutes between 2 times
function seconds_between(time1, time2) {
    return Math.floor((new Date(time2) - new Date(time1)) / 1000);
}

// does not work if you block during an exchange of emails
export async function getEmails({ supabase, providerToken, timezone, clients, matters }) {
    console.log("Called getEmails")

    try {

        let session = await supabase.auth.getSession();
        session = session.data.session;

        const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('provider_token, last_updated_email')
            .eq('profile_id', session.user.id);
        if (profileError) {
            console.error('Error fetching profile data:', profileError);
            return;
        }
        const { data: descData, error: descError } = await supabase
            .from('custom_descriptions')
            .select('*')
            .eq('profile_id', session.user.id)
            .eq('type', 'email');
        if (descError) {
            console.error('Error fetching description data:', descError);
            return;
        }
        let text1;
        if (!descData || !descData.length || !descData[0].text1) text1 = 'Sent email to'
        else text1 = descData[0].text1;
        let text2;
        if (!descData || !descData.length || !descData[0].text2) text2 = 'regarding'
        else text2 = descData[0].text2;
        

        //const key = process.env.AZURE_ENCRYPTION_KEY;
        //const providerToken = CryptoJS.AES.decrypt(profileData[0].provider_token, key).toString(CryptoJS.enc.Utf8);
        const providerToken = await decrypt(profileData[0].provider_token, 'azure');
        
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer' + providerToken,
                //'Content-Type': 'application/json'
            },
        };

        const lastUpdatedISO = new Date(profileData[0].last_updated_email).toISOString();

        // update the last_updated column for this user
        let { error: updateError } = await supabase
          .from('profiles')
          .update({ last_updated_email: new Date() }) // set the last_updated column to the current date and time
          .eq('profile_id', session.user.id); // where the id is the current user's id

        if (updateError) {
            console.error('Error updating last_updated:', updateError);
        }

        // fetch all mail sent after the last updated time
        const mail = await fetch(`https://graph.microsoft.com/v1.0/me/mailFolders/SentItems/messages?$filter=sentDateTime ge ${lastUpdatedISO}`, requestOptions);

        if (mail.status === 401) {
            // Redirect to the Auth page
            tokenExpired();
            return;
        }
        if (mail.error) {
            console.error('Error fetching emails:', mail.error);
            return;
        }

        if (mail.length === 0) {
            return [];
        }
        const data = await mail.json();

        if (data.value.length === 0) {
            return;
        }
        const emails = await parseSentEmailData(data.value, timezone);

        console.log("Original Emails:")
        console.log(emails.length)
        devLog(emails)

        // get the drafts from the supabase drafts table
        const { data: draftsData, error: draftsError } = await supabase
            .from('drafts')
            .select('*')
            .eq('profile_id', session.user.id);
        if (draftsError) {
            console.error('Error fetching drafts:', draftsError);
        }

        // if the conversation id and conversation index of any email is in drafts, set the time of that email to the time in drafts
        for (let email of emails) {
            for (let draft of draftsData) {
                if (email['Conversation Id'] === draft['conversation_id'] && email['Conversation Index'] === draft['conversation_index']) {
                    email['Time'] = Math.max(parseInt((draft['minutes']) / 6), 1);

                    const { error: deleteError } = await supabase
                        .from('drafts')
                        .delete()
                        .eq('profile_id', session.user.id)
                        .eq('conversation_id', draft['conversation_id'])
                        .eq('conversation_index', draft['conversation_index']);
                    if (deleteError) {
                        console.error('Error deleting draft:', deleteError);
                    }
                }
            }
        }

        // if any 2 emails have the same conversation id, combine them into one email
        let conversationId_to_index = {};
        // iterate through the indices of emails
        for (let i = 0; i < emails.length; i++) {
            let email = emails[i];
            let conversationId = email['Conversation Id'];
            // check if this conversation id is a key in the dictionary
            if (conversationId in conversationId_to_index) {
                // if it is, add this email to the list of emails with this conversation id
                conversationId_to_index[conversationId].push(i);
            } else {
                // if it's not, add this email to the dictionary
                conversationId_to_index[conversationId] = [i];
            }
        }
        //console.log("Conversation Id to Index")
        //console.log(conversationId_to_index)

        let newEmails = [];
        // iterate through the keys of the dictionary
        for (let conversationId in conversationId_to_index) {
            let indices = conversationId_to_index[conversationId];
            // if there's only one email with this conversation id, add it to the new emails
            let newEmail = emails[indices[0]]
            if (indices.length === 1) {
                newEmail.description = `${text1} ${newEmail['ToName'].join(', ')} ${text2} ${newEmail['Subject']}`;
            } else {
                // if there's more than one email with this conversation id, combine them into one email
                for (let i = 1; i < indices.length; i++) {
                    let email = emails[indices[i]];
                    newEmail['Time'] += email['Time'];
                }
                newEmail.description = `Exchanged emails with ${newEmail['ToName'].join(', ')} regarding ${newEmail['Subject']}`;
            }
            newEmails.push(newEmail);
        }

        //console.log("Combined Emails!!")
        //console.log(newEmails);

        const emailsToInsert = newEmails.map(email => ({
            uuid: session.user.id + ' ' + email['Time Sent'] + " email",
            profile_id: session.user.id,
            body_preview: email['Body Preview'],
            subject: email['Subject'],
            time_sent: email['Time Sent'],
            'to:name': email['ToName'],
            'to:email': email['ToEmail'],
            date: new Date(email['Time Sent']).toDateString(),
            time: email['Time'],
            conversation_id: email['Conversation Id'],
            conversation_index: email['Conversation Index'],
            description: email.description,
        }));


        for (let i=0; i<emailsToInsert.length; i++) {
            let email = emailsToInsert[i];
            //console.log("Email")
            //console.log(email)
            // see if any entries in the emails table have the same profile_id and conversation_id
            const { data: existingEmails, error: existingEmailsError } = await supabase
                .from('emails')
                .select('*')
                .eq('profile_id', session.user.id)
                .eq('date', email.date)
                .eq('conversation_id', email.conversation_id);
            if (existingEmailsError) {
                console.error('Error fetching existing emails:', existingEmailsError);
            }
            
            if (existingEmails.length === 0) {
                continue;
            }
            const uuid = existingEmails[0].uuid;
            const { data: existingEntries, error: existingEntriesError } = await supabase
                .from('block_entries')
                .select('*')
                .eq('profile_id', session.user.id)
                .eq('uuid', uuid)
            if (existingEntriesError) {
                console.error('Error fetching existing emails:', existingEntriesError);
            }
            if (existingEntries.length === 0) { continue; }

            emailsToInsert[i].uuid = uuid;
            emailsToInsert[i].time += existingEntries[0].time_taken;
            emailsToInsert[i].time_sent = existingEntries[0].time;
        }

        //console.log("Inserting into supabase emails table")
        //console.log(emailsToInsert)
        const emailsWODescription = emailsToInsert.map(({ description, ...rest }) => rest);

        const { error: insertError } = await supabase
            .from('emails')
            .upsert(emailsWODescription)
        if (insertError) {
            console.error('Error inserting emails:', insertError);
        }

        const entriesToInsert = emailsToInsert.map(({ uuid, profile_id, time_sent, date, subject, description, 'to:name': toName, 'to:email': toEmail, time, body_preview }) => {
            const { client, matter } = getClientAndMatter(toName, toEmail, body_preview, clients, matters);
            // see if something with the same uuid exists in the supabase entries table
            //console.log("Client")
            //console.log(client)
            //console.log("Matter")
            //console.log(matter)
            //console.log("Matters")
            //console.log(matters)
            let client_num = client ? client.uuid : null;
            let client_name = client ? client.name : null;
            return {
                uuid,
                profile_id,
                time: time_sent,
                time_taken: time,
                date,
                description,
                type: 'email',
                activity: 'Correspondence',
                client: client_name,
                client_num: client_num,
                matter_num: matter,
                matter: matter ? matters[matter].description : null,
                need_to_verify_client: client ? true : false,
                entries: [uuid],
            };
        });
        devLog("Entries to Insert")
        devLog(entriesToInsert)

        // iterate through the indices of entriesToInsert
        for (let i=0; i<entriesToInsert.length; i++) {
            let entry = entriesToInsert[i];
            // see if any entries in the emails table have the same profile_id and conversation_id
            const { data: existingEntries, error: exEntryError } = await supabase
                .from('block_entries')
                .select('*')
                .eq('profile_id', session.user.id)
                .eq('uuid', entry.uuid);
            if (exEntryError) {
                console.error('Error fetching existing entries:', exEntryError);
            }
            if (existingEntries.length > 0) {
                existingEntries[0].time_taken = entry.time_taken; // only the time and description should be updated
                if (existingEntries[0].description.slice(0, text1.length) === text1) {
                    const newDescription = `Exchanged emails with${existingEntries[0].description.slice(text1.length)}`;
                    existingEntries[0].description = newDescription;
                }
                // set the time, date, time_taken, and description to the new values
                entriesToInsert[i] = {
                    ...entriesToInsert[i],
                    time: existingEntries[0].time,
                    time_taken: existingEntries[0].time_taken,
                    description: existingEntries[0].description,
                    client: existingEntries[0].client,
                    client_num: existingEntries[0].client_num,
                    matter: existingEntries[0].matter,
                    matter_num: existingEntries[0].matter_num,
                    activity: existingEntries[0].activity,
                }
                //entriesToInsert[i] = existingEntries[0];
            }
        }

        console.log("Inserting into supabase entries table")
        console.log(entriesToInsert.length)
        devLog(entriesToInsert)

        const { error: entriesInsertError } = await supabase
            .from('entries')
            .upsert(entriesToInsert);
        if (entriesInsertError) {
            console.log('Error upserting entries:', entriesInsertError);
        }

        const blockEntriesToInsert = entriesToInsert.map(entry => ({
            ...entry,            
        }));
        const { error: entriesInsertError2 } = await supabase
            .from('block_entries')
            .upsert(blockEntriesToInsert);
        if (entriesInsertError2) {
            console.log('Error upserting entries:', entriesInsertError2);
        }

        return emails;

    } catch (error) {
        console.error("Error in getEmails:", error);
    }
}

async function parseSentEmailData(data, timezone) {
    //console.log("Parsing Sent Email Data")
    //console.log(data)
    let result = [];
    for (let obj of data) {
        let created = new Date(obj.createdDateTime);
        let sent = new Date(obj.sentDateTime);
        if (obj.meetingMessageType) { // ignore automatic meeting messages
            continue;
        }
        /*if (seconds_between(created, sent) < 1) { // either an automatic email or not worth adding to time entries
            continue;
        }*/

        let subject = obj.subject;
        let sentAt = new Date(obj.sentDateTime).toISOString();

        let bodyPreview = obj.bodyPreview;

        let sentToDict = obj.toRecipients;
        let sentToName = [];
        let sentToEmail = [];
        for (let recipient of sentToDict) {
            sentToName.push(recipient.emailAddress.name);
            sentToEmail.push(recipient.emailAddress.address);
        }
        let ccToDict = obj.ccRecipients;
        let bccToDict = obj.bccRecipients;
        for (let recipient of ccToDict) {
            sentToName.push(recipient.emailAddress.name);
            sentToEmail.push(recipient.emailAddress.address);
        }
        for (let recipient of bccToDict) {
            sentToName.push(recipient.emailAddress.name);
            sentToEmail.push(recipient.emailAddress.address);
        }

        let email = {
            "Subject" : subject,
            "Time Sent" : sentAt,
            "ToName" : sentToName,
            "ToEmail" : sentToEmail,
            "Body Preview": bodyPreview,
            "Time": 1,
            "Conversation Id": obj.conversationId,
            "Conversation Index": obj.conversationIndex,
        };

        result.push(email);
    }
    return result;
}

function getClientAndMatter(toName, toEmail, body_preview, clients, matters) {
    if (clients.length === 0) {
        return {client: null, matter: null};
    }
    // names should be an array of [first_name, last_name]
    let toNames = toName.map(name => [name.split(' ')[0].toLowerCase(), name.split(' ')[name.split(' ').length - 1].toLowerCase()]);
    let body_words = (body_preview.match(/\b[\w']+\b/g) || []).map(word => word.toLowerCase());
    //console.log("Body Preview:")
    //console.log(body_preview)
    //console.log("Body Words:")
    //console.log(body_words)

    let clientMatches = clients.map(client => ({...client, matches: 0}));

    for (let client of clients) {
        const split = client.name.split(' ');
        let first_name = split[0].toLowerCase();
        let last_name = split[split.length-1].toLowerCase();
        // first try seeing if the email address matches
        if (toEmail.includes(client.email)) {
            clientMatches[clients.indexOf(client)].matches += 1;
        }
        // now see if name matches
        if (toNames.includes([first_name, last_name])) {
            clientMatches[clients.indexOf(client)].matches += 1;
        }
        if (body_words.includes(first_name)) {
            clientMatches[clients.indexOf(client)].matches += 1;
        }
        if (body_words.includes(last_name)) {
            clientMatches[clients.indexOf(client)].matches += 1;
        }
        for (let matter_uuid of client.matters) {
            let matter = matters[matter_uuid];
            let matter_words = (matter.description.match(/\b[\w']+\b/g) || []);
            matter_words = matter_words.map(word => word.toLowerCase());
            for (let word of body_words) {
                if (matter_words.includes(word)) {
                    clientMatches[clients.indexOf(client)].matches += 1;
                }
            }
        }
    }
    //console.log("Client Matches:")
    //console.log(clientMatches)

    // sort the candidate clients
    // if there are multiple clients, return the one with the most matches
    // if there's a tie, return null
    clientMatches.sort((a, b) => b.matches - a.matches);
    if (clientMatches[0].matches === 0) {
        return {client: null, matter: null};
    }
    if (clientMatches.length === 1 || clientMatches[0].matches === clientMatches[1].matches) {
        return {client: null, matter: null};
    }

    if (clientMatches[0].matters.length === 1) {
        return {client: clientMatches[0], matter: clientMatches[0].matters[0]};
    }
    return {client: clientMatches[0], matter: null};
}