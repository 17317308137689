import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { supabase } from './supabaseClient'
import { encrypt, decrypt } from './functions/encryption';
import signInWithLawcus from './functions/lawcus/signInWithLawcus.js';
import devLog from './functions/devLog.js';

import './styles/style.css'
import CryptoJS from 'crypto-js';

export function Auth({ session, billingSoftware, setSignedInWithClio, setSignedInWithLawcus }) {
  const [loading, setLoading] = useState(false)
  //const [signedInWithAzure, setSignedInWithAzure] = useState(false); // New state to track Azure sign-in
  const location = useLocation();

  useEffect(() => {
    const fetchSession = async () => {
      console.log('fetchSession called');
      try {
        const timeout = new Promise((_, reject) => 
          setTimeout(() => reject(new Error('Timeout fetching session')), 5000)
        );

        const sessionResponse = await Promise.race([
          supabase.auth.getSession(),
          timeout
        ]);

        //const sessionResponse = await supabase.auth.getSession();
        devLog('Session:', sessionResponse);

        if (sessionResponse.error) {
          console.error('Error fetching session:', sessionResponse.error);
          return;
        }

        if (sessionResponse.data && sessionResponse.data.session) {
          //setSignedInWithAzure(true);
          //alert('Sign in with Azure successful');
        }
      } catch (error) {
        console.error('fetchSession error', error);
      }
    };

    // Check if the URL contains the OAuth callback parameters
    let params; 
    const hash = location.hash;
    if (hash) {
      params = new URLSearchParams(hash.substring(1)); // Remove the leading '#' and parse the fragment
    } else {
      params = new URLSearchParams(location.search);
    }
    if (params.has('access_token')) {
      fetchSession();
    }
    
  }, [location]);

  async function signInWithAzure() {
    devLog('signInWithAzure called');
    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'azure',
        options: {
          scopes: 'openid profile User.read email offline_access Mail.Read Mail.Read.Shared Calendars.Read Calendars.Read.Shared Files.Read Files.Read.All',
          prompt: 'consent',
          queryParams: {
            aud: "",
          },
          redirectTo: process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true' ? process.env.REACT_APP_PRODUCTION_URL : 'http://localhost:3000',
        },
      })
      /*if (data) {
        setSignedInWithAzure(true);
        const sessionResponse = await supabase.auth.getSession();
        devLog('Session:', sessionResponse);
      }*/
      if (error) throw error;

    } catch (error) {
      console.error('signInWithAzure error', error);
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  function useRestoreSession() {
    const location = useLocation();
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const accessToken = params.get('access_token');
  
      if (accessToken) {
        devLog("Restoring session")
        // Manually set the session using the access token
        supabase.auth.setSession({ access_token: accessToken });
        //setSignedInWithAzure(true);
        // Optionally, you can clear the URL parameters
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    }, [location]);
  }

  return (
    <div className="row flex flex-center">
      <div className="col-6 form-widget" style={{ padding: '60px 0 0px 0' }}>
      <img src="/LegalPulse.png" alt="LegalPulse Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} width="300" />
      
      <div>{session && (
          <button className="button white block" onClick={() => supabase.auth.signOut()}>Sign Out of Outlook</button>
      )}</div>
        <div>
          {!session ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                signInWithAzure();
              }}
              className={'button white block'}
              disabled={loading}
            >
              {loading ? "Loading": "Sign in with Outlook"}
            </button>
          ) : (
            billingSoftware === 'clio' ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                signInWithClio(session, setSignedInWithClio);
              }}
              className={'block button white'}
            >
              Sign in with Clio
            </button>
            ) : billingSoftware === 'lawcus' ? (
              <button
              onClick={(e) => {
                e.preventDefault();
                signInWithLawcus(setSignedInWithLawcus);
              }}
              className={'block button white'}
              >Sign In with Lawcus</button>
            ) : (
              <div></div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export async function signInWithClio(session, setSignedInWithClio) {
  // Redirect to Clio's OAuth endpoint, and then back to the redirect URI so the app can catch it.
  // the domain in redirectUri must be configured in Clio and supabase as an allowed redirect URI
  const clientId = process.env.REACT_APP_CLIO_CLIENT_ID;
  const deployed = process.env.REACT_APP_DEPLOYED_BOOLEAN;

  let redirectUri = deployed === 'true' 
      ? `${process.env.REACT_APP_PRODUCTION_URL}/api/clio/auth/v1/callback` 
      : `http://127.0.0.1:${process.env.REACT_APP_SERVER_PORT || 3000}/api/clio/auth/v1/callback`;
  
  const scopes = 'activities api billing calendars communications contacts custom fields documents imports general matters settings:read_write';

  const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks
  // store the protection string in supabase
  if (session && session.user && session.user.id) {
    const { data, error } = await supabase
      .from('integrations')
      .update({ csrf_token: CSRF_PROTECTION_STRING })
      .eq('profile_id', session.user.id);
    if (error) {
      console.error('Error updating CSRF token in Supabase:', error);
      return;
    }
  } else {
    return;
  }

  //const secretKey = process.env.REACT_APP_CLIO_ENCRYPTION_KEY;
  //const encryptedUserId = CryptoJS.AES.encrypt(session.user.id, secretKey).toString();
  const encryptedUserId = await encrypt(session.user.id, 'clio');
  devLog("Encrypted User ID:", encryptedUserId)
  const stateWithAdditionalData = `${CSRF_PROTECTION_STRING}-${encryptedUserId}`;
  
  //setSignedInWithClio(true);
  devLog("CSRF_PROTECTION_STRING:", CSRF_PROTECTION_STRING);
  devLog("Encrypted User ID:", encryptedUserId);
  devLog("Session:", session);
  devLog("ClientId:", clientId);
  devLog("RedirectUri:", redirectUri);
  devLog("Scopes:", scopes);

  //const stateWithAdditionalData = `${CSRF_PROTECTION_STRING}-${session.user.id}`;

  let params = new URLSearchParams({
    response_type: 'code',
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: scopes,
    state: stateWithAdditionalData,
    redirect_on_decline: true,
  });

  const clioAuthUrl = `https://app.clio.com/oauth/authorize?${params.toString()}`;
  //const clioAuthUrl = `https://app.clio.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&state=${CSRF_PROTECTION_STRING}&redirect_on_decline=true`;
  devLog(clioAuthUrl);

    
  // pause for 15 seconds to allow the user to see the URL before redirecting
  //await new Promise(r => setTimeout(r, 15000));

  window.location.href = clioAuthUrl;
}

export function generateCsrfToken(length = 32) {
  // Create a byte array of the specified length
  const bytes = new Uint8Array(length);
  // Use the crypto API to populate the array with random values
  window.crypto.getRandomValues(bytes);
  // Convert the byte array to a hexadecimal string
  return Array.from(bytes).map(b => b.toString(16).padStart(2, '0')).join('');
}