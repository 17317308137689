import { useState, useEffect } from 'react'
import { supabase } from './supabaseClient'
import Navbar from './components/Navbar';

import './styles/style.css'

export default function NeedAuthorization() {
  const [loading, setLoading] = useState(false)
  const [signedInWithAzure, setSignedInWithAzure] = useState(false); // New state to track Azure sign-in

  return (
    <div className="row flex flex-center">
      <div className="col-6 form-widget">
        {/*
      <img src="/LegalPulse.png" alt="LegalPulse Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} width="300" />
      */}
      {/*<Navbar setup={true}/>*/}
      <h3>You require admin authorization to proceed.</h3>
      </div>
    </div>
  )
}
