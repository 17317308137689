import './styles/index.css'
import { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { supabase } from './supabaseClient'
import Navbar from './components/Navbar'
import Footer from './components/Footer';
import DisplayDocument from './components/DisplayDocument';
import { Auth } from './Auth'
import Account from './Account'
import Settings from './Settings'
import Billing from './billing_screens/Billing'
import NewBill from './billing_screens/newBill'
import ViewBills from './billing_screens/viewBills'
import Billing_WB from './billing_screens/wellbornlaw/Billing'
import NewBill_WB from './billing_screens/wellbornlaw/newBill'
import EditBills_WB from './billing_screens/wellbornlaw/editBills'
import NeedAuthorization from './NeedAuthorization'
import updateTimeSaved from './functions/updateTimeSaved';
import signInWithZoom from './functions/zoom/signInWithZoom';
import signInWithGoogle from './functions/google/signInWithGoogle';
import Setup1 from './Setup1'
import Setup2 from './Setup2'
import devLog from './functions/devLog';
import CryptoJS from 'crypto-js';
import { encrypt, decrypt } from './functions/encryption';
import moment from 'moment-timezone';
import LogRocket from 'logrocket';

require('dotenv').config();

export default function Home() {
  const [session, setSession] = useState(null)
  const [signedInWithClio, setSignedInWithClio] = useState(false);
  const [signedInWithLawcus, setSignedInWithLawcus] = useState(false);
  const [setupsFinished, setSetupsFinished] = useState(2); // 2 out of 2
  const [signedInWithZoom, setSignedInWithZoom] = useState(false);
  const [signedInWithGoogle, setSignedInWithGoogle] = useState(false);
  const [needAuthorization, setNeedAuthorization] = useState(false);
  const [billingSoftware, setBillingSoftware] = useState('none'); // lowercase string: the billing software the user wants to integrate with

  const startTimeRef = useRef(Date.now());
  const isActiveRef = useRef(true); // Ref to track if the user is actively interacting with the page
  const [totalActiveTime, setTotalActiveTime] = useState(0);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      devLog("Initial session")
      devLog(session)
      if (!session) return

      // if the user isn't in profiles, sign out
      supabase
        .from('profiles')
        .select('*')
        .eq('profile_id', session.user.id)
        .then(({ data: [user] }) => {
          if (!user) {
            supabase.auth.signOut();
          }

          LogRocket.identify(session.user.id, {
            name: user.name,
            email: user.email,
          });
        })

      // update provider token and provider refresh token in the profiles table
      if (session && session.provider_token && session.provider_refresh_token) {
        //const key = process.env.AZURE_ENCRYPTION_KEY;
        //const encryptedToken = CryptoJS.AES.encrypt(session.provider_token, key).toString();
        //const encryptedRefreshToken = CryptoJS.AES.encrypt(session.provider_refresh_token, key).toString();
        //const encryptedToken = await encrypt(session.provider_token, 'azure');
        //const encryptedRefreshToken = await encrypt(session.provider_refresh_token, 'azure');
        // immediately invoked function
        (async () => {
          try {
              const encryptedToken = await encrypt(session.provider_token, 'azure');
              const encryptedRefreshToken = await encrypt(session.provider_refresh_token, 'azure');

              supabase
                .from('profiles')
                .update({
                    provider_token: encryptedToken,
                    provider_refresh_token: encryptedRefreshToken,
                })
                .eq('profile_id', session.user.id)
                .then(() => devLog('Provider token and refresh token added to profiles table'))
                .catch(console.error)
          } catch (error) {
              console.error('Error encrypting tokens:', error);
          }
        })();
      }

      supabase
        .from('pending_users')
        .select('*')
        .eq('profile_id', session.user.id)
        .then(({ data: user }) => {
          setNeedAuthorization(user.length > 0);
      })

      supabase
        .from('profiles')
        .select('setups_finished')
        .eq('profile_id', session.user.id)
        .then(({ data: [user] }) => {
          setSetupsFinished(user.setups_finished)
      })

      supabase
        .from('integrations')
        .select('*')
        .eq('profile_id', session.user.id)
        .then(({ data: [user], error: error }) => {
          if (!user) {
            console.log('User not found');
            return;
          }
          setBillingSoftware(user.billing_software)
          if (user.clio_access_token) {
            setSignedInWithClio(true);
          } else setSignedInWithClio(false);
          if (user.lawcus_access_token) {
            setSignedInWithLawcus(true);
          } else setSignedInWithLawcus(false);

          if (user.use_zoom) {
            localStorage.setItem('useZoom', 'true');
          } else {
            localStorage.setItem('useZoom', 'false');
          }
          if (user.use_google) {
            localStorage.setItem('useGoogle', 'true');
          } else {
            localStorage.setItem('useGoogle', 'false');
          }

          if (user.zoom_access_token) {
            setSignedInWithZoom(true);
          } else {
            setSignedInWithZoom(false);
            if (localStorage.getItem('useZoom') === 'true') {
              // prompt the user to re-authenticate
              if (window.confirm("Please re-authenticate with Zoom to continue using this integration.")) {
                signInWithZoom( { session } );
              }
            }
          }

          if (user.google_access_token) {
            setSignedInWithGoogle(true);
          } else {
            setSignedInWithGoogle(false);
            if (localStorage.getItem('useGoogle') === 'true') {
              // prompt the user to re-authenticate
              if (window.confirm("Please re-authenticate with Google to continue using this integration.")) {
                signInWithGoogle( { session } );
              }
            }
          }
          
          if (error) {
            console.error('Error fetching integration data:', error);
            return;
          }
        });
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      //devLog('onAuthStateChange', { session })
      setSession(session)
    })
  
  }, [])
  
    useEffect(() => {
      let inactivityTimeout;

      // handle resetting the total active time at the start of each day:
      const currentDate = moment.tz('America/Los_Angeles').format('YYYY-MM-DD');
      const lastUpdatedDate = localStorage.getItem('lastUpdatedDate');
      
      // Check if the current date is different from the last updated date
      if (lastUpdatedDate && currentDate !== lastUpdatedDate) {
        // Reset total active time to 0 and update the last updated date
        localStorage.setItem('totalActiveTime', '0');
        setTotalActiveTime(0); // Also reset in state

        // calculate time saved so far!
        supabase.auth.getSession().then(({ data: { session } }) => {
            updateTimeSaved({ session, supabase, currentDate });
        });
      }
      localStorage.setItem('lastUpdatedDate', currentDate);
  
      // Load total active time from localStorage or set to 0 if not found
      const savedTotalActiveTime = localStorage.getItem('totalActiveTime');
      if (savedTotalActiveTime) {
        setTotalActiveTime(parseInt(savedTotalActiveTime, 10));
      } else {
        setTotalActiveTime(0);
      }
      
      const updateSupabase = async () => {
        const lastUpdated = localStorage.getItem('lastUpdatedTimeSpent');
        
        // only update if it's been more than 10 minutes since the last update
        if (!lastUpdated || Date.now() - new Date(lastUpdated) > 10 * 60 * 1000) {
          //console.log('Updating total active time in supabase');
        } else {
          return;
        }

        try {
          const time_spent = parseInt(localStorage.getItem('totalActiveTime'), 10);

          const date = moment.tz('America/Los_Angeles').startOf('day').toDate();
          const formattedDate = moment(date).format('YYYY-MM-DD');
          //console.log("New total:", time_spent);
          const session = (await supabase.auth.getSession()).data.session;
          if (!session) {
            return;
          }

          const { data, error } = await supabase
            .from('legalpulse_time_spent')
            .upsert({
              profile_id: session.user.id,
              date: formattedDate,
              minutes: parseInt(time_spent / (1000 * 60)),
            });
          if (error) {
            console.error(error);
          }

          // update the last updated time in local storage
          localStorage.setItem('lastUpdatedTimeSpent', new Date().toISOString());
        } catch (error) {
          console.error('Error updating total active time in supabase:', error);
        }
      };
    
      // Function to handle user activity
      const handleUserActivity = () => {
        clearTimeout(inactivityTimeout); // Clear previous timeout
        if (!isActiveRef.current) {
          startTimeRef.current = Date.now(); // Reset start time
          isActiveRef.current = true;
        }
        updateSupabase();
        
        // Set a timeout for inactivity (e.g., 2 minutes of no activity)
        inactivityTimeout = setTimeout(() => {
          if (isActiveRef.current) {
            const endTime = Date.now();
            const activeTime = endTime - startTimeRef.current;
            setTotalActiveTime((prevTotal) => {
              const newTotal = prevTotal + activeTime;
              // Save the updated total active time to localStorage
              localStorage.setItem('totalActiveTime', newTotal.toString());
  
              return newTotal;
            });
            isActiveRef.current = false; // Mark as inactive after period of no activity
          }
        }, 120 * 1000); // 2 minutes of inactivity considered as user being inactive
  
      };
    
      // Event listeners for user activity
      const activityEvents = ['mousemove', 'click', 'keypress'];
      activityEvents.forEach((event) => 
        document.addEventListener(event, handleUserActivity)
      );
    
      // Visibility change handler
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          clearTimeout(inactivityTimeout); // Clear inactivity timeout
          if (isActiveRef.current) {
            const endTime = Date.now();
            const activeTime = endTime - startTimeRef.current;
            setTotalActiveTime((prevTotal) => {
              const newTotal = prevTotal + activeTime;
              // Save the updated total active time to localStorage
              localStorage.setItem('totalActiveTime', newTotal.toString());
              return newTotal;
            });
            isActiveRef.current = false;
          }
        }
  
        updateSupabase();
      };
    
      document.addEventListener('visibilitychange', handleVisibilityChange);
    
      // Cleanup function to remove event listeners and clear timeout
      return () => {
        activityEvents.forEach((event) => 
          document.removeEventListener(event, handleUserActivity)
        );
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        clearTimeout(inactivityTimeout);
      };
    }, []);

  
  return (
    <div className="app-container">
    <div>
    {/*<div className="top-gradient-line"></div>*/}
    <Router>
        { session && (billingSoftware === 'none' || signedInWithClio || signedInWithLawcus) && (
          <Navbar
            billingSoftware={billingSoftware}
            signedInWithZoom={signedInWithZoom}
            setSignedInWithZoom={setSignedInWithZoom}
            signedInWithGoogle={signedInWithGoogle}
            setup={setupsFinished !== 2}
          />
        )}
        <div className="container" style={{ padding: '0px 0 80px 0' }}>
          {/* A <Routes> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Routes>
            {(session && (setupsFinished !== 2 || billingSoftware === 'none' || signedInWithClio || signedInWithLawcus)) ? (
              <>
                <Route
                  path="/"
                  element={
                    needAuthorization ? (
                      <NeedAuthorization />
                    ) : setupsFinished === 0 ? (
                      <Setup1 billingSoftware={billingSoftware} setBillingSoftware={setBillingSoftware} />
                    ) : setupsFinished === 1 ? (
                      <Setup2
                        session={session}
                        billingSoftware={billingSoftware}
                        signedInWithClio={signedInWithClio}
                        setSignedInWithClio={setSignedInWithClio}
                        signedInWithLawcus={signedInWithLawcus}
                        setSignedInWithLawcus={setSignedInWithLawcus}
                      />
                    ) : (
                      <Account key={session.user.id} session={session} billingSoftware={billingSoftware} />
                    )
                  }
                />
                <Route path="/settings" element={<Settings billingSoftware={billingSoftware} />} />
                {/*<Route path="/billing" element={<Billing />} />
                <Route path="/new_bill" element={<NewBill />} />*/}
                {/*<Route path="/view_bills" element={<ViewBills />} /> I made this a component in billing*/}
                <Route path="/billing" element={<Billing_WB />} />
                <Route path="/new_bill" element={<NewBill_WB />} />
                <Route path="/edit_bills" element={<EditBills_WB />} />
              </>
            ) : (
              <Route
                path="*"
                element={
                  <Auth
                    session={session}
                    billingSoftware={billingSoftware}
                    setSignedInWithClio={setSignedInWithClio}
                    setSignedInWithLawcus={setSignedInWithLawcus}
                  />
                }
              />
            )}
            {/* These routes should be accessible regardless of session */}
            <Route path="/terms_of_service" element={<DisplayDocument file="Terms of Service" />} />
            <Route path="/privacy_policy" element={<DisplayDocument file="Privacy Policy" />} />
          </Routes>
        </div>
      </Router>
    </div>
    <Footer />
    </div>
  )
}
