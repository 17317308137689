import React, { useState } from 'react';
import { supabase } from '../../supabaseClient'
import { useEffect } from 'react';
import Navbar from '../../components/Navbar';
import { v4 as uuidv4 } from 'uuid';
import '../../styles/style.css';
import { useLocation } from 'react-router-dom';
import generateBills from './generateBills';
import fetchClientsMatters from '../../functions/fetchClientsMatters';
import EditDate from '../../components/EditDate';
import moment from 'moment-timezone';
import shiftDateByTimezone from '../../functions/shiftDateByTimezone';
import TimeInputField from './../../components/timeInputField.js';
import { handleDelete } from '../../functions/handleDelete.js';
import devLog from '../../functions/devLog.js';

const styleVars = getComputedStyle(document.documentElement);
const disabledBorderColor = styleVars.getPropertyValue('--custom-color-disabled');
const disabledBackgroundColor = styleVars.getPropertyValue('--custom-color-secondary');

export default function EditBills() {
    const location = useLocation(); // Get the current location object
    const [loading, setLoading] = useState(true);
    
    let { session, selected_entries, selectedClients, selectedMatters, invoice_orig, startDate, endDate, timezone, clients, matters, combineClients, combineMatters } = location.state;
    //console.log("EditBills")
    //console.log(startDate)
    //console.log(endDate)

    const [invoices, setInvoices] = useState(invoice_orig); //maps client_num to matter_num invoice. if key is "*", it's all clients/matters

    const client_id_to_name = {};
    clients.map((client) => {
        client_id_to_name[client.uuid] = client.name;
    });

    useEffect(() => {

        setLoading(false);
    }, []);

    if (loading || !selected_entries) {
        return <div>Loading...</div>;
    }
    if (selected_entries.length === 0) {
        return <div>No entries to bill</div>;
    }
    
    clients.map((client) => {
        client.matters.map((matter_id) => {
            if (!matters[matter_id]) {
                devLog('Undefined matter_id:', matter_id);
                devLog(client);
            }
        });
    });
    const handleFinalize = () => {
        generateBills({invoices, selected_entries, selectedClients, selectedMatters, startDate, endDate, timezone, combineClients, combineMatters, clients, matters })
    }

    const handleDescriptionChange = (clientUuid, matterUuid, index) => (e) => {
        let newInvoices = {...invoices};
        if (clientUuid === "*" || matterUuid === "*") {
            newInvoices[clientUuid][index].description = e.target.value;
        } else {
            newInvoices[clientUuid][matterUuid][index].description = e.target.value;
        }
        //console.log(newInvoices);
        setInvoices(newInvoices);
    }
    const handleTimeChange = (clientUuid, matterUuid, index) => (e) => {
        let newInvoices = {...invoices};
        if (clientUuid === "*" || matterUuid === "*") {
            newInvoices[clientUuid][index].time_taken = e.target.value;
        } else {
            newInvoices[clientUuid][matterUuid][index].time_taken = e.target.value;
        }
        //console.log(newInvoices);
        setInvoices(newInvoices);
    }
    const _handleDateChange = (value, clientUuid, matterUuid, index) => {
        //console.log("Date change")
        //console.log(new Date(value));
        if (new Date(value) - startDate < 0) {
            const confirm = window.confirm("This will set the date to before the start date. Are you sure?");
            if (!confirm) return;
        } if (new Date(value) - endDate > 0) {
            const confirm = window.confirm("This will set the date to after the end date. Are you sure?");
            if (!confirm) return;
        }
        const date = shiftDateByTimezone(new Date(value), timezone);
        const formattedDate = moment(date).format('YYYY-MM-DD');
        //console.log(formattedDate);
        //console.log(new Date(formattedDate));
        
        let newInvoices = {...invoices};
        if (clientUuid === "*" || matterUuid === "*") {
            newInvoices[clientUuid][index].date = formattedDate;
        } else {
            newInvoices[clientUuid][matterUuid][index].date = formattedDate;
        }
        //console.log(newInvoices);
        setInvoices(newInvoices);
    }
    const handleDelete = (clientUuid, matterUuid, index) => {
        let newInvoices = {...invoices};
        if (clientUuid === "*" || matterUuid === "*") {
            newInvoices[clientUuid].splice(index, 1);
        } else {
            newInvoices[clientUuid][matterUuid].splice(index, 1);
        }
        setInvoices(newInvoices);
    }

    function renderEntries(clientUuid, matterUuid, entries) {
        if (entries.length === 0) {
            return <div>No entries to bill.</div>;
        }
        return (
          <table className="invoice-table">
            <thead>
              <tr>
                <th className="client-header">Client</th>
                <th className="matter-header">Matter</th>
                <th className="timespent-header">Date</th>
                <th className="description-header">Entry</th>
                <th className="timespent-header">Time (hrs)</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => ( <tr key={index}>
                    <td className="rounded-td"><span>{entry.client}</span></td>
                    <td className="rounded-td"><span>{entry.matter}</span></td>
                    <td><EditDate date={new Date(entry.date)} handleDateChange={(e) => _handleDateChange(e.target.value, clientUuid, matterUuid, index)} /></td>
                    <td><input type='text' onChange={handleDescriptionChange(clientUuid, matterUuid, index)} value={entry.description}></input></td>
                    <td><input type='number' onChange={handleTimeChange(clientUuid, matterUuid, index)} value={entry.time_taken}></input></td>
                    <td className="no-border">
                    <button className="button secondary" onClick={() => {
                        if (window.confirm('Are you sure you want to delete this entry?')) {
                            handleDelete(clientUuid, matterUuid, index);
                        }
                    }}>X</button>
                    </td>
              </tr>))}
            </tbody>
          </table>
        );
    }

    return (
        <div className="form-widget">
            {/*<Navbar />*/}
            <h1>Finalize Invoice(s)</h1>
            <button className="button white" onClick={() => window.location.href = '/new_bill'}>Go back</button>

            <h3>Review the invoices that will be generated and make any edits you would like.</h3>

            {combineClients ? (
                renderEntries("*", "*", invoices['*'])
            ) : combineMatters ? (
                <div>
                    {Object.keys(invoices).map(clientUuid => (
                        <div key={clientUuid}>
                            <h3 style={{textDecoration: 'underline'}} >{client_id_to_name[clientUuid]}</h3> {/* client name */}
                            {renderEntries(clientUuid, "*", invoices[clientUuid])}
                        </div>
                    ))}
                </div>
            ) : (
                <div>
                    {Object.keys(invoices).map(clientUuid => (
                        <div key={clientUuid}>
                            <h3 style={{textDecoration: 'underline'}} >{client_id_to_name[clientUuid]}</h3> {/* client name */}
                            {Object.keys(invoices[clientUuid]).map(matterUuid => (
                                <div key={matterUuid}>
                                    <h4>{matters[matterUuid].description}</h4> {/* matter name */}
                                    {renderEntries(clientUuid, matterUuid, invoices[clientUuid][matterUuid])}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
            <button className="button white" onClick={handleFinalize}>Generate Invoices</button>
        </div>
    );
}