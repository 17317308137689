import React, { useState, useEffect, useRef } from 'react';

const EditDate = ({ date, handleDateChange}) => {
  // Format the date as YYYY-MM-DD for the input
  const formatDateForInput = (date) => {
    if (!(date instanceof Date) || isNaN(date)) return '';
    return date.toISOString().split('T')[0];
  };

  return (
    <div>
      <input
        type="date"
        value={formatDateForInput(date)}
        onChange={handleDateChange}
        style={{ border: '2px solid black' }}
      />
    </div>
  );
};

export default EditDate;