import devLog from './devLog';
export async function handleDelete(supabase, entriesToDelete, entries=null, setEntries=null, index=null) {
    devLog('handleDelete called');
    devLog(entriesToDelete);
    // delete the block entries  
    for (const entryToDelete of entriesToDelete) {
      const { error } = await supabase
        .from('block_entries')
        .delete()
        .eq('uuid', entryToDelete.uuid);

      if (error) {
        console.error('Error deleting entry:', error);
        continue;
      }

      // delete the corresponding entries from the entries table
      
      for (const uuid of entryToDelete.entries) {
        // First fetch the entry
        const { data: deletedEntry, error: fetchError } = await supabase
          .from('entries')
          .select('*')
          .eq('uuid', uuid)
          .single();
        if (fetchError) {
          console.error('Error fetching entry:', fetchError);
          continue;
        }
        devLog("Fetched Entry")
        devLog(deletedEntry)
        devLog(deletedEntry.type)
        // delete the entry
        const { error2 } = await supabase
          .from('entries')
          .delete()
          .eq('uuid', uuid);
        if (error2) {
          console.error('Error deleting entry:', error2);
          continue;
        }
  
        // delete from individual tables
        if (deletedEntry.type !== 'unk') {
            const table = deletedEntry.type + 's';
            devLog("Table:", table)
            const { error3 } = await supabase
              .from(table)
              .delete()
              .eq('uuid', uuid);
            if (error3) {
              console.error('Error deleting entry:', error3);
              continue;
            }
        }
        /*
        if (deletedEntry.type === 'calendar') {
          devLog("Deleting calendar entry")
          const { error3 } = await supabase
            .from('calendars')
            .delete()
            .eq('uuid', uuid);
          
          if (error3) {
            console.error('Error deleting entry:', error3);
            return;
          }
        } else if (deletedEntry.type === "email") {
          devLog("Deleting email entry")
          const { error3 } = await supabase
            .from('emails')
            .delete()
            .eq('uuid', uuid);
          
          if (error3) {
            console.error('Error deleting entry:', error3);
            return;
          }
        } else if (deletedEntry.type === "doc") {
          devLog("Deleting doc entry")
          devLog("uuid:", uuid)
          const { error3 } = await supabase
            .from('docs')
            .delete()
            .eq('uuid', uuid);
          
          if (error3) {
            console.error('Error deleting entry:', error3);
            return;
          }
        }*/
    
        /*if (entries && setEntries && index !== null) {
          const newEntries = [...entries];
          newEntries.splice(index, 1);
          setEntries(newEntries);
        }*/
      }
    }
}