import '../styles/style.css';
import { supabase } from '../supabaseClient'
import { useEffect } from 'react';
import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../styles/Settings.css';
import Modal from 'react-modal';
import { CustomSelect } from './customSelect.js';
import '../styles/style.css';
import devLog from '../functions/devLog.js';
import UploadClients from './UploadClientsCopilot.js';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

const maxx = Math.pow(2, 53) - 1
function generateBigInt() {
  return Math.floor(Math.random() * maxx) + 1;
}

function AddClients({ defaultBlock, setDefaultBlock }) {
    const [clients, setClients] = useState([]);
    const [matters, setMatters] = useState([]);
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editingClientIndex, setEditingClientIndex] = useState(null);
    const [editingMatterIndex, setEditingMatterIndex] = useState(null);
    const [clientModalIsOpen, setClientModalIsOpen] = useState(false);
    const [isCompany, setIsCompany] = useState(true);
    const [clientFirstName, setClientFirstName] = useState('');
    const [clientLastName, setClientLastName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [block, setBlock] = useState('1');
    const [code, setCode] = useState('None');
    const [editingClient, setEditingClient] = useState({});
    const [isCollapsed, setIsCollapsed] = useState(false);

    // Matter info
    const [matterModalIsOpen, setMatterModalIsOpen] = useState(false);
    const [editingMatterID, setEditingMatterID] = useState(null);
    const [description, setDescription] = useState('');
    const [responsibleAttorney, setResponsibleAttorney] = useState('');
    const [matterStatus, setMatterStatus] = useState('Open');
    const [statuteOfLimitations, setStatuteOfLimitations] = useState('');
    const [firmUsers, setFirmUsers] = useState([]);
    const [additionalContacts, setAdditionalContacts] = useState([]);
    const [billing, setBilling] = useState('Hourly');
    const [useCustomRate, setUseCustomRate] = useState(false);
    const [customRate, setCustomRate] = useState(0);
    const [flatFee, setFlatFee] = useState(0);
    const [contingency, setContingency] = useState(0);

    const isMounted = useRef(false);
    
    // Description
    // Responsible Attorney
    // Matter Status: Open, pending, closed
    // Statute of Limitations date
    // Firm users with access
    // Additional contacts: name, email, relationship
    // Billing: Hourly, flat fee, contingency
    // If hourly, custom rate
    // If flat fee, amount
    // If contingency, percentage

    // When billing, can select one or multiple clients to bill
    // Can select one or multiple matters to bill
    // * select all clients
    // * Client 1
    //    * Matter 1.1
    //    * Matter 1.2
    // * Client 2
    //    * Matter 2.1

    // Bills contain:
    // Issue date
    // Due date
    // Detail level: All details, summary, aggregate
    // Include time entries any expenses or one

    const block_value_to_display = {
        '1': '1 entry per day',
        '2': '1 entry for consecutive tasks',
        '3': 'Entry for each task'
    }
    const isCompany_value_to_display = {
      true: 'Company',
      false: 'Person'
    }

    const fetch = async () => {
        const session = (await supabase.auth.getSession()).data.session;
        //console.log("Session")
        //console.log(session)
        if (isMounted.current) setSession(session);
    };
    
    async function fetchClients() {
        const session = (await supabase.auth.getSession()).data.session;
        const { data: fetchedClients, error } = await supabase
          .from('clients')
          .select('*')
          .eq('profile_id', session.user.id);

        if (error) {
          console.error('Error fetching clients:', error);
          return;
        }
    
        const sortedClients = fetchedClients.sort((a, b) => a.name.localeCompare(b.name));
        if (isMounted.current) setClients(sortedClients);
        console.log("Fetched clients")
        console.log(sortedClients)
    };

    async function fetchMatters() {
        const session = (await supabase.auth.getSession()).data.session;
        const { data: fetchedMatters, error } = await supabase
          .from('matters')
          .select('*')
          .eq('profile_id', session.user.id);
        if (error) {
          console.error('Error fetching matters:', error);
          return;
        }

        // create a dictionary that maps each matter's uuid to the matter
        const mattersDict = {};
        fetchedMatters.forEach(matter => {
          mattersDict[matter.uuid] = matter;
        });
        if (isMounted.current) setMatters(mattersDict);
        console.log("Fetched matters")
        console.log(mattersDict)
    };
    
    useEffect(() => {
        isMounted.current = true;
        fetch();
        fetchClients();

        if (isMounted.current) setLoading(false);
        devLog("Clients")
        devLog(clients)
        return () => {
          isMounted.current = false;
      };
    }, [clientModalIsOpen]);

    useEffect(() => {
      fetchMatters();

      if (isMounted.current) setLoading(false);
      return () => {
        isMounted.current = false;
      };
    }, [clientModalIsOpen, matterModalIsOpen]);

    const handleAddClient = async () => {
        devLog("Add Client")
        const newClient = {
          is_company: (isCompany === true || isCompany === 'true') ? true : false,
          first_name: clientFirstName,
          last_name: clientLastName,
          name: `${clientFirstName} ${clientLastName}`,
          email: clientEmail,
          phone: clientPhone,
          uuid: generateBigInt(),
          matters: [],
          profile_id: session.user.id,
          block: defaultBlock,
          code: code,
        };
        
        setClientModalIsOpen(true);
        setEditingClient(newClient);
    };

    const handleEditClient = async (index) => {
      devLog("Edit Client")
      setClientModalIsOpen(true);
      setEditingClient(clients[index]);
      devLog(clients[index])
      if (clients[index].is_company === true || clients[index].is_company === 'true') {
        setIsCompany(true);
        setClientFirstName(clients[index].name ? clients[index].name : "");
      } else {
        setIsCompany(false);
        setClientFirstName(clients[index].first_name ? clients[index].first_name : "");
        setClientLastName(clients[index].last_name ? clients[index].last_name : "");
      }

      setClientEmail(clients[index].email ? clients[index].email : "");
      setClientPhone(clients[index].phone ? clients[index].phone : "");
      setBlock(clients[index].block ? clients[index].block : defaultBlock);
      setCode(clients[index].code ? clients[index].code : "");
      devLog("Editing client")
      devLog(editingClient)
    }

    const saveClient = async (event) => {

      event.preventDefault();

      if (!clientEmail) {
        alert('Please enter an email address.');
        return;
      }
      
      let newClient;
      if (isCompany === true || isCompany === 'true') {
        if (!clientFirstName) {
          alert('Please enter a company name');
          return;
        }
        newClient = {
          is_company: true,
          name: clientFirstName,
          email: clientEmail,
          phone: clientPhone,
          profile_id: session.user.id,
          block: block,
          code: code,
          uuid: editingClient.uuid,
        };
      } else {
        if (!clientFirstName || !clientLastName) {
          alert('Please enter a full name');
          return;
        }
        newClient = {
          is_company: false,
          first_name: clientFirstName,
          last_name: clientLastName,
          name: `${clientFirstName} ${clientLastName}`,
          email: clientEmail,
          phone: clientPhone,
          profile_id: session.user.id,
          block: block,
          code: code,
          uuid: editingClient.uuid,
        };
      }
      // replace the client with this uuid in the clients array
      let newClients = [...clients];
      let index = newClients.findIndex(client => client.uuid === editingClient.uuid);
      if (index === -1) {
        newClients = [...clients, editingClient];
        index = newClients.findIndex(client => client.uuid === editingClient.uuid);
      }

      for (const key in newClient) {
        newClients[index][key] = newClient[key];
        setClients(newClients);
      }
      
      devLog("Added client")
      devLog(newClient)
      devLog(newClient.uuid)
      
      try {
        const { error } = await supabase
          .from('clients')
          .upsert(newClient)
          .eq('uuid', editingClient.uuid)
          .eq('profile_id', session.user.id);
        if (error) {
          devLog(error)
          alert(error)
        }
      } catch (error) {
        console.error(error);
        alert("error")
      }

      setClientModalIsOpen(false); // Close the modal after adding the client
      setClientFirstName('');
      setClientLastName('');
      setClientEmail('');
      setClientPhone('');
      setIsCompany(true);
      setBlock(defaultBlock);
      setCode('None');
    };
    
    function handleAddMatter(clientIndex) {
      devLog("Before Add Matter")
      devLog(clients)

      setMatterModalIsOpen(true);
      const newMatter = {
        uuid: generateBigInt(),
        profile_id: session.user.id,
        client_uuid: clients[clientIndex].uuid,
        description: '',
        responsible_attorney: '',
        matter_status: 'Open',
        firm_users: [],
        additional_contacts: [],
        billing: 'Hourly',
        custom_rate: 0,
        flat_fee: 0,
        contingency: 0,
      };
      setEditingClient(clients[clientIndex]);
      setEditingClientIndex(clientIndex);
      setEditingMatterID(newMatter.uuid);
      setEditingMatterIndex(clients[clientIndex].matters.length);

      devLog("Before Add Matter")
      devLog(clients)

      const newClients = [...clients];
      newClients[clientIndex].matters.push(newMatter.uuid);
      setClients(newClients);

      devLog("After Add Matter")
      devLog(clients)
    }

    const handleEditMatter = (clientIndex, matterIndex) => {
      setMatterModalIsOpen(true);

      const matter = matters[clients[clientIndex].matters[matterIndex]];
      devLog("Matter")
      devLog(matter)
      devLog("Clients")
      devLog(clients)

      setEditingMatterID(clients[clientIndex].matters[matterIndex]);
      setEditingMatterIndex(matterIndex);
      setEditingClient(clients[clientIndex]);
      setEditingClientIndex(clientIndex);

      setDescription(matter.description);
      // if matter.responsible_attoryney is not null, otherwise make it ""
      setResponsibleAttorney(matter.responsible_attorney ? matter.responsible_attorney : "");
      setMatterStatus(matter.status);
      setStatuteOfLimitations(matter.statute_of_limitations);
      setFirmUsers(matter.firmUsers);
      setAdditionalContacts(matter.additional_contacts);
      setBilling(matter.billing);
      setUseCustomRate(matter.use_custom_rate);
      setCustomRate(matter.custom_rate);
      setFlatFee(matter.flat_fee);
      setContingency(matter.contingency);
      devLog("Clients")
      devLog(clients)
    };

    const saveMatter = async (event) => {
      event.preventDefault();

      devLog("Clients")
      devLog(clients)
      const updatedMatter = {
        uuid: editingMatterID,
        profile_id: session.user.id,
        client_uuid: clients[editingClientIndex].uuid,
        description,
        responsible_attorney: responsibleAttorney,
        //matter_status: matterStatus,
        //statute_of_limitations: statuteOfLimitations,
        //firm_users: firmUsers,
        //additional_contacts: additionalContacts,
        //billing,
        //use_custom_rate: useCustomRate,
        //custom_rate: customRate,
        //flat_fee: flatFee,
        //contingency,
      };
      devLog("Updated Matter")
      devLog(updatedMatter)
      devLog(editingMatterID)
      
      // save this matter to the supabase matters database
      // update the client's matters array with the new matter
      const { error } = await supabase
      .from('matters')
      .upsert(updatedMatter)
      .eq('uuid', editingMatterID);
      if (error) {
        console.error('Error updating matter:', error);
        return;
      }
      const matterUUIDs = clients[editingClientIndex].matters;
      // find the client in the clients array and update the matters array
      const newClients = [...clients];
      newClients[editingClientIndex].matters = matterUUIDs;
      setClients(newClients);

      const { error2 } = await supabase
        .from('clients')
        .update({ matters: matterUUIDs })
        .eq('uuid', clients[editingClientIndex].uuid);
      if (error2) {
        console.error('Error updating client:', error2);
        return;
      }
      
      setMatterModalIsOpen(false);

      setDescription("");
      setResponsibleAttorney("");
      setMatterStatus("Open");
      setStatuteOfLimitations("");
      setFirmUsers([]);
      setAdditionalContacts([]);
      setBilling("Hourly");
      setUseCustomRate(false);
      setCustomRate(0);
      setFlatFee(0);
      setContingency(0);
    };
    
    const deleteClient = async (index) => {
      const client = clients[index];
      const { error } = await supabase
        .from('clients')
        .delete()
        .eq('uuid', client.uuid);
      if (error) {
        console.error('Error deleting client:', error);
      } else {
        // Remove the client from the local state
        const newClients = [...clients];
        newClients.splice(index, 1);
        setClients(newClients);
      }

      // delete all matters associated with this client
      const { error2 } = await supabase
        .from('matters')
        .delete()
        .in('uuid', client.matters);
      if (error2) {
        console.error('Error deleting client matters:', error2);
      }
    };

    const handleDefaultBlockChange = async (value) => {
      setDefaultBlock(value);
      try {
        const { data, error } = await supabase
          .from('profiles')
          .update({ default_block: value })
          .eq('profile_id', session.user.id);
        if (error) {
          console.error('Error updating default block:', error);
        } else {
          devLog('Default block updated successfully');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      }
    };

    const applyDefaultBlockToAllClients = async () => {
      const updatedClients = clients.map(client => ({
        ...client,
        block: defaultBlock
      }));
      setClients(updatedClients);

      try {
        const { error } = await supabase
          .from('clients')
          .upsert(updatedClients);
        if (error) {
          console.error('Error updating clients:', error);
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      }
    };

    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };

    return (
        <div>
            <div className="flexbox" style={{gap: '20px'}}>
              <h2>Clients</h2>
              <button onClick={toggleCollapse} className="button white square">
                    {isCollapsed ? '\u25B6' : '\u25BC'}
              </button>
            </div>

            {/*<h3>Default Client Settings</h3>
            <p>These settings will be applied whenever a new client is created. You may modify individual client settings.</p>
            */}
            {/*{!isCollapsed && (
                <div className={`collapsible-content ${isCollapsed ? 'collapsed' : 'expanded'}`}>*/}
            <div className={`collapsible-content ${isCollapsed ? 'collapsed' : 'expanded'}`}>

            <h3>Upload files with your clients and matters or add them manually.</h3>
            <UploadClients
              defaultBlock={defaultBlock}
              clients={clients}
              setClients={setClients}
              fetchClients={fetchClients}
              fetchMatters={fetchMatters}
            />
            <div className="table-container">
                <table className="clients-table">
                <thead>
                    <tr>
                      <th></th>
                      <th>Client</th>
                      <th>Matters</th>
                      <th></th>
                    </tr>
                </thead>
                <tbody>
                {clients.map((client, index) => (
                  <tr key={client.uuid}>
                  {/*<div key={client.uuid} style={{ display: 'flex', alignItems: 'center' }}>*/}
                    {/*<div className="client-box">*/}
                    <td>
                        <button className = "delete-button" onClick={() => deleteClient(index)}>
                        <i className="fas fa-times"></i>
                      </button>
                    </td>
                        
                      <td><div className="client-name-box">
                        {client.name}
                          <button className="circular-button" onClick={() => handleEditClient(index)}>
                          <i className="fas fa-pen"></i>
                        </button>
                      </div></td>
                      {client.matters.length > 0 && client.matters.map((matter_uuid, ind) => (
                        matters[matter_uuid] && matters[matter_uuid].description && (
                            <td key={ind}><button className="matter-box" onClick={() => handleEditMatter(index, ind)}>
                            {matters[matter_uuid].description.substring(0, 20)}
                            </button></td>
                        )
                      ))}
                      <td style={{width: '100'}}>
                        <div className="button-container">
                          <div className="button white" onClick={() => handleAddMatter(index)}>+</div>
                        </div>
                      </td>
                    {/*</div>*/}
                  {/*</div>*/}
                  </tr>
                ))}
                </tbody>
                </table>
                </div>
              <button className="button white" onClick={() => handleAddClient()}>+</button>
            </div>
            

            <div className="flexbox-bottom">
              <label style={{margin: '0px'}}>
                Default Block Billing:
                <CustomSelect 
                  myVar={defaultBlock} 
                  setVar={handleDefaultBlockChange} 
                  value_to_display={block_value_to_display} 
                  defaultValue={"1"} 
                  defaultDisplay={block_value_to_display["1"]} 
                  width={'300px'}
                />
              </label>
              <button className="button white" onClick={applyDefaultBlockToAllClients}>Apply to all clients</button>
            </div>

            <Modal
              isOpen={matterModalIsOpen && editingMatterIndex !== null}
              onRequestClose={() => {
                setMatterModalIsOpen(false);
                setEditingMatterID(null);
                setEditingClientIndex(null);
                setEditingMatterIndex(null);
              }}
              contentLabel="Edit Matter"
            >
              <h2>Edit Matter</h2>
              <form onSubmit={(event) => saveMatter(event)}>
                <label>
                  Name:
                  <input
                    type="text"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </label>
                
                <label>
                  Responsible Attorney Name:
                  <input
                    type="text"
                    value={responsibleAttorney}
                    onChange={(event) => setResponsibleAttorney(event.target.value)}
                  />
                </label>
                {/*
                <label>
                  Status:
                  <input
                    type="text"
                    value={matterStatus}
                    onChange={(event) => setMatterStatus(event.target.value)}
                  />
                </label>
                <label>
                  Statute of Limitations:
                  <input
                    type="date"
                    value={statuteOfLimitations}
                    onChange={(event) => setStatuteOfLimitations(event.target.value)}
                  />
                </label>*/}
                  <button type="submit">Save Matter</button>
                  <button type="button" onClick={() => {
                    setMatterModalIsOpen(false);
                    setEditingMatterID(null);
                    setEditingClientIndex(null);
                    setEditingMatterIndex(null);
                  }}>
                    Cancel
                  </button>
              </form>
            </Modal>
            <Modal
              isOpen={clientModalIsOpen && editingClient !== null}
              onRequestClose={() => {
                setClientModalIsOpen(false);
                setEditingClient(null);
                }}
              contentLabel="Edit Client"
            >
              <h2>Edit Client</h2>
              <form onSubmit={(event) => saveClient(event)}>
              
              <label>
                Client Type:
                <CustomSelect myVar={isCompany} setVar={(value) => setIsCompany(value)} value_to_display ={isCompany_value_to_display} defaultValue={true} defaultDisplay={'Company'} width={'100px'}/>
              </label>
              <br></br>

              {(isCompany==false || isCompany=='false') ? (
                <>
                  <label>
                    First Name:
                    <input type="text" value={clientFirstName} onChange={e => setClientFirstName(e.target.value)}/>
                  </label>
                  <label>
                    Last Name:
                    <input type="text" value={clientLastName} onChange={e => setClientLastName(e.target.value)}/>
                  </label>
                </>
              ) : (
                <label>
                  Company Name:
                  <input type="text" value={clientFirstName} onChange={e => setClientFirstName(e.target.value)}/>
                </label>
              )}
              <label>
                Email:
                <input type="text" value={clientEmail} onChange={e => setClientEmail(e.target.value)}/>
              </label>
              <label>
                Phone (optional):
                <input type="text" value={clientPhone} onChange={e => setClientPhone(e.target.value)}/>
              </label>

              <br></br>
              <label>
                Block Billing:
                <CustomSelect myVar={block} setVar={(value) => setBlock(value)} value_to_display ={block_value_to_display} defaultValue={defaultBlock} defaultDisplay={block_value_to_display[defaultBlock]} width={'300px'}/>
              </label>
              {/*
              <label>
                Code:
                <div>
                  <select value={code} onChange={e => setCode(e.target.value)} className="settings-select">
                    <option value="None">None</option>
                    <option value="LEEDS">LEEDS</option>
                    <option value="Custom">Custom</option>
                  </select>
                </div>
              </label>*/}


              <br></br>
                <button type="submit">Save Client</button>
                <button type="button" onClick={() => {
                  setClientModalIsOpen(false);
                  setEditingClient(null);
                  setClientFirstName(''); setClientLastName('');
                  setClientEmail(''); setClientPhone('');
                  setIsCompany(true);
                  setBlock(defaultBlock);
                  setCode('None');}}>
                  Cancel
                </button>
            </form>

          </Modal>
        </div>
    );
}

export default AddClients;