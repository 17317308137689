import React from 'react';
import '../styles/style.css';
import { useEffect, useState, useRef } from 'react';

export function CustomSelect({ myVar, setVar, value_to_display, defaultValue, defaultDisplay, width=null }) {
    
    const [showOptions, setShowOptions] = useState(false);
    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };
    const selectRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Step 3: Remove event listener on cleanup
        };
    }, []); // Empty dependency array means this effect runs once on mount


    return  (
        <div className="select-container" ref={selectRef}>
        <div className="custom-select">
            <div className="select-selected select-style" onClick={toggleOptions} style={{width: width || 'auto'}}>
                {myVar ? value_to_display[myVar] : defaultDisplay}
            </div>
            {/* Dynamically set the display style based on showOptions */}
            <div className="select-items select-style" style={{ display: showOptions ? 'block' : 'none', width: width || 'auto' }}>
                {Object.keys(value_to_display).map((key) => (
                    <div key={key} onClick={() => { setVar(key); toggleOptions(); }}>
                        {value_to_display[key]}
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
};