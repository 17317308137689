/**
 * lib/supabaseClient.js
 * Helper to initialize the Supabase client.
 */

const { createClient } = require('@supabase/supabase-js');

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

//export const supabase = createClient(supabaseUrl, supabaseAnonKey)
const supabase = createClient(supabaseUrl, supabaseAnonKey);
module.exports = { supabase };