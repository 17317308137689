import devLog from './devLog';

async function updateTimeSaved({ session, supabase, currentDate }) {
    // local storage variables to update/display:
    // daily_time_saved: Average number of minutes saved per day
    // weekly_time_saved: Average number of minutes saved per week
    // last_week_time_saved: Number of minutes saved last week
    // if weekly_time_saved is null, this means we do not have enough data and daily_time_saved should be displayed.
    if (!session) {
        return;
    }
    
    try {
        const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('prev_weekly_hours_time_tracking')
            .eq('profile_id', session.user.id);
        if (profileError) {
            console.error('Error fetching last_updated:', profileError);
        }

        const currentDate = new Date();

        // Calculate the date a week ago
        const dateAWeekAgo = new Date(currentDate);
        dateAWeekAgo.setDate(currentDate.getDate() - 7);

        // Calculate yesterday's date
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        // Format the dates to yyyy-mm-dd
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const formattedDateAWeekAgo = formatDate(dateAWeekAgo);
        const formattedYesterday = formatDate(yesterday);

        devLog("Formatted Date A Week Ago: ", formattedDateAWeekAgo);
        devLog("Formatted Yesterday: ", formattedYesterday);
        
        const {data: timeData, error: timeError} = await supabase
            .from('legalpulse_time_spent')
            .select('*')
            .eq('profile_id', session.user.id)
            .gte('date', formattedDateAWeekAgo)
            .lte('date', formattedYesterday)
            .order('date', {ascending: true});
        if (timeError) {
            console.error('Error fetching last_updated:', timeError);
        }
        devLog("Time Data: ", timeData);
        if (timeData.length === 0) {
            return;
        }

        if (timeData[0].date !== formattedDateAWeekAgo) { // we don't have enough data to calculate weekly time saved
            // calculate average daily time saved
            let minutes_previously_spent = profileData[0].prev_weekly_hours_time_tracking * 60;
            minutes_previously_spent = parseInt(minutes_previously_spent * timeData.length / 5);
            devLog("Previously Spent: ", minutes_previously_spent);

            const minutes_spent = timeData.reduce((acc, curr) => acc + curr.minutes, 0);
            devLog("Minutes Spent: ", minutes_spent);
            const minutes_saved = minutes_previously_spent - minutes_spent;
            devLog("Last Week Minutes Saved: ", minutes_saved);
            devLog("Daily Time Saved: ", parseInt(minutes_saved / timeData.length));
            // update local storage
            localStorage.setItem('last_week_time_saved', minutes_saved);
            localStorage.setItem('daily_time_saved', parseInt(minutes_saved / timeData.length));
        } else {
            // calculate average weekly time saved
            const minutes_spent = timeData.reduce((acc, curr) => acc + curr.minutes, 0);
            const minutes_saved = profileData[0].prev_weekly_hours_time_tracking * 60 - minutes_spent;
            devLog("Minutes Saved: ", minutes_saved);
            // update local storage
            localStorage.setItem('last_week_time_saved', parseInt(minutes_saved));
            localStorage.setItem('daily_time_saved', parseInt(minutes_saved / timeData.length));
        }

    } catch (error) {
        console.error('Error fetching last_updated:', error);
    }
}

export default updateTimeSaved;