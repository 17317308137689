import React, { useState } from 'react';
import { supabase } from '../../supabaseClient'
import { useEffect } from 'react';
import Navbar from '../../components/Navbar';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import '../../styles/style.css';
import { useLocation } from 'react-router-dom';
import generateBills from './generateBills';
import fetchClientsMatters from '../../functions/fetchClientsMatters';
import EditDate from '../../components/EditDate';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import shiftDateByTimezone from '../../functions/shiftDateByTimezone';
import devLog from '../../functions/devLog';

const styleVars = getComputedStyle(document.documentElement);
const disabledBorderColor = styleVars.getPropertyValue('--custom-color-disabled');
const disabledBackgroundColor = styleVars.getPropertyValue('--custom-color-secondary');

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

export default function NewBill() {
    const [loading, setLoading] = useState(true);
    const [timezone, setTimezone] = useState('America/New_York');
    const [clients, setClients] = useState([]);
    const [matters, setMatters] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [editingClientIndex, setEditingClientIndex] = useState(null);
    const [editingMatterIndex, setEditingMatterIndex] = useState(null);

    const [startDate, setStartDate] = useState(moment.tz(timezone).startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment.tz(timezone).startOf('day').toDate());
    const [include, setInclude] = useState('1');
    const [combineClients, setCombineClients] = useState(false); // if this is true, combineMatters will be true
    const [combineMatters, setCombineMatters] = useState(false);
    const [includeUnbilledEntries, setIncludeUnbilledEntries] = useState(false);
    const [session, setSession] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    
    const fetch = async () => {
        const session = (await supabase.auth.getSession()).data.session;
        setSession(session);

        const { data, error } = await supabase
        .from('profiles')
        .select('timezone')
        .eq('profile_id', session.user.id);

        if (error) {
            alert('Failed to get timezone: ' + error.message);
        } else {
            setTimezone(data[0].timezone);
        }
        const value = await fetchClientsMatters( null, null, supabase, session )
        
        const clients1 = value.clients;
        const matters1 = value.matters;
        
        const clientsWithSettings = clients1.map(client => ({
            ...client,
            selected: false,
        }));
        
        setMatters(matters1);
        setClients(clientsWithSettings);
    };

    useEffect(() => {
        fetch();
        setLoading(false);
    }, []);

    const toggleSelectAll = () => {
        devLog("Toggle Select All")
        /*if (selectAll) {
            setSelectAll(false);
            return;
        }*/
        let newClients = [...clients];
        let newMatters = {...matters};
        newClients.forEach((client, clientIndex) => {
            if (client.matters.length !== 0) {
                newClients[clientIndex].selected = !selectAll;
                client.matters.forEach((matter_id) => {
                    newMatters[matter_id].selected = !selectAll;
                });}
        });
        setClients(newClients);
        setMatters(newMatters);
        setSelectAll(!selectAll);
    };
    const toggleClientSelection = (clientIndex) => {
        devLog("Toggle Client Selection")
        let newClients = [...clients];
        let newMatters = {...matters};
        newClients[clientIndex].selected = !newClients[clientIndex].selected;
        // for all matters in the client, set the selected state to the client's selected state
        newClients[clientIndex].matters.forEach((matter_uuid, matterIndex) => {
            newMatters[matter_uuid].selected = newClients[clientIndex].selected;
            if (!newMatters[matter_uuid].selected) {
                setSelectAll(false);
            }
        });
        setClients(newClients);
        setMatters(newMatters);
    };
    const toggleMatterSelection = (clientIndex, matterIndex, matter_id) => {
        devLog('Toggle Matter Selection')
        let newMatters = {...matters};
        newMatters[matter_id].selected = !newMatters[matter_id].selected;
        setMatters(newMatters);
        if (!newMatters[matter_id].selected) {
            setSelectAll(false);
        }

        // if no matters for this client are selected, unselect the client
        let client = clients[clientIndex];
        let noMattersSelected = client.matters.every(matter_id => !matters[matter_id].selected);
        devLog('No matters selected:', noMattersSelected)
        if (noMattersSelected) {
            let newClients = [...clients];
            newClients[clientIndex].selected = false;
            setClients(newClients);
        }
        else { // if any matter for this client is selected, select the client
            let newClients = [...clients];
            newClients[clientIndex].selected = true;
            setClients(newClients);
        }
    }

    const submitModal = async (event) => {
        event.preventDefault();

        // array of uuid's of all the selected clients
        const selectedClients = clients
            .filter(client => client.selected)
            .map(client => client.uuid);
        // array of uuid's of all the selected matters
        const selectedMatters = Object.values(matters)
            .filter(matter => matter.selected)
            .map(matter => matter.uuid);
        function sup_to_display(entries) {
            return entries.map(entry => ({
                ...entry,
                time_taken: entry.time_taken / 10.0
            }));}


        // start and end date are the beginning of the day in UTC;
        // we need to move them to the beginning of the day in the user's timezone
        // represented by the variable timezone in the format 'america/new york'

        let newStartDate = shiftDateByTimezone(startDate, timezone);
        let newEndDate = shiftDateByTimezone(endDate, timezone);
        // move endDate up one day so we get all the entries of the last day
        newEndDate = new Date(newEndDate);
        newEndDate.setDate(newEndDate.getDate() + 1);

        let { data: selected_entries, error } = await supabase
            .from('block_entries')
            .select('*')
            .eq('profile_id', session.user.id)
            .gte('time', newStartDate.toISOString())
            .lt('time', newEndDate.toISOString())
            .in('client_num', selectedClients)
            .in('matter_num', selectedMatters)
            .eq('billed', false);
        if (error) {
            devLog(error);
            throw new Error('Failed to fetch client data');
        }

        devLog("Selected entries")
        devLog(selected_entries)

        // check if any entries are missing a client or matter
        const missingClientEntries = selected_entries.filter(entry => (!entry.client_num || !entry.matter_num));
        if (missingClientEntries.length > 0) {
            let missing_dates = missingClientEntries.map(entry => entry.date);

            const confirm = window.confirm(`Client and/or matter missing in entries on the following dates: ${missing_dates.join(', ')}. These will be ignored. Continue?`);
            if (!confirm) {
                return;
            }
        }
        // remove entries that are missing a client or matter
        selected_entries = selected_entries.filter(entry => (entry.client_num && entry.matter_num));

        // sort selected_entries by client_num, matter_num, and then time
        selected_entries.sort((a, b) => {
            if (a.client_num !== b.client_num) {
                return a.client_num - b.client_num;
            }
            if (a.matter_num !== b.matter_num) {
                return a.matter_num - b.matter_num;
            }
            return a.time - b.time;
        });
        selected_entries = sup_to_display(selected_entries);

        let invoice_orig = {}
        if (combineClients) { // 1 invoice
            invoice_orig['*'] = [];
        } else if (combineMatters) { // 1 invoice for each client
            selectedClients.forEach(client_num => {
                invoice_orig[client_num] = [];
            });
        } else { // 1 invoice for each matter
            clients.forEach(client => {
                if (client.selected) {
                    invoice_orig[client.uuid] = {};
                    client.matters.forEach(matter_num => {
                        if (matters[matter_num].selected) {
                            invoice_orig[client.uuid][matter_num] = [];
                        }
                    });
                }
            });
        }
        selected_entries.forEach(entry => {
            let client_num = entry.client_num;
            let matter_num = entry.matter_num;
            if (combineClients) {
                invoice_orig['*'].push(entry);
            } else if (combineMatters) {
                invoice_orig[client_num].push(entry);
            } else {
                if (invoice_orig[client_num] && invoice_orig[client_num][matter_num]) {
                    invoice_orig[client_num][matter_num].push(entry);
                }
            }
        });
        devLog("Invoice orig")
        devLog(invoice_orig)

        navigate('/edit_bills', { state: { session, selected_entries: selected_entries, selectedClients, selectedMatters, invoice_orig, startDate, endDate, timezone, clients, matters, combineClients, combineMatters } });
    }

    if (loading) {
        return <div>Loading...</div>;
    }
    
    clients.map((client) => {
        client.matters.map((matter_id) => {
            if (!matters[matter_id]) {
                devLog('Undefined matter_id:', matter_id);
                devLog(client);
            }
        });
    });

    return (
        <div className="form-widget">
        {/*<Navbar />*/}
        <h1>New Invoice</h1>

        <div style={{ display: 'flex', paddingBottom: '10px' }}>
            <input
            type="checkbox"
            checked={selectAll}
            onChange={() => toggleSelectAll()}
            />
            {selectAll ? 'Deselect All' : 'Select All'}
        </div>
        
        {clients.map((client, clientIndex) => (
            <div key={clientIndex}>
            <div style={{ display: 'flex', paddingBottom: '10px' }}>
            <input
            type="checkbox"
            checked={client.selected}
            disabled={client.matters.length === 0}
            onChange={() => toggleClientSelection(clientIndex)}
            />
            <span style={{ color: client.matters.length === 0 ? disabledBorderColor : 'inherit' }}>
                {client.name}
            </span>
            </div>
            
            {client.matters.map((matter_id, matterIndex) => (
                <div key={matterIndex} style={{ paddingLeft: '20px' }}>
                    <input
                        type="checkbox"
                        checked={matters[matter_id] ? matters[matter_id].selected : false}
                        onChange={() => {toggleMatterSelection(clientIndex, matterIndex, matter_id)}}
                        /*onChange={() => {
                        const newMatters = {...matters};
                        newMatters[matter_id].selected = !newMatters[matter_id].selected;
                        setMatters(newMatters);
                        devLog(newMatters)
                        }}*/
                    />
                    {matters[matter_id].description}
                </div>
            ))}
        </div>
        ))}
        <br></br>

        <div><button className="button white" onClick={() => {
            setModalOpen(true);
        } }>Generate Invoices</button></div>

        <Modal
            isOpen={modalOpen}
            onRequestClose={() => {
                setModalOpen(false);
                }}
              contentLabel="Add Client"
            >
            <h2>Generate Invoices</h2>
            {/*<form onSubmit={(event) => generateBills({event, startDate, endDate, timezone, include, combineMatters, includeUnbilledEntries, clients, matters })}>*/}
            {/*<form onSubmit={(event) => navigate('/edit_bills', { state: { session, startDate, endDate, timezone, clients, matters, combineClients, combineMatters } })}>*/}
            <form onSubmit={submitModal}>
            <label>
                Start Date
                <EditDate date={startDate} handleDateChange={(e) => setStartDate(new Date(e.target.value))} />
                {/*<input type="date" value={startDate} onChange={e => setStartDate(e.target.value)}/>*/}
            </label>

            <label>
                End Date
                <EditDate date={endDate} handleDateChange={(e) => setEndDate(new Date(e.target.value))} />
                {/*<input type="date" value={endDate} onChange={e => setEndDate(e.target.value)}/>*/}
            </label>
            <br></br>
            {/* Bill theme */}

            {/* Detail level */}
            {/*<label>
                Detail Level:
                <div>
                <select value={detailLevel} onChange={e => setDetailLevel(e.target.value)} className="settings-select">
                    <option value="3">All Details</option>
                    <option value="2">Activity Summary</option>
                    <option value="1">Client Summary</option>
                </select>
                </div>
            </label>*/}
            {/*
            <label>
                Include:
                <div>
                <select value={include} onChange={e => setInclude(e.target.value)} className="settings-select">
                    <option value="1">Time Entries and Expenses</option>
                    <option value="2">Time Entries Only</option>
                    <option value="3">Expenses Only</option>
                </select>
                </div>
            </label>*/}

            {/* Other options represented as checkboxes */}
            <label>
                Preferences:
                <div><input type="checkbox" checked={combineClients} onChange={e => setCombineClients(e.target.checked)}/>
                Combine all clients into one invoice</div>
                
                <div><input type="checkbox" checked={combineMatters || combineClients} onChange={e => setCombineMatters(e.target.checked)} disabled={combineClients}/>
                Combine all matters for a client into one invoice</div>
                
                {/*
                <input type="checkbox" checked={includeUnbilledEntries} onChange={e => setIncludeUnbilledEntries(e.target.checked)}/>
                Include unbilled time entries*/}
            </label>

            {/* Tax later */}

              <button type="submit">Generate Invoices</button>
              <button type="button" onClick={() => {setModalOpen(false)}}>
                Cancel
              </button>
            </form>

          </Modal>

        </div>
    );
}