import React from 'react';
import moment, { min } from 'moment-timezone';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import { components } from 'react-select';
import { supabase } from '../supabaseClient';
import e from 'cors';
import { useEffect, useState, useRef } from 'react';
import '../styles/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import devLog from '../functions/devLog';

function mins_between(time1, time2) {
  return Math.floor((new Date(time2) - new Date(time1)) / 60000);
}

const styleVars = getComputedStyle(document.documentElement);

const disabledBorderColor = styleVars.getPropertyValue('--custom-color-disabled');
const disabledBackgroundColor = styleVars.getPropertyValue('--custom-color-secondary');

const hourOptions = Array.from({ length: 24 }, (v, i) => ({ value: i, label: i < 10 ? `0${i}` : `${i}` }));
const minuteOptions = Array.from({ length: 60 }, (v, i) => ({ value: i, label: i < 10 ? `0${i}` : `${i}` }));

function TimeInputField({ index, entry, field, handleInputChange, clients=null, matters=null, timezone=null, fetchEntries=null, activities=[] }) {
  const [isHovering, setIsHovering] = useState(false);
  const [showInactiveTime, setShowInactiveTime] = useState(false);
  const [selectedIntervals, setSelectedIntervals] = useState([]); // indices of selected intervals for this entry

  const inactivePopupRef = useRef(null);

  useEffect(() => {
    // Function to detect click outside
    function handleClickOutside(event) {
      if (inactivePopupRef.current && !inactivePopupRef.current.contains(event.target)) {
        setShowInactiveTime(false); // Assuming setShowInactiveTime is your state setter
      }
    }
    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  const handleVerifyClient = () => {
    devLog("Verify client");
    handleInputChange(index, 'need_to_verify_client', false);
  }
  async function handleInputChange_ (index, field, value, uuid = null) {
    handleInputChange(index, field, value, uuid);

    if (field === 'client') {
      const client = clients.find(client => client.uuid === uuid);
      if (client.matters.length > 0) {
        const matter_id = client.matters[0];
        handleInputChange_(index, 'matter', matters[matter_id].description, uuid = matter_id);
      } else {
        handleInputChange_(index, 'matter', '', uuid = null);
      }
      handleInputChange(index, 'need_to_verify_client', false);
    }
  }
  let value = entry[field];

  if (value === undefined || value === null || value === '') {
    value = "";
  } else {
    if (field === "time") {
        //console.log("Value:")
        //console.log(value)
        try {
          //console.log(entry)
          //console.log(moment(value))
          value = moment(value).tz(timezone).format('HH:mm');
        } catch (e) {
          console.error("Error converting time to timezone", e);
          devLog(value)
        }
        //console.log(value)
      }
  }
  const disabled = entry.sent_to_billing_sw || entry.billed;
  const disabledSelectStyle = disabled ? { color: disabledBorderColor, borderColor: disabledBorderColor, backgroundColor: disabledBackgroundColor } : {};

  if (field === 'client') {
    const clientOptions = clients.map(client => ({ value: client.uuid, label: client.name }));
    const boxStyle = entry.need_to_verify_client ? { borderColor: 'red', backgroundColor: '#ffcccc' } : { borderColor: 'black'};
    
    return (
      <td style={{ position: 'relative' }} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <Select
          options={clientOptions}
          value={{ value: entry["client_num"], label: value }}
          onChange={(e) => handleInputChange_(index, field, e.label, e.value)}
          isSearchable
          maxMenuHeight={1000}
          styles={{ position: "absolute",
            control: (base, state) => ({
              ...base,
              ...(!disabled ? boxStyle : disabledSelectStyle),
              /*
              borderColor: state.isDisabled ? disabledBorderColor : 'black',
              color: state.isDisabled ? disabledBorderColor : base.color,
              backgroundColor: state.isDisabled ? disabledBackgroundColor : base.backgroundColor,*/
              '&:hover': { borderColor: 'gray' }
            }),
          }}
            isDisabled={disabled}
        />
        {(!disabled && entry.need_to_verify_client) && (
          <button className="button verify" onClick={handleVerifyClient} style={{ marginTop: '5px' }}>✓</button>
        )}
      </td>
    );
  }

  if (field === 'matter') {
    if (entry.client === null || entry.client === undefined || entry.client === '') {
      entry.client = null;
    }

    if (!entry.client || clients.length === 0) {
      return (
        <td>
          <Select
            isDisabled
            placeholder="Select client"/>
        </td>);}
    
    //console.log("Clients")
    //console.log(clients)
    //console.log("Entry")
    //console.log(entry)

    const client = clients.find(client => client.uuid === entry.client_num);
    if (!client) {
      devLog("Client not found")
      return (
        <td>
          <Select
            isDisabled
            placeholder="Select client"/>
        </td>
      );
    }

    const matterUUIDs = client.matters;

    let matterOptions = [];
    // check if matters is a dictionary with at least one key
    if (matters && Object.keys(matters).length > 0) {
       matterOptions = matterUUIDs.map(matterID => ({ value: matterID, label: matters[matterID].description }));
    }

    return (
      <td>
        <Select
          options={matterOptions}
          value={{ value: entry["matter_num"], label: value }}
          onChange={(e) => handleInputChange_(index, field, e.label, e.value)}
          isSearchable
          maxMenuHeight={1000}
          styles={{ position: "absolute",
            control: (base, state) => ({
              ...base,
              borderColor: state.isDisabled ? disabledBorderColor : 'black',
              color: state.isDisabled ? disabledBorderColor : base.color,
              backgroundColor: state.isDisabled ? disabledBackgroundColor : base.backgroundColor,
              '&:hover': { borderColor: 'gray' }
            }) }}
          isDisabled={disabled}
        />
      </td>
    );
  }


  if (field === 'activity') {
    let activityOptions;
    if (activities && activities.length > 0) {
      activityOptions = activities.map(value => ({ value, label: value }));
    } else {
      activityOptions = [ 'Phone Call', 'Court Time', 'Deposition', 'Research', 'Review', 'Training', 'Prepare', 'Analyze', 'Correspondence', 'Meeting', 'Draft', 'Break' ].map(value => ({ value, label: value }));
    }    
    // return a dropdown menu
    return (
      <td>
        <Select
          options={activityOptions}
          value={{ value: value, label: value }}
          onChange={(e) => handleInputChange_(index, field, e.value)}
          isSearchable
          maxMenuHeight={1000} // Increase as needed
          styles={{ position: "absolute",
            control: (base, state) => ({
              ...base,
              borderColor: state.isDisabled ? disabledBorderColor : 'black',
              color: state.isDisabled ? disabledBorderColor : base.color,
              backgroundColor: state.isDisabled ? disabledBackgroundColor : base.backgroundColor,
              '&:hover': { borderColor: 'gray' }
            }) }}
          isDisabled={disabled}
        />
      </td>
    );
  }

  function handleInactiveTimeClick() {
    setShowInactiveTime(!showInactiveTime);
  }
  const handleCheckboxChange = (index) => {
    setSelectedIntervals((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  async function submitIntervals() {
    // Logic to handle the submission of selected intervals
    // Hide the popup after submission
    const intervalsToRemove = entry.inactive_intervals.filter((_, index) => selectedIntervals.includes(index));
    devLog("Intervals to remove")
    devLog(intervalsToRemove)

    let mins_taken = Math.round(entry.time_taken * 60);
    devLog("Mins taken")
    devLog(mins_taken)
    for (let interval of intervalsToRemove) {
      mins_taken += mins_between(interval[0], interval[1]);
      devLog("mins_between")
      devLog(mins_between(interval[0], interval[1]))
    }

    // Logic to remove intervals and update time_taken goes here
    const new_intervals = entry.inactive_intervals.filter((_, index) => !selectedIntervals.includes(index));
    let inactive_mins = 0;
    for (let interval of new_intervals) {
        inactive_mins += mins_between(interval[0], interval[1]);
    }
    const {data, error} = await supabase
      .from('block_entries')
      .update({
        inactive_intervals: new_intervals,
        inactive_time: Math.round(inactive_mins / 6),
        time_taken: Math.round(mins_taken / 6)
      })
      .eq('uuid', entry.uuid)
      .eq('profile_id', entry.profile_id);
    setSelectedIntervals([]);
    setShowInactiveTime(false);
    fetchEntries(); // refresh the entries
  }
  async function cancelIntervals() {
    // Logic to handle the cancellation of selected intervals
    // Hide the popup after cancellation
    // in supabase, set the intervals to null
    const {data, error} = await supabase
      .from('block_entries')
      .update({
        inactive_intervals: null,
        inactive_time: null
      })
      .eq('uuid', entry.uuid)
      .eq('profile_id', entry.profile_id);

    setSelectedIntervals([]);
    setShowInactiveTime(false);
    fetchEntries(); // refresh the entries
  }

  return (
    <td>
      <style>
        {`
          #appt-${index}-${field}::placeholder {
            color: black;
          }
        `}
      </style>
      {field === 'time_taken' ? (
          <div style={{ position: 'relative' }}>
          {(entry.inactive_intervals && entry.inactive_intervals.length > 0 ) && (
            <button
              className = "button verify"
              style={{ backgroundColor: 'white' }}
              title="Inactive time"
              onClick={handleInactiveTimeClick}>
              <FontAwesomeIcon icon={faClock} />
            </button>
          )}
          <input
            type="text"
            value={value}
            onChange={(e) => handleInputChange_(index, field, e.target.value)}
            //style={{ color: 'black' }}
            disabled={disabled}
          />
          
          {(showInactiveTime && entry.inactive_intervals) && (
            <div id="inactiveTimePopup" className="inactive-popup" ref={inactivePopupRef}>
              <div className="inactive-popup-content">
              <h3 style={{marginTop: '5px', marginBottom: '5px'}}>You were inactive for the following intervals:</h3>
              
              {entry.inactive_intervals.map((interval, index) => {
                // Use Intl.DateTimeFormat to format the date in the user's timezone
                const formatter = new Intl.DateTimeFormat('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: timezone,
                  hour12: true
                });

                // Format the start and end dates
                const formattedStart = formatter.format(new Date(interval[0]));
                const formattedEnd = formatter.format(new Date(interval[1]));

                return (
                  <div key={index} style={{ display: 'flex', paddingBottom: '2px', alignItems: 'center' }}>
                    <input type="checkbox"
                    onChange={() => handleCheckboxChange(index)}></input>
                    <label>{formattedStart} - {formattedEnd}</label>
                  </div>
                );
              })}

              <div className="container">
                <button className = "button white" disabled={selectedIntervals.length === 0} onClick={() => submitIntervals()}>Mark as Working Time</button>
                <button className = "button white" onClick={() => cancelIntervals()}>Ignore All</button>
              </div>
            </div>
          </div>
          )}
        </div>
      ) : field === 'time' ? (
          <input
            type="time"
            id={`appt-${index}-${field}`}
            name="appt"
            required
            defaultValue={value || "12:00"}
            onChange={(e) => handleInputChange_(index, field, e.target.value)}
            disabled={disabled}
          />
      ) : (
        <input
          type="text"
          value={value}
          onChange={(e) => handleInputChange_(index, field, e.target.value)}
          //style={{ color: 'black' }}
          disabled={disabled}
        />
      )}
    </td>
  );
}

export default TimeInputField;