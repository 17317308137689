import React, { useState } from 'react';
import { supabase } from '../supabaseClient'
import Modal from 'react-modal';
import devLog from '../functions/devLog';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

function ErrorReportModal({ showErrorModal, onClose }) {
  const [errorScreen, setErrorScreen] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    devLog(errorMessage);

    try {
        const session = (await supabase.auth.getSession()).data.session;
        
        let { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('first_name, last_name, email')
            .eq('profile_id', session.user.id)
            .single();

        if (profileError) throw profileError;

        // Insert the complaint into the complaints table
        const { data: errorData, error: insertError } = await supabase
            .from('feedback')
            .insert([
            { 
                profile_id: session.user.id,
                name: profileData.first_name + ' ' + profileData.last_name,
                email: profileData.email,
                screen: errorScreen,
                complaint: errorMessage,
            },
        ]);

        if (insertError) throw insertError;

        localStorage.setItem('last_submitted_feedback', new Date().toISOString());

        devLog('Issue reported successfully:', errorData);
        alert('Issue successfully reported!'); // Show success message

        setErrorScreen(''); // Clear the screen field
        setErrorMessage(''); // Clear the message field

    } catch (error) {
        console.error('Error reporting failed:', error);
    }

    onClose(); // Close the modal
  };

  return (
    <Modal
        isOpen={showErrorModal}
        onRequestClose={() => {
            onClose();
            }}
            contentLabel="Report an Issue"
            className="medium-modal"
        >
        <h2>Report an Issue</h2>
        <form onSubmit={handleSubmit}>
            <h4 style={{ paddingBottom: '10px' }}>Please describe the problem you encountered in as much detail
                as possible. We will fix it as fast as we can!</h4>
            
            <label>Screen on which issue was encountered</label>
            <textarea
                className="text-area"
                style={{height: '30px'}}
                value={errorScreen}
                onChange={(e) => setErrorScreen(e.target.value)}
                placeholder="e.g. home, billing, settings..."
            />

            <label>Issue</label>
            <textarea 
                className="text-area"
                value={errorMessage} 
                onChange={(e) => setErrorMessage(e.target.value)} 
                placeholder="Describe the issue here..."
            />
            <div style={{gap: '10px'}}>
                <button type="submit" className="send-error">Send Error Report</button>
                <button type="button" onClick={onClose}>Cancel</button>
            </div>
      </form>
    </Modal>
  );
}

export default ErrorReportModal;