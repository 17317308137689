import { useRef, useState, useEffect } from 'react'
import CryptoJS from 'crypto-js';
import { supabase } from './supabaseClient'
import { getEmails } from './functions/azure/getEmails.js';
import { getCalendar } from './functions/azure/getCalendar.js';
import { getDrafts } from './functions/azure/getDrafts.js';
import { getDocs } from './functions/azure/getDocs.js';
import { getMeetings } from './functions/zoom/getMeetings.js';
import tokenExpired from './functions/azure/tokenExpired.js';
import queryClio from './functions/clio/queryClio.js';
import testRLS from './functions/testRLS.js';
import './styles/style.css';
import Entries from './Entries';
import { CustomSelect } from './components/customSelect.js';
import { Link } from 'react-router-dom';
import Navbar from './components/Navbar'; // Adjust the path based on your file structure
import getClientsMatters from './functions/lawcus/getClientsMatters.js';
import signInWithLawcus from './functions/lawcus/signInWithLawcus.js';
import { getEmails as getEmailsGoogle } from './functions/google/getEmails.js';
require('dotenv').config();

export default function Account({ session, billingSoftware }) {
  const [loading, setLoading] = useState(true)
  const [clients, setClients] = useState(null);
  const [matters, setMatters] = useState(null);
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [timezone, setTimezone] = useState('America/New_York');
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    if (session === null) {
      supabase.auth.signOut();
    }

    function shouldCallGetDocs() {
      // Check if it's been less than 1 minute since the last call
      // this is to avoid calling getDocs multiple times on reload
      if (hasJustSignedIn()) {
        return false;
      }
      const lastCallTimestamp = localStorage.getItem('lastGetDocsCall');
      const now = new Date().getTime();
      const interval = 1 * 60 * 1000; // 1 minute in milliseconds
      if (lastCallTimestamp) {
        const timeSinceLastCall = now - parseInt(lastCallTimestamp, 10);
        if (timeSinceLastCall < interval) { // It's been less than the interval since the last call
          return false;
        }
      }
      localStorage.setItem('lastGetDocsCall', now.toString()); // Update the timestamp in localStorage
      return true;
    }
    function hasJustSignedIn() {
      // check if the user has signed in within a minute ago- the provider token won't be updated yet
      const signInTime = new Date(session.user.last_sign_in_at); // Assuming session.user.signInTime is the sign-in timestamp
      const currentTime = new Date();
      const timeDifference = currentTime - signInTime;
      return timeDifference < 30 * 1000; // Less than 30 seconds
    }

    function getDocsIfAppropriate() {
      if (shouldCallGetDocs()) {
        getDocs({timezone: timezone}); // Replace with your actual function to fetch documents
      }
    }

    async function getProfileAndEmails() {
      //console.log("Getting profile and emails")
      if (!isMounted.current) return;
      try {
        const { user } = session

        let { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('profile_id', user.id)
          .single()
        if (error) throw error;
        
        if (isMounted.current) {
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setTimezone(data.timezone);
        }
        let { data: int, error: intError } = await supabase
          .from('integrations')
          .select('*')
          .eq('profile_id', user.id)
          .single()
        if (intError) throw intError;
        
        // get clients and matters
        const { data: clientsData, error: clientError } = await supabase
          .from('clients')
          .select('*')
          .eq('profile_id', session.user.id);
        if (clientError) {
          console.error('Error fetching clients:', clientError);
        }
        if (isMounted.current) setClients(clientsData);

        const { data: mattersData, error: matterError } = await supabase
          .from('matters')
          .select('*')
          .eq('profile_id', session.user.id);
        if (matterError) {
          console.error('Error fetching matters:', matterError);
        }
        // create a dictionary mapping the matter uuid to the matter
        const matters = {};
        for (let matter of mattersData) {
          matters[matter.uuid] = matter;
        }
        if (isMounted.current) setMatters(matters);

        try {
            if (!hasJustSignedIn()) {
              const emailsData = await getEmails({ supabase: supabase, timezone: timezone, clients: clientsData, matters: matters });
            }
          } catch (error) {
              console.error('Error calling getEmails:', error);
        }

        try {
          if (!hasJustSignedIn()) {
            await getCalendar({ supabase: supabase, timezone: timezone });
            if (int.use_zoom) {
              await getMeetings({ supabase: supabase })
            }
          }
        } catch (error) {
            console.error('Error calling getCalendar:', error);
        }

        } catch (error) {
          console.error("Error in Account:", error);
        } finally {
          //console.log("Is mounted")
          //console.log(isMounted.current)
          if (isMounted.current) setLoading(false);
      }
    }

    if (isMounted.current) {
      getProfileAndEmails();
    }

    let intervalId;
    if (isMounted.current) {
      // Get the drafts and documents every 6 minutes
      if (!hasJustSignedIn()) {
        getDrafts();
      }
      //getDocs({ timezone: timezone });
      getDocsIfAppropriate();

      intervalId = setInterval(() => {
        getDrafts();
        //getDocs({ timezone: timezone });
        getDocsIfAppropriate();
      }, 6 * 60 * 1000);
    }

    // Clear the interval when the component unmounts
    return () => {
      isMounted.current = false; // Component is unmounted
      clearInterval(intervalId);
    };
    
  }, [session])

  // Add in refresh emails code: must get new data and redisplay so
  // figure out multiple ways to do that.
  // 1. Add a refresh button that calls getEmails again
  
  const heading = ["Time", "Time Spent", "To:", "Subject", "Client number", "Matter number", "Description"];
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  //startDate.setHours(0, 0, 0, 0);

  if (loading) {
      return <div>Loading...</div>;
  }
  //const weekAgo = new Date(startDate);
  //weekAgo.setDate(weekAgo.getDate() - 9);

  /*
  async function signInWithAdobe() {
    const ADOBE_AUTH_URL = 'https://ims-na1.adobelogin.com/ims/authorize';

    const deployed = process.env.REACT_APP_DEPLOYED_BOOLEAN;
    const CLIENT_ID = process.env.REACT_APP_ADOBE_CLIENT_ID;
    console.log("Client ID: " + CLIENT_ID)
    console.log("Deployed: " + deployed)
    let REDIRECT_URI = deployed === 'true' 
      ? `${process.env.REACT_APP_PRODUCTION_URL}/api/adobe/auth/callback` 
      : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/adobe/auth/callback`;
    REDIRECT_URI = encodeURIComponent(REDIRECT_URI);
    const SCOPES = 'openid,AdobeID,DCAPI';
    const RESPONSE_TYPE = 'code';
    //const url = `${ADOBE_AUTH_URL}?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}`;
    const authUrl = `https://ims-na1.adobelogin.com/ims/authorize/v2?client_id=${CLIENT_ID}&scope=${SCOPES}&response_type=code&redirect_uri=${REDIRECT_URI}`

    console.log(authUrl)
    window.location.href = authUrl;
  }*/

  return (
    <div className="form-widget">
      {/*<Navbar setup={false} useClio={useClio} />*/}

      {/*For testing:*/}
      {/*<button className="button white" onClick={() => { signInWithLawcus(setSignedInWithLawcus ) }}> Sign In with Lawcus </button>*/}
      {/*
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button className="button secondary" style={{ margin: '10px', height: '50px', fontSize: '17px' }} onClick={decrementDate}>&lt;</button>
        <input 
          className="date-input"
          type="date" 
          value={startDate.toISOString().substring(0,10)} 
          onChange={(event) => handleStartDateChange(event)}
        />
        <input
          className="date-input"
          type="date" 
          value={endDate.toISOString().substring(0,10)} 
          onChange={(event) => handleEndDateChange(event)}
        />
        <button className="button secondary" style={{ margin: '10px', height: '50px', fontSize: '17px' }} onClick={incrementDate} disabled={startDate.getTime() === today.getTime()}>&gt;</button>
      </div>*/}

      {/*
      <div style={{ textAlign: 'center' }}>
          <button className="button white" onClick={() => {queryClio({ supabase });}}>
            Get Clients from Clio
          </button>
      </div>*/}
      {/*
      <div style={{ textAlign: 'center' }}>
          <button className="button white" onClick={() => {testRLS();}}>
            Test RLS
          </button>
      </div>*/}

      
      <div>
          <Entries session={session} timezone={timezone} billingSoftware={billingSoftware}/>
      </div>

    </div>
  )
}
