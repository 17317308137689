import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import generateCsrfToken from '../generateCsrfToken.js';
import fetchClientsMatters from '../fetchClientsMatters.js';
import refreshZoomToken from './refreshToken.js';
import devLog from '../devLog.js';

export async function getMeetings({ supabase }) {
    let session = await supabase.auth.getSession();
    session = session.data.session;
    // get past zoom meetings after last_updated_zoom
    const { data: updatedData, error: profileError } = await supabase
        .from('last_updated')
        .select('zoom')
        .eq('profile_id', session.user.id);
    if (profileError) {
        console.error('Error fetching last_updated:', profileError);
        return;
    }
    const { data: descData, error: descError } = await supabase
        .from('custom_descriptions')
        .select('*')
        .eq('profile_id', session.user.id)
        .eq('type', 'meeting');
    if (descError) {
        console.error('Error fetching description data:', descError);
        return;
    }
    let text1;
    if (!descData || !descData.length || !descData[0].text1) text1 = 'Meeting regarding'
    else text1 = descData[0].text1;
    let text2;
    if (!descData || !descData.length || !descData[0].text2) text2 = 'with'
    else text2 = descData[0].text2;
    // if last_updated is null, set it to yesterday
    let lastUpdated = new Date(new Date().setDate(new Date().getDate() - 1))
    if (updatedData.length > 0 && updatedData[0].zoom)
        lastUpdated = new Date(updatedData[0].zoom);
    
    // if we updated this less than 15 minutes ago, return
    if (new Date() - lastUpdated < 15 * 60 * 1000) {
        return;
    }
    devLog("Getting Zoom Meetings")

    const {data: accessTokenData, error: accessTokenError} = await supabase
        .from('profiles')
        .select('zoom_access_token, timezone')
        .eq('profile_id', session.user.id);
    if (accessTokenError) {
        console.error('Error fetching zoom access token:', accessTokenError);
        return;
    }
    if (!accessTokenData.zoom_access_token) {
        refreshZoomToken( {supabase} );
        return;
    }
    const key = process.env.REACT_APP_ZOOM_ENCRYPTION_KEY;
    //const accessToken = CryptoJS.AES.decrypt(accessTokenData[0].zoom_access_token, key).toString(CryptoJS.enc.Utf8);
    const encryptedID = CryptoJS.AES.encrypt(session.user.id, key).toString();
    const timezone = accessTokenData[0].timezone;

    //console.log("Access Token:")
    //console.log(accessToken)
    // call my server-side function to get the meetings
    const serverEndpoint = process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true' ?
        process.env.REACT_APP_PRODUCTION_URL + '/api/zoom/get-meetings'
        : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/zoom/get-meetings`;
    const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks
    // store the protection string in supabase
    const { _, error } = await supabase
        .from('integrations')
        .update({ csrf_token: CSRF_PROTECTION_STRING })
        .eq('profile_id', session.user.id);
    if (error) {
        console.error('Error updating CSRF token in Supabase:', error);
        return;
    }

    let data;
    
    try {
        const response = await fetch(serverEndpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': CSRF_PROTECTION_STRING,
            'X-Profile-ID': encryptedID,
            'X-Last-Updated': lastUpdated,
          }
        });

        if (!response.ok) {
            if (response.status === 401) {
                devLog('Access token expired');
                refreshZoomToken( {supabase} );
                return;
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }
    
        data = await response.json();

    } catch (error) {
        console.error('Error fetching Zoom meetings:', error);
        return null;
    }
    devLog("Meetings:")
    devLog(data)

    if (!data) return;

    try {
        const {clients, matters} = await fetchClientsMatters(null, null, supabase, session);
        const zoom_data = parseMeetingData(data, session, clients, matters, timezone);

        devLog("Zoom Data:")
        devLog(zoom_data)

        for (let meeting of zoom_data) {
            insertOrUpdateMeeting(meeting, supabase, session, text1, text2);
        }

        // update the last_updated column for this user if all meetings were inserted successfully
        const { error: updateError } = await supabase
            .from('last_updated')
            .upsert({ 'profile_id': session.user.id, 'zoom': new Date() }) // set the last_updated column to the current date and time
            .eq('profile_id', session.user.id); // where the id is the current user's id
        if (updateError) console.error('Error updating last_updated:', updateError);

    } catch (error) {
        console.error('Error inserting meetings:', error);
    }
}

const insertOrUpdateMeeting = async (meeting, supabase, session, text1, text2) => {
    devLog("Inserting or Updating Meeting")
    devLog(meeting.start)
    devLog(moment(meeting.start).subtract(20, 'minutes').toISOString())
    // pull all meetings from calendar with a start time within 20 minutes of the start time of the zoom meeting
    const { data: calendarData, error: calendarError } = await supabase
        .from('calendars')
        .select('*')
        .eq('profile_id', session.user.id)
        .gte('start', moment(meeting.start).subtract(20, 'minutes').toISOString())
        .lte('start', moment(meeting.start).add(20, 'minutes').toISOString());
    if (calendarError) {
        console.error('Error fetching calendar data:', calendarError);
    }
    devLog("Calendar Data:")
    devLog(calendarData)
    for (let calendarMeeting of calendarData) {
        // find overlap time
        const start1 = moment(meeting.start);
        const end1 = moment(meeting.end);
        const start2 = moment(calendarMeeting.start);
        const end2 = moment(calendarMeeting.end);

        devLog(start1)
        devLog(end1)
        devLog(start2)
        devLog(end2)
        const overlap = Math.min(end1, end2) - Math.max(start1, start2);
        devLog("Meeting:")
        devLog(meeting)
        devLog("Minutes of overlap:")
        devLog(overlap / 60000)
        // get overlap in minutes
        if (Math.floor(overlap / 60000) < 5) {
            continue;
        }
        const max_percent_overlap = Math.max(overlap / (end1 - start1), overlap / (end2 - start2));
        devLog("Max Percent Overlap")
        devLog(max_percent_overlap)
        if (meeting.uuid === calendarMeeting.uuid || max_percent_overlap > 0.5) {
            devLog("Upating Meeting")
            devLog(calendarMeeting)
            // update start and end times, time_taken
            const {error} = await supabase
                .from('calendars')
                .update({ start: start1.toISOString(), end: end1.toISOString(), time_taken: meeting.time_taken })
                .eq('uuid', calendarMeeting.uuid);
            if (error) {
                console.error('Error updating meeting in calendars:', error);
            }
            // update in supabase entries table
            const {error: entriesError} = await supabase
                .from('entries')
                .update({ time: start1.toISOString(), time_taken: meeting.time_taken })
                .eq('uuid', calendarMeeting.uuid);
            if (entriesError) { console.error('Error updating meeting in entries:', entriesError); }

            // update in supabase block entries table
            const {error: blockEntriesError} = await supabase
                .from('block_entries')
                .update({ time: start1.toISOString(), time_taken: meeting.time_taken })
                .eq('uuid', calendarMeeting.uuid);
            if (blockEntriesError) { console.error('Error updating meeting in block entries:', blockEntriesError); }
            return;
        }
    }

    devLog("Inserting Meeting")
    const { client, matter, client_num, matter_num, ...meetingtoInsert } = meeting;
    devLog(meetingtoInsert)
    // didn't find any overlapping meetings, so insert the meeting
    const { error } = await supabase
        .from('calendars')
        .insert([meetingtoInsert]);
    if (error) {
        console.error('Error inserting meeting:', error);
        return;
    }
    let description = `${text1} ` + meeting.subject;
    if (meeting.participants_names && meeting.participants_names.length > 0) {
        description += ` ${text2} ` + meeting.participants.join(', ');
    }
    const entryToInsert = {
        uuid: meeting.uuid,
        profile_id: session.user.id,
        date: meeting.date,
        time: meeting.start,
        time_taken: meeting.time_taken,
        client_num: meeting.client_num,
        matter_num: meeting.matter_num,
        client: meeting.client,
        matter: meeting.matter,
        description: description,
        type: "calendar",
        activity: "Meeting",
    };
    devLog(entryToInsert)
    const { error: entryError } = await supabase
        .from('entries')
        .insert([entryToInsert]);
    if (entryError) { console.error('Error inserting entry:', entryError); return; }
    const blockEntryToInsert = {
        ...entryToInsert,
        entries: [entryToInsert.uuid],
        need_to_verify_client: entryToInsert.client ? true : false,
    };
    devLog(blockEntryToInsert)
    const { error: blockEntryError } = await supabase
        .from('block_entries')
        .insert([blockEntryToInsert]);
    if (blockEntryError) { console.error('Error inserting block entry:', blockEntryError); return; }
}

const parseMeetingData = (data, session, clients, matters, timezone) => {
    let formattedData = [];
    for (let meeting of data) {
        const {client, matter} = getClientAndMatter(meeting.topic, clients, matters);
        let formattedMeeting = {
            uuid: session.user.id + ' ' + meeting.uuid + " zoom",
            profile_id: session.user.id,
            start: meeting.start_time,
            end: meeting.end_time,
            date: moment(meeting.start_time).tz(timezone).format('YYYY-MM-DD'),
            time_taken: Math.max(parseInt(meeting.duration / 6), 1), // meeting.duration is in minutes
            subject: meeting.topic,
            client: client ? client.name : null,
            client_num: client ? client.uuid : null,
            matter_num: matter,
            matter: matter ? matters[matter].description : null,
            attendees_names: meeting.participants_names ? meeting.participants_names : [],
            attendees_emails: meeting.participants_emails ? meeting.participants_emails : [],
        };
        formattedData.push(formattedMeeting);
    }
    return formattedData;
}

function getClientAndMatter(title, clients, matters) {
    if (clients.length === 0) {
        return {client: null, matter: null};
    }
    let title_words = (title.match(/\b[\w']+\b/g) || []).map(word => word.toLowerCase());
  
    let clientMatches = clients.map(client => ({...client, matches: 0}));
  
    for (let client of clients) {
        const split = client.name.split(' ');
        let first_name = split[0].toLowerCase();
        let last_name = split[split.length-1].toLowerCase();
        // now see if name matches
        if (title_words.includes(first_name)) {
            clientMatches[clients.indexOf(client)].matches += 1;
        }
        if (title_words.includes(last_name)) {
            clientMatches[clients.indexOf(client)].matches += 1;
        }
        for (let matter_uuid of client.matters) {
            let matter = matters[matter_uuid];
            let matter_words = (matter.description.match(/\b[\w']+\b/g) || []);
            matter_words = matter_words.map(word => word.toLowerCase());
            for (let word of title_words) {
                if (matter_words.includes(word)) {
                    clientMatches[clients.indexOf(client)].matches += 1;
                }
            }
        }
    }
    //console.log("Client Matches:")
    //console.log(clientMatches)
  
    // sort the candidate clients
    // if there are multiple clients, return the one with the most matches
    // if there's a tie, return null
    clientMatches.sort((a, b) => b.matches - a.matches);
    if (clientMatches[0].matches === 0) {
        return {client: null, matter: null};
    }
    if (clientMatches.length === 1 || clientMatches[0].matches === clientMatches[1].matches) {
        return {client: null, matter: null};
    }
    if (clientMatches[0].matters.length === 1) {
        return {client: clientMatches[0], matter: clientMatches[0].matters[0]};
    }
    return {client: clientMatches[0], matter: null};
  }