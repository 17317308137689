import { useEffect, useState } from 'react';
import { supabase } from './supabaseClient.js';
import TimeInputField from './components/timeInputField.js';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import fetchClientsMatters from './functions/fetchClientsMatters.js';
import { blockEntries } from './functions/blockEntries.js';
import EntriesDay from './EntriesDay';
import EntriesCal from './EntriesCal';
import EditDate from './components/EditDate';
import { CustomSelect } from './components/customSelect.js';
import './styles/style.css';
import { handleDelete } from './functions/handleDelete.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const offBlackColor = getComputedStyle(document.documentElement).getPropertyValue('--off-black-color');

export default function Entries( {session, timezone, billingSoftware}) {
    const [entries, setEntries] = useState([]);
    const [clientID, setClientID] = useState(null); // uuid of selected client
    const [clientDict, setClientDict] = useState(null); // dictionary of client uuids to names
    const [clients, setClients] = useState([]);
    const [matters, setMatters] = useState({});
    const [loading, setLoading] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(moment.tz(timezone).startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment.tz(timezone).startOf('day').toDate());
    const [view, setView] = useState("day"); // day or client
    const [numDaysDisplayed, setNumDaysDisplayed] = useState(0); // how long before endDate we should make startDate
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedEntries, setSelectedEntries] = useState([]);

    const onDaysDisplayedChange = (value) => {
        setNumDaysDisplayed(value);
        setStartDate(moment(endDate).tz(timezone).subtract(value, 'days').toDate());
    }
  
    useEffect(() => {
      // Function to set pageLoaded to true
      const handleLoad = () => {
        setPageLoaded(true);
      };
      // Add event listener for the load event
      window.addEventListener('load', handleLoad);
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('load', handleLoad);
    }, []); // Empty dependency array to run only once on mount
  
    useEffect(() => {
        fetchClientsMatters(setClients, setMatters, supabase, session);
        //console.log("Start Date")
        //console.log(startDate)
        //console.log("End Date")
        //console.log(endDate)
        setLoading(false);
    }, [pageLoaded, startDate, endDate, view, session]);

    // function to process entries so that the time_taken column gets divided by 10.0
    function sup_to_display(entries) {
      return entries.map(entry => ({
        ...entry,
        time_taken: entry.time_taken / 10.0
    }));}

    async function saveEntries() {
      // create a new array of entries with time_taken multiplied by 10
      const supabaseEntries = entries.map(fullEntry => {
          const { selected, ...entry } = fullEntry;
          const timeTakenNumber = Number(entry.time_taken);
          return {
              ...entry,
              time_taken: (!isNaN(timeTakenNumber)) ? Math.round(timeTakenNumber * 10.0) : 1
          };
      });
      //console.log("Saving entries")
      //console.log(supabaseEntries)

      for (let entry of supabaseEntries) {
        delete entry.type;
        const { error } = await supabase
          .from('block_entries')
          .update(entry)
          .match({ uuid: entry.uuid });

        if (error) {
          console.log('Error updating entry:', error);
          return;
        }
        
        if (entry.entries.length === 1) {
          // single entry has everything in entry without the entries array
          let singleEntry = {
            ...entry,
          };
          delete singleEntry.entries;
          delete singleEntry.uuid;
          delete singleEntry.need_to_verify_client;
          delete singleEntry.inactive_intervals;
          delete singleEntry.inactive_time;

          const { error2 } = await supabase
            .from('entries')
            .update(singleEntry)
            .match({ uuid: entry.entries[0] });
          if (error2) {
            console.log('Error updating entry:', error2);
            return;
          }
        }
        setSaveEnabled(false);
      }
    }
    const incrementDate = () => {
        saveEntries();
        let newDate = new Date(endDate);
        newDate.setDate(newDate.getDate() + 1);
        setStartDate(newDate);
        setEndDate(newDate);
        setNumDaysDisplayed(0);
    };
    const decrementDate = () => {
        saveEntries();
        let newDate = new Date(endDate);
        newDate.setDate(newDate.getDate() - 1);
        setStartDate(newDate);
        setEndDate(newDate);
        setNumDaysDisplayed(0);
    };
    const handleDateChange = (event) => { // for the editable date field
      //console.log("handleDateChange")
      //setEndDate(new Date(event.target.value));
      
      let date = new Date(event.target.value);
      // add the timezone offset to the date given the variable timezone which is a string such as "EST"
      if (timezone === "America/Los_Angeles") {
        date.setHours(date.getHours() + 7);
      } else if (timezone === "America/New_York") {
        date.setHours(date.getHours() + 4);
      } else if (timezone === "America/Chicago") {
        date.setHours(date.getHours() + 5);
      } else if (timezone === "America/Denver") {
        date.setHours(date.getHours() + 6);
      }
      //console.log(date)
  
      setEndDate(date);
      setStartDate(date);
      setNumDaysDisplayed(0);
    };

    async function entrySelectToggle(id) {
      entries[id].selected = !entries[id].selected;
      setEntries([...entries]);
      const index = selectedEntries.indexOf(id);
      if (index === -1) {
        // id is not in selectedEntries, so add it
        setSelectedEntries([...selectedEntries, id]);
        setShowDelete(true);
      } else {
        // id is in selectedEntries, so remove it
        setSelectedEntries(selectedEntries.filter(entryId => entryId !== id));
        if (selectedEntries.length === 1) {
          setShowDelete(false);
        }
      }
    }

    async function deleteEntries() {
      setShowDelete(false);
      await handleDelete(supabase, entries.filter((entry, index) => selectedEntries.includes(index)));
      const newEntries = entries.filter((entry, index) => !selectedEntries.includes(index));
      setEntries(newEntries);
      setSelectedEntries([]);
    }

    if (loading || !clients || !matters) {
      return <div>Loading...</div>;
    }

    const today = moment().tz(timezone).startOf('day').toDate();
  
    let client = clientID == null ? null : clients.find(client => String(client.uuid) === String(clientID));

    return (
        <div className="form-widget" style={{ color: 'black' }}>
            <div className="container">
                <div className="block-button">
                    <button
                      className="button white"
                      onClick={deleteEntries}
                      disabled={!showDelete}
                      style={{ display: 'flex' }}
                    >
                    <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
                <div className="block-button">
                    <button
                      className="button white"
                      onClick={() => {saveEntries(); blockEntries({ session, currentDate: startDate, timezone, setEntries });}}
                      disabled={clientID !== null || numDaysDisplayed !== 0}>
                      Block entries
                    </button>
                </div>
                
                <div className="button-group">
                    <button className="button secondary" onClick={decrementDate}>
                      {/*&lt;*/}
                      <i className="fas fa-caret-left" style={{fontSize: '17px'}}></i>
                    </button>
                    {/*<span style={{ margin: '10px' }}>{endDate.toDateString()}</span>*/}
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}> <EditDate date={endDate} handleDateChange={handleDateChange} /> </span>
                    <button className="button secondary" onClick={incrementDate} disabled={endDate.getTime() === today.getTime()}>
                      <i className="fas fa-caret-right" style={{fontSize: '17px'}}></i>
                    </button>
                </div>
                <div>
                  <label>Show:</label>
                  <CustomSelect myVar={numDaysDisplayed} setVar={onDaysDisplayedChange} value_to_display={{0: 'PAST DAY', 6: 'PAST WEEK', 13: 'PAST 2 WEEKS', 27: 'PAST 4 WEEKS'}} defaultValue={0} defaultDisplay={"PAST DAY"} />
                </div>
                
                <div>
                  <button
                    className={`button white ${view === 'client' ? 'active' : ''}`}
                    onClick={() => {
                      setView(view === 'day' ? 'client' : 'day');
                      if (view === 'client') {
                        setClientID(null);
                      }
                    }}
                  >
                    Show Client
                  </button>
                </div>

                {/*
                <div>
                  <label>Display by:</label>
                <CustomSelect myVar={view} setVar={setView} value_to_display={{'day': 'Day', 'client': 'Client'}} defaultValue={'day'} defaultDisplay={'Day'} />
                </div>*/}
                {
                    view === 'client' && (
                        (() => {
                        let clientDict = {};
                        clients.forEach(client => {
                            clientDict[client.uuid] = client.name;
                        });
                        return (
                            <CustomSelect myVar={clientID} setVar={setClientID} value_to_display={clientDict} defaultValue={clients[0].uuid} defaultDisplay={"Select Client"} />
                        );
                        })()
                    )
                }
            </div>
            {
              numDaysDisplayed === 0 || numDaysDisplayed === '0' ? (
                <div>
                  <EntriesDay session={session} billingSoftware={billingSoftware} day={endDate} client={client} timezone={timezone} today={today} entries={entries} setEntries={setEntries} saveEntries={saveEntries} saveEnabled={saveEnabled} setSaveEnabled={setSaveEnabled} entrySelectToggle={entrySelectToggle} setShowDelete={setShowDelete}/>
                </div>
              ) : (
                <div>
                  <EntriesCal session={session} startDay={startDate} endDay={endDate} client={client} timezone={timezone} setView={setView} setStartDate={setStartDate} setEndDate={setEndDate} setNumDaysDisplayed={setNumDaysDisplayed} entries={entries} setEntries={setEntries}/>
                </div>
              )
            }

            {/*
            {
              (view === 'day' || !clientID) && (
                (numDaysDisplayed === 0 || numDaysDisplayed === '0') ? (
                  <div>
                    <EntriesDay session={session} day={endDate} timezone={timezone} decrementDate={decrementDate} incrementDate={incrementDate} today={today} entries={entries} setEntries={setEntries}/>
                  </div>
                ) : (
                  
                  <div>
                    <EntriesCal session={session} startDay={startDate} endDay={endDate} client={null} timezone={timezone} setView={setView} setStartDate={setStartDate} setEndDate={setEndDate} setNumDaysDisplayed={setNumDaysDisplayed} entries={entries} setEntries={setEntries}/>
                  </div>
                  
                )
              )
            }
            {(view === 'client' && clientID) && (
                    (() => {
                    // find the client object with the clientID
                    let client = clients.find(client => String(client.uuid) === String(clientID));
                    return (
                        <div>
                        <EntriesCal session={session} startDay={startDate} endDay={endDate} client={client} timezone={timezone} setView={setView} setStartDate={setStartDate} setEndDate={setEndDate} setNumDaysDisplayed={setNumDaysDisplayed} entries={entries} setEntries={setEntries}/>
                        </div>
                    );
                    })()
                )
            }*/}
        </div>
    );
}