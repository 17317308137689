import '../styles/style.css';
import { supabase } from '../supabaseClient'
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../styles/Settings.css';
import '../styles/style.css';
import queryClio from '../functions/clio/queryClio';
import getLawcusClientsMatters from '../functions/lawcus/getClientsMatters';
import { isDate } from 'moment-timezone';
import BlockSelect from './BlockSelect';
import { CustomSelect } from './customSelect.js';
import devLog from '../functions/devLog';

function isValidEmail(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

function EditClients({ billingSoftware, clients, setClients, defaultBlock, setDefaultBlock, saveClientsButton=true, signedInWithClio=true }) {
    const [matters, setMatters] = useState([]);
    const [clientsDict, setClientsDict] = useState({});
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [unassignedMatters, setUnassignedMatters] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const block_value_to_display = {
        '1': '1 entry per day',
        '2': '1 entry for consecutive tasks',
        '3': 'Entry for each task'
    }

    const fetchSession = async () => {
        const session = (await supabase.auth.getSession()).data.session;
        setSession(session);
    };
    
    async function fetchClients() {
        if (billingSoftware === 'lawcus') {
            await getLawcusClientsMatters();
        } else if (billingSoftware === 'clio') {
            await queryClio({force: true});
        }
        const session = (await supabase.auth.getSession()).data.session;
        const { data: fetchedClients, error } = await supabase
          .from('clients')
          .select('*')
          .eq('profile_id', session.user.id);

        if (error) {
          console.error('Error fetching clients:', error);
          return;
        }
        
        setClients(fetchedClients);

        let newClientsDict = {};
        for (const client of fetchedClients) {
          newClientsDict[client.uuid] = client.name;
        }
        setClientsDict(newClientsDict);
        devLog("Fetched clients")
        devLog(clients)
    };

    async function fetchMatters() {
        const session = (await supabase.auth.getSession()).data.session;
        const { data: fetchedMatters, error } = await supabase
          .from('matters')
          .select('*')
          .eq('profile_id', session.user.id);
        if (error) {
          console.error('Error fetching matters:', error);
          return;
        }

        // create a dictionary that maps each matter's uuid to the matter
        const mattersDict = {};
        fetchedMatters.forEach(matter => {
          mattersDict[matter.uuid] = matter;
        });
        setMatters(mattersDict);
        const newUnassignedMatters = Object.values(mattersDict).filter(matter => matter.client_uuid === null && matter.deleted !== true);
        //console.log("Unassigned Matters: ", newUnassignedMatters);
        setUnassignedMatters(newUnassignedMatters);
    };
    
    useEffect(() => {
        const fetchData = async () => { // this ensures setLoading(false) happens after all fetches
            devLog("Fetch in EditClients");
            await fetchSession();
            await fetchClients();
            await fetchMatters();

            setLoading(false);
            devLog("Clients");
            devLog(clients);
        };

        fetchData();
    }, [signedInWithClio]);

    const handleClientChange = (index, field, value, isDefault = false, setAll = false) => {
        let updatedClients = [...clients];
        if (isDefault) {
            setDefaultBlock(value);
            if (setAll) {
                for (let i = 0; i < updatedClients.length; i++) {
                    updatedClients[i] = { ...updatedClients[i], [field]: value };
                }
            }
        } else {
            updatedClients[index] = { ...updatedClients[index], [field]: value };
        }
        setClients(updatedClients);
        setSaveEnabled(true);
    };

    const saveClients = async () => {
        /*let done = true;
    
        for (const client of clients) {
          if (client.email === '' || client.email === null) {
            done = false;
          }
        }
        if (!done) {
            alert('Please fill out all red client fields.');
            return;
        }
        for (const client of clients) {
          if (!isValidEmail(client.email)) {
            alert(`Please enter a valid email client: ${client.name}`);
            return;
          }
        }*/

        for (const client of clients) { // update all clients in supabase
          let { error } = await supabase
            .from('clients')
            .update(client)
            .eq('profile_id', session.user.id)
            .eq('uuid', client.uuid);
          if (error) {
            devLog('Error updating client:', error, client);
            return;
          }
        }
        // update all matters in supabase
        for (const matter of Object.values(matters)) {
          let { error } = await supabase
            .from('matters')
            .update(matter)
            .eq('profile_id', session.user.id)
            .eq('uuid', matter.uuid);
          if (error) {
            devLog('Error updating matter:', error, matter);
            return;
          }
        }
        //update defaultBlock in the default_client_settings table in supabase
        let { data1, error1 } = await supabase
          .from('profiles')
          .update({ default_block: defaultBlock })
          .eq('profile_id', session.user.id);
        if (error1) {
          devLog('Error updating default block:', error1);
          return;
        }
    
        devLog('Clients updated:', clients);
        devLog('Default block updated:', defaultBlock);
        setSaveEnabled(false);
    };
    
    const setMatterClient = async (index, matter, client_uuid) => {
        setSaveEnabled(true);
        const updatedMatter = { ...matter, 'client_uuid': client_uuid };

        // update clients
        let updatedClients = [...clients];
        // Find the client that currently has the matter and remove the matter from that client's matters array
        updatedClients = updatedClients.map(client => {
          if (client.matters.includes(matter.uuid)) {
              return {
                  ...client,
                  matters: client.matters.filter(matterId => matterId !== matter.uuid)
              };
          }
          return client;
        });
        // add it to the new client's matters array
        const clientIndex = updatedClients.findIndex(client => client.uuid === client_uuid);
        if (clientIndex !== -1) {
            updatedClients[clientIndex] = { ...updatedClients[clientIndex], matters: [...updatedClients[clientIndex].matters, matter.uuid] };
        }

        setClients(updatedClients);

        // update matters
        setMatters({ ...matters, [matter.uuid]: updatedMatter });

        let updatedUnassignedMatters = [...unassignedMatters];
        updatedUnassignedMatters[index] = { ...updatedUnassignedMatters[index], 'client_uuid': client_uuid};
        setUnassignedMatters(updatedUnassignedMatters);
    };

    // Function to delete a matter
    const deleteMatter = async (uuid, index) => {
      if (!window.confirm('Are you sure you want to delete this matter?'))
          return;
      try {
          const { data, error } = await supabase
              .from('matters')
              .update({ deleted: true })
              .eq('uuid', uuid)
              .eq('profile_id', session.user.id);
          if (error) {
              console.error('Error deleting matter:', error);
              return;
          }
          // Remove the matter from the state
          setUnassignedMatters((prevMatters) => prevMatters.filter((_, i) => i !== index));
          // remove it from any client's matters array
          let updatedClients = [...clients];
          updatedClients = updatedClients.map(client => {
              return {
                  ...client,
                  matters: client.matters.filter(matterId => matterId !== uuid)
              };
          });
          setClients(updatedClients);
      } catch (error) {
          console.error('Error deleting matter:', error);
      }
    };
    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };

    if (loading) {
        return (
          <div>
            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
              <h1>Clients</h1>
            </div>
            <h3>Loading...</h3>
          </div>
        )
    }

    //console.log("Matters: ", matters);
    //console.log("Unassigned Matters: ", unassignedMatters);

    return (
        <div>
            {/*<h2 className='error-text'> Fill out all red fields before continuing.</h2>*/}

            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
              <h1>Clients</h1>
              <button className="button white" onClick={saveClients} disabled={!saveEnabled} >Save Clients</button>
              <button onClick={toggleCollapse} className="button white square">
                    {isCollapsed ? '\u25B6' : '\u25BC'}
              </button>
            </div>

            <div className={`collapsible-content ${isCollapsed ? 'collapsed' : 'expanded'}`}>
              <h3> Default Client Settings </h3>
              <p> These settings will be applied whenever a new client is created. You may modify individual client settings.</p>
              <div style={{display: 'flex', alignItems: 'flex-end'}}>
              <label style={{margin:'0px'}}>
                  Block Billing:
                  <CustomSelect myVar={defaultBlock} setVar={(value) => handleClientChange(0, 'block', value, true)} value_to_display ={block_value_to_display} defaultValue={"1"} defaultDisplay={block_value_to_display["1"]} width={'300px'}/>
              </label>
              <button className="button white" onClick={() => handleClientChange(0, 'block', defaultBlock, true, true)}>Apply to all clients</button>
              {/*<BlockSelect
                  blockValue={defaultBlock}
                  onChange={(e) => handleClientChange(0, 'block', e.target.value, true)}
              />*/}
            </div>
            
            <table>
            <tbody>
            {clients.map((client, index) => (
              <React.Fragment key={index}>
                <tr key={index}>
                <td>
                  <h3>{client.name}</h3>
                </td>
                <td>
                  <div className="input-group">
                    <div><label>Email:</label></div>
                    <input
                        type="text"
                        value={client.email || ''}
                        onChange={(e) => handleClientChange(index, 'email', e.target.value)}
                        placeholder="Email"
                        className={client.email ? 'input-normal' : 'input-error'}
                    />
                  </div>
                </td>
                <td>
                    <div className="input-group">
                        <div><label>Phone Number:</label></div>
                        <input
                            type="text"
                            value={client.phone || ''}
                            onChange={(e) => handleClientChange(index, 'phone', e.target.value)}
                            placeholder="Phone"
                            //className={client.phone ? 'input-normal' : 'input-error'}
                            className={'input-normal'}
                        />
                    </div>
                </td>
                <td>
                    <div className="input-group">
                        <div><label>Block Billing:</label></div>
                        
                        <CustomSelect myVar={client.block} setVar={(value) => handleClientChange(index, 'block', value)} value_to_display ={block_value_to_display} defaultValue={defaultBlock} defaultDisplay={block_value_to_display[defaultBlock]} width={'300px'}/>
                        
                    </div>
                </td>
              </tr>
              <tr>
              <td colSpan="4">
                {client.matters.map((matter_uuid, matterIndex) => (
                    <label key={matter_uuid} style={{ marginLeft: '10px', color: 'gray' }}>
                        &#x2514; {matters[matter_uuid] ? matters[matter_uuid].description : 'Loading...'}
                    </label>
                ))}
                </td>
              </tr>
              </React.Fragment>

                
            ))}
          </tbody>
        </table>
        </div>

        {unassignedMatters.length > 0 && (<>
          <h3> Unassigned Matters </h3>
          <p> You may assign these matters to a client or delete them.</p>
          <table>
            <tbody>
              {unassignedMatters.map((matter, index) => (
                <tr key={index} style={{height: 'auto'}}>
                  <td style={{width: '1%', padding: '0px'}}>
                    <button className="delete-button" onClick={() => deleteMatter(matter.uuid, index)}>
                      <i className="fas fa-times"></i>
                    </button>
                  </td>
                  <td>
                    <h3 style={{margin: '0px'}}>{matter.description}</h3>
                  </td>
                  <td>
                    <CustomSelect myVar={matter.client_uuid} setVar={(value) => setMatterClient(index, matter, value)} value_to_display ={clientsDict} defaultValue={''} defaultDisplay={'None'} width={'300px'}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </>
        )}

        </div>
    );
}

export default EditClients;