import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient'
import { useEffect } from 'react';
import Navbar from './../components/Navbar';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import '../styles/style.css';
import devLog from '../functions/devLog';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

export default function Billing() {
    const [loading, setLoading] = useState(true);
    const [timezone, setTimezone] = useState('Eastern Standard Time');
    const [clients, setClients] = useState([]);
    const [matters, setMatters] = useState([]);
    const [session, setSession] = useState(null);
    const [editingClientIndex, setEditingClientIndex] = useState(null);
    const [editingMatterIndex, setEditingMatterIndex] = useState(null);

    const fetch = async () => {
        const session = (await supabase.auth.getSession()).data.session;
        setSession(session);

        const { data, error } = await supabase
        .from('profiles')
        .select('timezone')
        .eq('profile_id', session.user.id);

        if (error) {
            alert('Failed to get timezone: ' + error.message);
        } else {
            setTimezone(data[0].timezone);
        }
    };

    async function fetchClients() {
        const session = (await supabase.auth.getSession()).data.session;
        const { data: clients, error } = await supabase
        .from('clients')
        .select('*')
        .eq('profile_id', session.user.id);

        if (error) {
        console.error('Error fetching clients:', error);
        return;
        }

        const clientsWithSettings = clients.map(client => ({
        ...client,
        selected: false,
        }));
        devLog("Clients")
        devLog(clientsWithSettings)

        setClients(clientsWithSettings);
    }
    async function fetchMatters() {
        const session = (await supabase.auth.getSession()).data.session;
        const { data: fetchedMatters, error } = await supabase
        .from('matters')
        .select('*')
        .eq('profile_id', session.user.id);
        if (error) {
        console.error('Error fetching matters:', error);
        return;
        }

        // create a dictionary that maps each matter's uuid to the matter
        const mattersDict = {};
        fetchedMatters.forEach(matter => {
            matter.selected = false;
            mattersDict[matter.uuid] = matter;
        });

        setMatters(mattersDict);
        devLog(mattersDict)
    };

    useEffect(() => {
        devLog("Matters changed")
        devLog(matters);
    }, [matters]);
    useEffect(() => {
        fetch();
        fetchClients();
        fetchMatters();

        setLoading(false);
    }, []);

    clients.map((client) => {
        client.matters.map((matter_id) => {
            if (!matters[matter_id]) {
                devLog('Undefined matter_id:', matter_id);
                devLog(client);
            }
        });
    });

    devLog("Test")
    devLog(clients)
    devLog(matters)
    devLog(matters["b6672483-cf62-4067-95c3-e6e13538aaf1"])

    if (loading) {
        return <div>Loading...</div>;
    }

    devLog("Clients in billing")
    devLog(clients)

    return (
        <div style={{width: '100%'}}>
            {/*<Navbar />*/}
            <h1 style={{textAlign: 'center'}}>Billing</h1>

        {/*
        <Link to="/new_bill">
            <button className="button white">New Bill</button>
        </Link>*/}

        <Link to={{
            pathname: '/new_bill',
            state: {
                session: session,
                setSession: setSession,
                clients: clients,
                setClients: setClients,
                matters: matters,
                setMatters: setMatters,
                timezone: timezone,
            }
        }}>
            <button className="button white">New Bill</button>
        </Link>

        </div>
    );
}