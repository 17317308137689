import { supabase } from '../../supabaseClient';
import CryptoJS from 'crypto-js';
import generateCsrfToken from '../generateCsrfToken';
import devLog from '../devLog';

export default async function signInWithZoom({ session = null}) {
    if (!session) {
      session = (await supabase.auth.getSession()).data.session;
    } 
    const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
    const deployed = process.env.REACT_APP_DEPLOYED_BOOLEAN;

    let redirectUri = deployed === 'true' 
      ? `${process.env.REACT_APP_PRODUCTION_URL}/api/zoom/auth/v1/callback` 
      : `http://localhost:${process.env.REACT_APP_SERVER_PORT || 3000}/api/zoom/auth/v1/callback`;
  
      //const scopes = 'meeting:read:list_registrants meeting:read:past_meeting meeting:read:list_meetings meeting:read:list_past_instances meeting:read:list_past_participants';
      const CSRF_PROTECTION_STRING = generateCsrfToken(); // to prevent CSRF attacks
      // store the protection string in supabase
      if (session && session.user && session.user.id) {
        const { error } = await supabase
          .from('integrations')
          .update({ csrf_token: CSRF_PROTECTION_STRING })
          .eq('profile_id', session.user.id);
        if (error) {
          console.error('Error updating CSRF token in Supabase:', error);
          return;
        }
      } else {
        return;
      }

      const secretKey = process.env.REACT_APP_ZOOM_ENCRYPTION_KEY;
      const encryptedUserId = CryptoJS.AES.encrypt(session.user.id, secretKey).toString();
      devLog("Encrypted User ID:", encryptedUserId)
      const stateWithAdditionalData = `${CSRF_PROTECTION_STRING}-${encryptedUserId}`;
      
      devLog("CSRF_PROTECTION_STRING:", CSRF_PROTECTION_STRING);
      devLog("Session:", session);
      devLog("ClientId:", clientId);
      devLog("RedirectUri:", redirectUri);

      let params = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
        redirect_uri: redirectUri,
        state: stateWithAdditionalData,
        redirect_on_decline: true,
      }); // scopes are not necessary because Zoom adds all the scopes I added to the portal
      //https://zoom.us/oauth/authorize?response_type=code&client_id=rh3_r5DWTV69eiwtktqS9g&redirect_uri=https://legalpulse-dev.vercel.app/api/zoom/auth/v1/callback
      //https://zoom.us/oauth/authorize?response_type=code&client_id=rh3_r5DWTV69eiwtktqS9g&redirect_uri=https%3A%2F%2Flegalpulse-dev.vercel.app%2Fapi%2Fzoom%2Fauth%2Fv1%2Fcallback&scope=meeting%3Aread%3Alist_registrants+meeting%3Aread%3Apast_meeting+meeting%3Aread%3Alist_meetings+meeting%3Aread%3Alist_past_instances+meeting%3Aread%3Alist_past_participants&state=d61e381b7e3112511e4118b7177f48c7bcc6305e0dc0b85134e2799541c872ae-U2FsdGVkX1%2FMHBBuI%2F3Hm4oVJGVJF6H7VQiMAFHoQlU5UDgYDf3kF97DKkDjl6qZO%2FqwLlgzBH2z7Qd%2BVbw%2FOw%3D%3D&redirect_on_decline=true
      const zoomAuthUrl = `https://zoom.us/oauth/authorize?${params.toString()}`;
      devLog(zoomAuthUrl);

      window.location.href = zoomAuthUrl;
};