//import { version } from 'react';
import { supabase } from '../../supabaseClient.js'
import CryptoJS from 'crypto-js';
import tokenExpired from './tokenExpired.js';
//import { min } from 'moment-timezone';
import fetchClientsMatters from '../fetchClientsMatters.js';
import devLog from '../devLog.js';
import { encrypt, decrypt } from '../encryption';

// function to calculate the number of minutes between 2 times
function mins_since(time1) {
    return Math.floor((new Date() - new Date(time1)) / 60000);
} function mins_between(time1, time2) {
    return Math.floor((new Date(time2) - new Date(time1)) / 60000);
} function seconds_between(time1, time2) {
    return Math.floor((new Date(time2) - new Date(time1)) / 1000);
}
const time_mark_inactive = 20; // minutes. If there is a gap of this many minutes between versions, consider the user inactive
const time_split_entry = 60; // minutes. If there is a gap this big between versions, create a new entry

export async function getDocs({ timezone }) {
    
    //devLog("Called getDocs")
    
    try {

        let session = await supabase.auth.getSession();
        session = session.data.session;

        const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('last_updated_docs, provider_token, email')
            .eq('profile_id', session.user.id);

        if (profileError) {
            console.error('Error fetching last_updated:', profileError);
            return;
        }
        const { data: descData, error: descError } = await supabase
            .from('custom_descriptions')
            .select('*')
            .eq('profile_id', session.user.id)
            .eq('type', 'document');
        if (descError) {
            console.error('Error fetching description data:', descError);
            return;
        }
        let text1;
        if (!descData || !descData.length || !descData[0].text1) text1 = 'Edited'
        else text1 = descData[0].text1;
        let text2;
        if (!descData || !descData.length || !descData[0].text2) text2 = ''
        else text2 = descData[0].text2;

        // if the last_updated column is null, set it to yesterday
        const last_updated = new Date(profileData[0].last_updated_docs) || new Date(new Date().setDate(new Date().getDate() - 1));
        
        const deployed = process.env.REACT_APP_DEPLOYED_BOOLEAN === 'true';
        const mins_to_wait = deployed ? 6 : 1; // 6 minutes if deployed, 1 minute if testing
        if (mins_since(last_updated) < mins_to_wait) {
            //console.log("Just updated")
            return;
        }
        //console.log("Getting Docs")

        const {clients, matters} = await fetchClientsMatters(null, null, supabase, session);
        
        //const key = process.env.AZURE_ENCRYPTION_KEY;
        //const providerToken = CryptoJS.AES.decrypt(profileData[0].provider_token, key).toString(CryptoJS.enc.Utf8);
        const providerToken = await decrypt(profileData[0].provider_token, 'azure');

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer' + providerToken,
                'Content-Type': 'application/json'
            },
        };

        let files;
        try {
            const wordFiles_raw = await fetch('https://graph.microsoft.com/v1.0/me/drive/recent', requestOptions);
            if (wordFiles_raw.status === 401) {
                // Redirect to the Auth page
                tokenExpired();
                return;
            }
            if (wordFiles_raw.error) {
                console.error('Error fetching word files:', wordFiles_raw.error);
                return;
            }
            
            files = await wordFiles_raw.json();
            if (!files) {
                console.error("No files found")
                return;
            }
        } catch (error) {
            console.error("Error fetching word files:", error);
        }

        // captures Microsoft Word, Excel, and PowerPoint files
        const wordFiles = files.value.filter(file => 
            (file.name.endsWith('.docx') || file.name.endsWith('.doc') || // Word
            file.name.endsWith('.xlsx') || file.name.endsWith('.xls') || // Excel
            file.name.endsWith('.pptx') || file.name.endsWith('.ppt')) // PowerPoint
        );
        //const wordFiles = files.value.filter(file => (file.name.endsWith('.docx') || file.name.endsWith('.doc')) && file.lastModifiedDateTime >= lastUpdatedISO);
        //devLog("Word Files")
        //devLog(files.value);
        //console.log(wordFiles);

        for (const file of wordFiles) {
            //devLog("File")
            //devLog(file)
            const id = file.id;
            //console.log("File name")
            //console.log(file.name)
            
            const response = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${id}`, requestOptions);
            if (response.status === 404) {
                console.error(`Error fetching item details: Item with ID ${id} not found.`);
                // Handle the 404 error gracefully, e.g., skip processing this item
                continue;
            }
            const itemJson = await response.json();

            // Log the parent directory information
            //console.log("Parent directory ID:", itemJson.parentReference.id);
            //console.log("Parent directory path:", itemJson.parentReference.path);

            let versions;
            try {
                const versions_raw = await fetch(`https://graph.microsoft.com/v1.0/me/drive/items/${id}/versions`, requestOptions);
                versions = await versions_raw.json();
                if (versions.error) {
                    if (versions.error.code === 'itemNotFound') {
                        console.error(`Error fetching versions: Item with ID ${id} not found.`);
                    } else {
                        console.error('Error fetching versions:', versions.error);
                    }
                    continue;
                }
            } catch (error) {
                console.error ("Error getting versions: " + error);
            }
            
            // make sure it was this user that modified the file
            // get version_times in to the format that supabase stores them in
            let version_times = versions.value
                .filter(version => version.lastModifiedBy.user.email === profileData[0].email)
                .map(version => 
                    version.lastModifiedDateTime.replace('Z', '+00:00')
            );
            //console.log("Version Times:")
            //console.log(version_times)

            // we only want the versions after we last updated
            const lastUpdatedTime = new Date(last_updated);
            //console.log("Last Updated Time")
            //console.log(lastUpdatedTime)
            version_times = version_times.filter(time => {
                const version_time = new Date(time);
                //console.log("Version Time: ", version_time)
                return version_time >= lastUpdatedTime;
            });

            //console.log("Version Times Filtered by Time")
            //console.log(version_times);

            if (version_times.length === 0) { // no changes since last update
                continue;
            }
            // sort in ascending order
            version_times = version_times.sort((a, b) => new Date(a) - new Date(b));

            // get the supabase entries in docs with doc_id = file.id
            const { data: docData, error: docError } = await supabase
                .from('docs')
                .select('*')
                .eq('doc_id', file.id)
                .eq('profile_id', session.user.id)
                .order('start_time', {ascending: true});
            if (docError) {
                console.error('Error fetching doc:', file.id, docError);
                continue;
            }
            // we only want the most recent entry- this may need to be updated
            const last_doc_entry = docData.length > 0 ? docData[docData.length - 1] : null;
            let last_entry = null;

            if (last_doc_entry) {
                // get this entry from block_entries
                let oldBlockEntries = await supabase
                    .from('block_entries')
                    .select('*')
                    .eq('profile_id', session.user.id)
                    .eq('uuid', last_doc_entry.uuid)
                if (oldBlockEntries.error) {
                    console.error('Error fetching block entries:', oldBlockEntries.error);
                    return;
                }
                if (oldBlockEntries.data.length > 0) {
                    last_entry = oldBlockEntries.data[0];
                    last_entry.end_time = last_doc_entry.end_time; // we'll need this for later processing
                }
            }

            // get the entries to insert and update
            const return_val = getTimeSpent(itemJson, version_times, last_entry, timezone, session, clients, matters);
            const entry_to_update = return_val.entry_to_update;
            //console.log("Entries Returned")
            //console.log(return_val.entries);
            //console.log("Entry to Update")
            //console.log(entry_to_update);
            const new_entries = return_val.entries;
            if (new_entries.length === 0) {
                continue;
            }

            // check if this id in block_entries has been billed
            if (entry_to_update && entry_to_update.billed) {
                // this entry has been billed and can't be updated
                continue;
            }

            if (entry_to_update) { // update this entry in docs, entries, and block_entries
                let updated_doc = {};
                updated_doc.uuid = entry_to_update.uuid;
                updated_doc.profile_id = entry_to_update.profile_id; // must be here b/c row level security only allows updates if the profile_id column matches the user's supabase id
                updated_doc.minutes = new_entries[0].minutes;
                updated_doc.end_time = new_entries[0].end_time;
                updated_doc.inactive_time = new_entries[0].inactive_time;
                updated_doc.inactive_intervals = new_entries[0].inactive_intervals;
                updated_doc.title = new_entries[0].title;

                //console.log("Updated Doc")
                //console.log(updated_doc);

                let { error: updateError } = await supabase
                    .from('docs')
                    .update([updated_doc])
                    .match({ 'uuid': entry_to_update.uuid, 'profile_id': session.user.id });
                if (updateError) {
                    console.error('Error updating doc:', updated_doc, updateError);
                    return;
                }
                // update in supabase block entries
                let newBlockEntry = {
                    uuid: entry_to_update.uuid,
                    profile_id: entry_to_update.profile_id, // must be here b/c row level security only allows updates if the profile_id column matches the user's supabase id
                    time_taken: Math.max(Math.round(updated_doc.minutes / 6), 1),
                    client: new_entries[0].client,
                    matter: new_entries[0].matter,
                    client_num: new_entries[0].client_num,
                    matter_num: new_entries[0].matter_num,
                    need_to_verify_client: new_entries[0].need_to_verify_client,
                    inactive_time: new_entries[0].inactive_time,
                    inactive_intervals: new_entries[0].inactive_intervals,
                };
                //console.log("New Block Entry")
                //console.log(newBlockEntry);
                let { error: upsertError } = await supabase
                    .from('block_entries')
                    .update([newBlockEntry])
                    .match({ 'uuid': entry_to_update.uuid, 'profile_id': session.user.id });
                if (upsertError) {
                    console.error('Error upserting block entry:', newBlockEntry, upsertError);
                    return;
                }

                // update in supabase entries
                // delete columns that are not in this table
                const { inactive_time, inactive_intervals, need_to_verify_client, ...newEntry } = newBlockEntry;

                let { error: upsertError2 } = await supabase
                    .from('entries')
                    .update([newEntry])
                    .match({ 'uuid': entry_to_update.uuid, 'profile_id': session.user.id });
                if (upsertError2) {
                    console.error('Error upserting entry:', newEntry, upsertError2);
                    return;
                }
                // delete newentries[0]
                new_entries.shift();
            }

            let entries_to_insert = new_entries;

            //console.log("Entries to Insert")
            //console.log(entries_to_insert);

            // add the new entries to supabase docs
            for (const entry of entries_to_insert) {
                const { client, matter, client_num, matter_num, need_to_verify_client, ...docEntry } = entry;

                let { error: insertError } = await supabase
                    .from('docs')
                    .insert([docEntry]);
                if (insertError) {
                    console.error('Error inserting doc:', docEntry, insertError);
                    return;
                }

                let newBlockEntry = {
                    uuid: entry.uuid,
                    profile_id: entry.profile_id,
                    date: entry.date,
                    type: "doc",
                    description: `${text1} ` + entry.title,
                    time_taken: Math.max(Math.round(entry.minutes / 6), 1),
                    time: entry.start_time,
                    activity: "Draft",
                    entries: [entry.uuid],
                    client: entry.client,
                    matter: entry.matter,
                    client_num: entry.client_num,
                    matter_num: entry.matter_num,
                    need_to_verify_client: entry.need_to_verify_client,
                    inactive_time: entry.inactive_time,
                    inactive_intervals: entry.inactive_intervals,
                };

                //console.log("Block Entry")
                //console.log(newBlockEntry);
                let { error: upsertError } = await supabase
                    .from('block_entries')
                    .insert([
                        newBlockEntry
                    ]);
                if (upsertError) {
                    console.error('Error inserting entry:', newBlockEntry, upsertError);
                    return;
                }

                let newEntry = {
                    uuid: entry.uuid,
                    profile_id: entry.profile_id,
                    date: entry.date,
                    type: "doc",
                    description: `${text1} ${entry.title}${text2 !== '' ? ' ' + text2 : ''}`,
                    time_taken: Math.round(entry.minutes / 6),
                    time: entry.start_time,
                    activity: "Draft",
                    client: entry.client,
                    matter: entry.matter,
                    client_num: entry.client_num,
                    matter_num: entry.matter_num,
                };

                let { error: upsertError2 } = await supabase
                    .from('entries')
                    .insert([
                        newEntry
                    ]);
            
                if (upsertError2) {
                    console.error('Error inserting entry:', newEntry, upsertError2);
                    return;
                }
            }

            devLog("Inserted doc entries everywhere")
        }

        // only update the last_updated column for this user if we make it to the end of the funciton with no errors.
        let { error: updateError } = await supabase
            .from('profiles')
            .update({ last_updated_docs: new Date() }) // set the last_updated column to the current date and time
            .eq('profile_id', session.user.id); // where the id is the current user's id

        if (updateError) {
            console.error('Error updating last_updated:', updateError);
        }
    } catch (error) {
        console.error('Error in getDocs:', error);
    }
}

function getTimeSpent(itemJson, version_times, last_entry, timezone, session, clients, matters) {

    // version_times: all the version times of this document from startDay onwards
    // old_entries: all the entries in the docs table for this doc from startDay onwards

    let need_to_verify_client = true;
    if (last_entry) need_to_verify_client = last_entry.need_to_verify_client;

    let client_num = null;
    let client_name = null;
    let matter_num = null;
    let matter_name = null;
    //console.log("Last Entry")
    //console.log(last_entry)
    //console.log(need_to_verify_client)
    if (need_to_verify_client || !last_entry.client || !last_entry.matter) {
        const { client, matter } = getClientAndMatter(itemJson, clients, matters);
        client_num = client ? client.uuid : null;
        client_name = client ? client.name : null;
        matter_num = matter;
        matter_name = matter ? matters[matter].description : null;
    } else {
        client_num = last_entry.client_num;
        client_name = last_entry.client
        matter_num = last_entry.matter_num;
        matter_name = last_entry.matter;
    }
    if (client_num === null) {
        need_to_verify_client = false;
        matter_num = null;
        matter_name = null;
    }

    // now we only need to change the last one
    let startTime = version_times[0];
    let entry_to_update = null;
    let inactive_intervals = []; // array of arrays in the form [start_time, end_time], both are timestampz
    let seconds_taken = 0; // putting this in seconds to be more accurate
    let prev_time = version_times[0];
    if (last_entry && mins_between(last_entry.end_time, version_times[0]) < time_split_entry) { // the most recent entry will have to be extended.

        startTime = last_entry.time;
        // time_taken is the # of 6 minute intervals
        seconds_taken = last_entry.time_taken * 6 * 60; // convert to seconds
        if (seconds_taken === 6 * 60) { // this may have been a default minimum value
            seconds_taken = seconds_between(startTime, last_entry.end_time);}
        if (last_entry.inactive_intervals) {
            inactive_intervals = last_entry.inactive_intervals;
        }
        prev_time = last_entry.end_time;
        entry_to_update = last_entry;
    }
    let entries = [];

    /*console.log("Initial Values")
    console.log(startTime)
    console.log(entry_to_update)
    console.log(inactive_intervals)
    console.log(seconds_taken / 60)*/
    
    for (let i = 0; i < version_times.length; i++) {
        seconds_taken += seconds_between(prev_time, version_times[i]);

        if (mins_between(prev_time, version_times[i]) > time_mark_inactive) { // user was inactive from prev_time to version_times[i]

            // if the last inactive interval ended right before prev_time, extend it
            if (inactive_intervals.length > 0 && mins_between(inactive_intervals[inactive_intervals.length-1][1], prev_time) < 6) {
                inactive_intervals[inactive_intervals.length-1][1] = version_times[i];
            } else { // otherwise, just add a new inactive interval
                inactive_intervals.push([prev_time, version_times[i]]);
            }
        }
        prev_time = version_times[i];

        if (i === version_times.length - 1 || mins_between(version_times[i], version_times[i+1]) > time_split_entry) {
            let inactive_mins = 0;
            for (let interval of inactive_intervals) {
                inactive_mins += mins_between(interval[0], interval[1]);
            }
            const inactive_intervals_copy = [...inactive_intervals]; // to avoid reference issues
            const date = new Date(version_times[i]).toLocaleString('en-US', { timeZone: timezone }).split(',')[0];
            const minutes_taken = Math.round(seconds_taken / 60);
            let entry_to_push = {
                uuid: itemJson.id + " " + startTime + " doc",
                start_time: startTime,
                end_time: version_times[i],
                minutes: Math.max(minutes_taken - inactive_mins, 6),
                title: itemJson.name.substring(0, itemJson.name.lastIndexOf('.')), // everything before the last '.' to remove the file extension
                date: date,
                profile_id: session.user.id,
                doc_id: itemJson.id,
                client: client_name,
                matter: matter_name,
                client_num: client_num,
                matter_num: matter_num,
                need_to_verify_client: need_to_verify_client,
                inactive_time: Math.round(inactive_mins / 6) > 0 ? Math.round(inactive_mins / 6) : null,
                inactive_intervals: inactive_intervals_copy.length > 0 ? inactive_intervals_copy : null, // make it null if there are no inactive intervals
            }
            //console.log("Entry to Push")
            //console.log(entry_to_push)
            //entries.push(entry_to_push);
            entries.push(JSON.parse(JSON.stringify(entry_to_push))); // deep copy
            //console.log("Entries")
            //console.log(entries)
            seconds_taken = 0;
            if (i !== version_times.length - 1) {
                startTime = version_times[i+1]; // reset start time for the next entry
                inactive_intervals = []; // reset inactive intervals
            }
        }
    }

    return {entry_to_update: entry_to_update, entries: entries};
}

function getClientAndMatter(itemJson, clients, matters) {
    if (!clients || clients.length === 0) {
        return {client: null, matter: null};
    }
    //console.log("Called getClientAndMatter")
    //console.log(itemJson, clients, matters)
    if (!clients || clients.length === 0) {
        return {client: null, matter: null};
    }

    // first check the file path
    // if the file path contains a client name, return that client
    // if the file path contains a matter name, return that matter
    //console.log("Parent directory ID:", itemJson.parentReference.id);
    //console.log("Parent directory path:", itemJson.parentReference.path);
    let path = itemJson.parentReference.path;
    let folders = path.split('/');

    let clientMatches = clients.map(client => ({...client, matches: 0}));
    let matterMatches = Object.keys(matters).reduce((acc, matterId) => {
        acc[matterId] = { matches: 0 };
        return acc;
    }, {});
    for (let client of clients) {
        for (let matter_uuid of client.matters) {
            matters[matter_uuid].client = client;
        }
    }

    //console.log("Folders")
    //console.log(folders)
    
    for (let folder of folders) {
        //console.log("Folder")
        //console.log(folder)
        if (folder === "") {
            continue;
        }
        for (let client of clients) {
            let name = client.name.toLowerCase();
            folder = folder.toLowerCase();
            // if the folder name contains the client name
            if (folder.includes(name) || folder.includes(client.uuid)) {
                //console.log("Client found")
                clientMatches[clients.indexOf(client)].matches += 1;
            }
            for (let matter_uuid of client.matters) {
                let matter = matters[matter_uuid];
                let matter_name = matter.description.toLowerCase();
                if (folder.includes(matter_uuid) || folder.includes(matter_name)) {
                    //console.log("Matter found")
                    matterMatches[matter_uuid].matches += 1;
                    client = matter.client;
                    clientMatches[clients.indexOf(client)].matches += 1;
                }
            }
        }
    }
    /*console.log("Client Matches")
    console.log(clientMatches)
    console.log("Matter Matches")
    console.log(matterMatches)

    console.log("clients")
    console.log(clients)
    console.log("matters")
    console.log(matters)*/

    const title = itemJson.name.substring(0, itemJson.name.lastIndexOf('.')); // everything before the last '.' to remove the file extension
    let title_words = (title.match(/\b[\w']+\b/g) || []).map(word => word.toLowerCase());
    //console.log("Title Words:")
    //console.log(title_words)

    for (let client of clients) {
        const split = client.name.split(' ');
        let first_name = split[0].toLowerCase();
        let last_name = split[split.length-1].toLowerCase();
        //console.log("First and Last Name")
        //console.log(first_name)
        //console.log(last_name)
        // see if name matches
        if (title_words.includes(first_name)) {
            clientMatches[clients.indexOf(client)].matches += 1;
        }
        if (title_words.includes(last_name)) {
            clientMatches[clients.indexOf(client)].matches += 1;
        }
        for (let matter_uuid of client.matters) {
            let matter = matters[matter_uuid];
            let matter_words = (matter.description.match(/\b[\w']+\b/g) || []);
            matter_words = matter_words.map(word => word.toLowerCase());
            for (let word of title_words) {
                if (matter_words.includes(word)) {
                    clientMatches[clients.indexOf(client)].matches += 1;
                }
            }
        }
    }
    //console.log("Client Matches:")
    //console.log(clientMatches)

    // sort the candidate clients
    // if there are multiple clients, return the one with the most matches
    // if there's a tie, return null
    clientMatches.sort((a, b) => b.matches - a.matches);

    // create a set of candidate clients
    let candidate_clients = clientMatches.filter(client => client.matches === clientMatches[0].matches);
    //console.log("Candidate Clients")
    //console.log(candidate_clients)

    if (candidate_clients.length === 1) {
        let client = candidate_clients[0];
        if (client.matters.length === 0) {
            return {client: client, matter: null};
        }
        let candidate_matters = client.matters.map(matter_uuid => ({uuid: matter_uuid, matches: matterMatches[matter_uuid].matches}));
        candidate_matters.sort((a, b) => b.matches - a.matches); // highest to lowest
        candidate_matters = candidate_matters.filter(matter => matter.matches === candidate_matters[0].matches);
        if (candidate_matters.length === 1) {
            return {client: client, matter: candidate_matters[0].uuid};
        } else {
            return {client: client, matter: null};
        }
    }

    // if there are multiple clients with the same number of matches, use the matters to break the tie
    let candidate_matters = [];
    for (let client of candidate_clients) {
        for (let matter_uuid of client.matters) {
            candidate_matters.push({uuid: matter_uuid, matches: matterMatches[matter_uuid].matches});
        }
    }
    
    candidate_matters.sort((a, b) => b.matches - a.matches);
    if (candidate_matters.length === 1) {
        let matter = candidate_matters[0].uuid;
        let client = matters[matter].client;
        return {client: client, matter: matter};
    } else {
        return {client: null, matter: null};
    }
}

/*              const test_entries = [
                    {
                        uuid: "test1",
                        start_time: "2021-07-01T00:00:00.000Z",
                        end_time: "2021-07-01T00:10:00.000Z",
                        minutes: 10,
                        title: "test",
                        date: "2021-07-01",
                        profile_id: session.user.id,
                        doc_id: itemJson.id,
                        num_versions: 2,
                    },
                    {
                        uuid: "test2",
                        start_time: "2021-07-01T02:00:00.000Z",
                        end_time: "2021-07-01T02:15:00.000Z",
                        minutes: 15,
                        title: "test",
                        date: "2021-07-01",
                        profile_id: session.user.id,
                        doc_id: itemJson.id,
                        num_versions: 3,
                    }
                ]
                const test_versions1 = [
                    "2021-07-01T00:05:00.000Z",
                    "2021-07-01T00:10:00.000Z",

                    "2021-07-01T02:05:00.000Z",
                    "2021-07-01T02:10:00.000Z",
                    "2021-07-01T02:20:00.000Z",

                    "2021-07-01T04:00:00.000Z",
                ]
                const return_val = getTimeSpent(itemJson, test_versions1, test_entries, timezone, session);
                console.log("Entries from Test 1")
                console.log(return_val.id_to_delete)
                console.log(return_val.entries);

                const test_entries2 = [
                    {
                        uuid: "test1",
                        start_time: "2021-07-01T00:00:00.000Z",
                        end_time: "2021-07-01T00:10:00.000Z",
                        minutes: 10,
                        title: "test",
                        date: "2021-07-01",
                        profile_id: session.user.id,
                        doc_id: itemJson.id,
                        num_versions: 2,
                    },
                    {
                        uuid: "test2",
                        start_time: "2021-07-01T02:00:00.000Z",
                        end_time: "2021-07-01T02:20:00.000Z",
                        minutes: 20,
                        title: "test",
                        date: "2021-07-01",
                        profile_id: session.user.id,
                        doc_id: itemJson.id,
                        num_versions: 3,
                    },
                    {
                        uuid: "test3",
                        start_time: "2021-07-01T03:54:00.000Z",
                        end_time: "2021-07-01T04:00:00.000Z",
                        minutes: 6,
                        title: "test",
                        date: "2021-07-01",
                        profile_id: session.user.id,
                        doc_id: itemJson.id,
                        num_versions: 1,
                    }
                ]

                const test_versions2 = [
                    "2021-07-01T00:05:00.000Z",
                    "2021-07-01T00:10:00.000Z",

                    "2021-07-01T02:05:00.000Z",
                    "2021-07-01T02:10:00.000Z",
                    "2021-07-01T02:20:00.000Z",

                    "2021-07-01T04:00:00.000Z",
                    "2021-07-01T04:15:00.000Z",
                ]
                const return_val2 = getTimeSpent(itemJson, test_versions2, test_entries2, timezone, session);
                console.log("Entries from Test 2")
                console.log(return_val2.id_to_delete)
                console.log(return_val2.entries);
                

                const test_entries = [
                    {
                        uuid: "test1",
                        start_time: "2021-07-01T00:00:00.000Z",
                        end_time: "2021-07-01T00:10:00.000Z",
                        minutes: 10,
                        title: "test",
                        date: "2021-07-01",
                        profile_id: session.user.id,
                        doc_id: itemJson.id,
                        num_versions: 2,
                    },
                    {
                        uuid: "test2",
                        start_time: "2021-07-01T02:00:00.000Z",
                        end_time: "2021-07-01T02:15:00.000Z",
                        minutes: 15,
                        title: "test",
                        date: "2021-07-01",
                        profile_id: session.user.id,
                        doc_id: itemJson.id,
                        num_versions: 3,
                    }
                ]
                const test_versions1 = [
                    "2021-07-01T00:05:00.000Z",
                    "2021-07-01T00:10:00.000Z",
        
                    "2021-07-01T02:05:00.000Z",
                    "2021-07-01T02:10:00.000Z",
        
                    "2021-07-01T02:20:00.000Z",
                    "2021-07-01T02:41:00.000Z", // inactive
                    "2021-07-01T02:42:00.000Z",
                    "2021-07-01T02:45:00.000Z",
                    "2021-07-01T03:30:00.000Z",
        
                    "2021-07-01T03:33:00.000Z",
                    "2021-07-01T03:45:00.000Z",
                    "2021-07-01T03:55:00.000Z",
        
                    "2021-07-01T04:30:00.000Z",
                    "2021-07-01T04:40:00.000Z",
                    "2021-07-01T04:45:00.000Z",
                    "2021-07-01T04:50:00.000Z",
        
                    "2021-07-01T07:00:00.000Z",
                    "2021-07-01T07:10:00.000Z",
                    "2021-07-01T07:15:00.000Z",
                    "2021-07-01T07:20:00.000Z",
        
                    "2021-07-01T07:45:00.000Z",
                    "2021-07-01T07:50:00.000Z",
                    "2021-07-01T07:55:00.000Z",
                    "2021-07-01T08:30:00.000Z",
                ]

                entries should be:
                1. 2021-07-01T02:05:00.000Z - 2021-07-01T04:50:00.000Z
                    inactive: 2021-07-01T02:20:00.000Z - 2021-07-01T03:30:00.000Z (1.2 hrs)
                    inactive: 2021-07-01T03:55:00.000Z - 2021-07-01T04:30:00.000Z (0.6 hrs)
                    inactive_time: 1.8 hrs
                    70+35=105 mins = 1.75 hrs

                2. 2021-07-01T07:00:00.000Z - 2021-07-01T08:30:00.000Z
                    inactive: 2021-07-01T07:20:00.000Z - 2021-07-01T07:45:00.000Z (0.4 hrs)
                    inactive: 2021-07-01T07:55:00.000Z - 2021-07-01T08:30:00.000Z (0.6 hrs)
                
                
                const return_value = getTimeSpent(itemJson, test_versions1, test_entries, timezone, session);
                console.log("Entries from Test 1")
                console.log(return_value.id_to_delete)
                console.log(return_value.entries);
                // print the inactive intervals
                for (let entry of return_value.entries) {
                    if (entry.inactive_intervals) {
                        console.log("Inactive Intervals")
                        console.log(entry.inactive_intervals)
                    }
                    console.log("Inactive time")
                    console.log(entry.inactive_time)
                }*/