import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import AddClients from './components/AddClients';
import './styles/style.css';
import EditClients from './components/EditClients';
import Navbar from './components/Navbar';
import { signInWithClio } from './Auth';
import queryClio from './functions/clio/queryClio.js';
import fetchClientsMatters from './functions/fetchClientsMatters';
import signInWithLawcus from './functions/lawcus/signInWithLawcus.js';
import Integrations from './components/Integrations';
import devLog from './functions/devLog.js';

function isValidEmail(email) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

function Setup2({ session, billingSoftware, signedInWithClio, setSignedInWithClio, signedInWithLawcus, setSignedInWithLawcus }) {
  // set up everything but the clients & matters- b/c this depends on whether the user wants to integrate with Clio
  const [clients, setClients] = useState([]);
  const [defaultBlock, setDefaultBlock] = useState('1'); // default block billing settings

  useEffect(() => {
      fetch();
  }, []);
  async function fetch() {
      const session = (await supabase.auth.getSession()).data.session;
      const { data: user, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('profile_id', session.user.id);
      if (error) {
        console.error('Error fetching user profile:', error);
        return;
      }
      if (user.length === 0) {
        console.log('User not found');
        supabase.auth.signOut();
      }

      const { data: integrations, int_error } = await supabase
        .from('integrations')
        .select('clio_access_token, lawcus_access_token')
        .eq('profile_id', session.user.id);
      if (int_error) {
        console.error('Error fetching user profile:', error);
        return;
      }
      if (integrations.length > 0) {
        if (integrations[0].clio_access_token) {
          setSignedInWithClio(true);
        } else {
          setSignedInWithClio(false);
        }
        if (integrations[0].lawcus_access_token) {
          setSignedInWithLawcus(true);
        } else {
          setSignedInWithLawcus(false);
        }
      }
  };

  const goBack = async () => {
    const session = await supabase.auth.getSession();
    const user = session.data.session.user;
    let { data, error } = await supabase
      .from('profiles')
      .update({ setups_finished: 0 })
      .eq('profile_id', user.id);
    if (error) {
      devLog('Error going back:', error);
      return;
    }
    window.location.href = '/'; // will automatically go to the previous setup page now that we've changed the setups_finished value
  };

  const handleSubmit = async () => {

    const session = await supabase.auth.getSession();

    /*let done = true;

    for (const client of clients) {
      if (client.email === '' || client.email === null) {
        done = false;
      }
    }
    if (!done) {
        alert('Please fill out all fields.');
        return;
    }
    for (const client of clients) {
      if (!isValidEmail(client.email)) {
        alert('Please enter a valid email for each client.');
        return;
      }
    }*/

    const user = session.data.session.user;

    
    let { data, error } = await supabase
      .from('profiles')
      .update({ setups_finished: 2 })
      .eq('profile_id', user.id);
    if (error) {
      devLog('Error updating user info:', error);
      return;
    }
    for (const client of clients) { // update all clients in supabase
      let { data, error } = await supabase
        .from('clients')
        .update(client)
        .eq('profile_id', user.id)
        .eq('uuid', client.uuid);
      if (error) {
        devLog('Error updating client:', error, client);
        return;
      }
    }
    //update defaultBlock in the default_client_settings table in supabase
    let { data1, error1 } = await supabase
      .from('profiles')
      .update({ default_block: defaultBlock })
      .eq('profile_id', user.id);
    if (error1) {
      devLog('Error updating default block:', error1);
      return;
    }

    devLog('Clients updated:', clients);
    devLog('Default block updated:', defaultBlock);
    // essentially reloads
    window.location.href = '/';
  };

  //console.log("Use Clio: " + useClio);
  //console.log("Signed in with Clio: " + signedInWithClio);
  const canContinue = (billingSoftware==='none' || signedInWithClio || signedInWithLawcus);

  return (
    <div className='form-widget'>
      <h1>Setup: Clients and Matters</h1>

      {billingSoftware!=='none' ? (
        (billingSoftware ==='clio' && !signedInWithClio) ? (
        <div>
            <h3>Sign in with Clio so we can integrate your clients and matters.</h3>
            <button
            onClick={async (e) => {
                e.preventDefault();
                await signInWithClio(session, setSignedInWithClio);
                //await queryClio(setClients);
                //fetchClientsMatters(setClients, null, supabase, session);
            }}
            className="block button white"
            >
            Sign in with Clio
            </button>
        </div>
      ) : (billingSoftware ==='lawcus' && !signedInWithLawcus) ? (
        <div>
            <h3>Sign in with Lawcus so we can integrate your clients and matters.</h3>
            <button
            onClick={async (e) => {
                e.preventDefault();
                await signInWithLawcus(setSignedInWithLawcus);
            }}
            className="block button white"
            >
            Sign in with Lawcus
            </button>
        </div>
      )
      : (
          <div>
            <EditClients
            billingSoftware={billingSoftware}
            clients={clients}
            setClients={setClients}
            defaultBlock={defaultBlock}
            setDefaultBlock={setDefaultBlock}
            saveClientsButton={false}
            signedInWithClio={signedInWithClio}
            />
          </div>
      )) : (
          /* no billing software: the user adds in clients themself. */
          <div>
            <AddClients
              defaultBlock={defaultBlock}
              setDefaultBlock={setDefaultBlock}
            />
          </div>
      )}

      {/* <Integrations session={session}></Integrations> */}
      
      <div className='button-group' style={{justifyContent:'flex-start'}}>
        <button className="button white" onClick={() => goBack()}>Go back</button>
        {canContinue ? (
        <button className="button white" onClick={() => handleSubmit()}>Save and continue</button>
        ) : (
        <button className="button white" disabled>Save and continue</button>
        )}
      </div>

      <br></br>
      
    </div>
  );
}

export default Setup2;