import React, { useState } from 'react';

export default function DisplayDocument(file='Terms of Service') {
    let fileUrl = '/Terms of Service.pdf';
    if (file.file === 'Privacy Policy') {
        fileUrl = '/Privacy Policy.pdf';
    }
    console.log("File")
    console.log(file)

    return (
        <div className = 'form-widget'>
          <h1>{file.file}</h1>
          <iframe
            src={fileUrl}
            width="100%"
            height="750px"
            style={{ border: 'none' }}
            title="Terms of Service"
          />
        </div>
      );
}