export default function shiftDateByTimezone(date, timezone) {
    // if the date is the beginning of the day in UTC,
    // and timezone is "America/Los_Angeles",
    // shiftedDate will be the beginning of the day in PST

    let shiftedDate = new Date(date);
    // add the timezone offset to the date given the variable timezone
    if (timezone === "America/Los_Angeles") {
        shiftedDate.setHours(shiftedDate.getHours() + 7);
    } else if (timezone === "America/New_York") {
        shiftedDate.setHours(shiftedDate.getHours() + 4);
    } else if (timezone === "America/Chicago") {
        shiftedDate.setHours(shiftedDate.getHours() + 5);
    } else if (timezone === "America/Denver") {
        shiftedDate.setHours(shiftedDate.getHours() + 6);
    }
    return shiftedDate;
}